import {
  PiRegister,
  dispatch,
  dispatchFromReducer,
  update,
} from "@pihanga/core";
import { AppState } from "../app.type";
import { onAnalyticsServicesShown } from "../collection/collection.action";
import { AiBotRating, AiBotTask } from "./ai-bot.type";
import { getCollectionId } from "../app.state";
import {
  createInvestigateAction,
  createInvestigateResultAction,
  onInvestigate,
  onInvestigateResult,
} from "./ai-bot.action";
import { featureEnabled } from "./ai-bot.pihanga";

const DEF_RATING = AiBotRating.Warning;
const DEF_MSG = `
This image classification task has only been trained and tested on images taken off Fiji,
while your images have been collected in Indonesian waters.

According to the literature, there are **5** seagrass species found in your region of interest 
which are not found in Fiji marine environments and therefore, will likely not be properly identified.
`;
const DEF_INVESTIGATE_DELAY = 3000;

export function init(register: PiRegister): void {
  onAnalyticsServicesShown<AppState>(
    register,
    (state, { serviceID, collectionID }) => {
      if (!featureEnabled(state)) return state;

      const id = `${collectionID}-${serviceID}`;
      let task = state.aiBot[id];
      if (task) {
        // already in progress
        return state;
      }
      task = { serviceID, collectionID, pending: true };
      dispatchFromReducer(createInvestigateAction(serviceID, collectionID));
      return update(state, ["aiBot", id], task);
    }
  );

  onInvestigate<AppState>(register, (state, { serviceID, collectionID }) => {
    if (!featureEnabled(state)) return state;

    const result = createInvestigateResultAction(
      serviceID,
      collectionID,
      DEF_RATING,
      DEF_MSG
    );
    setTimeout(() => dispatch(result), DEF_INVESTIGATE_DELAY);
    return state;
  });

  onInvestigateResult<AppState>(register, (state, a) => {
    if (!featureEnabled(state)) return state;

    const id = `${a.collectionID}-${a.serviceID}`;
    const task = { ...a, pending: false };
    return update(state, ["aiBot", id], task);
  });
}

export function getTask(
  serviceID: string,
  collectionID: string | null,
  s: AppState
): AiBotTask | undefined {
  const collID = collectionID ? collectionID : getCollectionId(s);
  if (!collID) {
    return;
  }
  if (serviceID.startsWith("urn:ivcap:service:")) {
    serviceID = serviceID.substring("urn:ivcap:service:".length);
  }
  const id = `${collID}-${serviceID}`;
  const task = s.aiBot[id];
  return task;
}
