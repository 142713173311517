import React from "react";
import { PiCardSimpleProps } from "@pihanga/core";
import {
  ArtifactDetail,
  ArtifactMetadataDict,
  MetadataSchema,
} from "../../app.type";
import { MetadataQueryResultItem, OrderProduct } from "../../ivcap";
import { IconDownload } from "@tabler/icons-react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { getColourForClass } from "./colourGenerator";
export const CV_PIPELINE_SCHEMA = "urn:ibenthos:schema:segmentation.1";

export const getSourceArtifactId = (
  record?: MetadataQueryResultItem
): string | undefined => {
  const inputUrn =
    record && record.aspect.input_urn && record.aspect.input_urn.split("/");

  return inputUrn && inputUrn[inputUrn.length - 1];
};

export type ComponentProps = {
  artifactMetadataList: ArtifactMetadataDict;
  product?: OrderProduct;
};

type CoverageData = {
  id: string;
  name: string;
  percent: number | undefined;
  backgroundColor: string | undefined;
};

export const getDisplayData = (
  json: any,
  coverageByClass: CoverageByClass | undefined
): CoverageData[] => {
  return (
    json &&
    json.classes &&
    coverageByClass &&
    json.classes.map(
      ({ id, name }: { id: string; name: string }, index: number) => {
        const c = coverageByClass.get(id);
        return {
          id,
          name,
          percent: c && c.coverage,
          backgroundColor: getColourForClass(index, c && c.weighting),
        };
      }
    )
  );
};

const NO_OF_DIGITS = 2;

const displayCoverage = (percent: number | undefined): string => {
  const hasData = percent !== undefined;
  const c = hasData ? percent! * 100 : 0;

  return hasData ? c.toFixed(NO_OF_DIGITS) : "No coverage";
};

const renderCoverage = (
  exportFileName: string,
  json: any,
  coverageByClass: CoverageByClass | undefined,
  totalCoverage: number | undefined
): React.ReactNode => {
  const data = getDisplayData(json, coverageByClass);
  const exportData = data.concat([
    {
      id: "totalCoverage",
      name: "Total Coverage",
      percent: totalCoverage,
    },
  ] as CoverageData[]);

  return (
    data &&
    data.length > 0 && (
      <div
        className="table-responsive"
        style={{
          display: "grid",
          placeItems: "center",
          gridTemplate: "auto auto / auto auto auto auto",
          rowGap: "10px",
        }}
      >
        <table
          className="table table-vcenter"
          style={{
            gridColumn: "1/5",
          }}
        >
          <thead>
            <tr>
              <th>Index</th>
              <th>Class</th>
              <th>Percentage Coverage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(
              (
                {
                  id,
                  name,
                  percent,
                  backgroundColor,
                }: {
                  id: string;
                  name: string;
                  percent: number | undefined;
                  backgroundColor: string | undefined;
                },
                index: number
              ) => {
                const c = displayCoverage(percent);

                return (
                  <tr>
                    <td className="text-secondary">{index}</td>
                    <td>{name}</td>

                    <td title={c}>{c}</td>

                    <td>
                      <div
                        style={{
                          height: 20,
                          width: 20,
                          backgroundColor,
                        }}
                      ></div>
                    </td>
                  </tr>
                );
              }
            )}
            <tr>
              <td colSpan={2} className="text-center">
                <strong>Total Coverage</strong>
              </td>
              <td colSpan={2} className="text-center">
                {displayCoverage(totalCoverage)}
              </td>
            </tr>
          </tbody>
        </table>

        <button
          type="button"
          className="btn"
          style={{
            gridColumn: "2/4",
          }}
          onClick={(): void => {
            const csvConfig = mkConfig({
              useKeysAsHeaders: true,
              filename: exportFileName,
            });
            const csv = generateCsv(csvConfig)(exportData);
            download(csvConfig)(csv);
          }}
        >
          <IconDownload />
          Download coverage
        </button>
      </div>
    )
  );
};

export type CoverageByClass = Map<
  string,
  { coverage: number; weighting: number }
>;

export const getCoverageByClass = (
  artifactMetadataList: ArtifactMetadataDict,
  product?: OrderProduct
): {
  coverageData?: ArtifactDetail;
  record?: MetadataQueryResultItem;
  coverageByClass: CoverageByClass;
  totalCoverage: number;
} => {
  const coverageData =
    product &&
    artifactMetadataList[product.id] &&
    artifactMetadataList[product.id][MetadataSchema.DEFAULT];

  const record =
    coverageData &&
    coverageData.records.find((r) => r.schema === CV_PIPELINE_SCHEMA);

  return {
    coverageData,
    record,
    coverageByClass:
      record &&
      record.aspect &&
      record.aspect.coverage &&
      record.aspect.coverage.reduce(
        (
          a: CoverageByClass,
          {
            id,
            coverage_weighting,
            percent_coverage,
          }: {
            id: string;
            coverage_weighting: number;
            percent_coverage: number;
          }
        ) => {
          return a.set(id, {
            coverage: percent_coverage,
            weighting: coverage_weighting,
          });
        },
        new Map()
      ),
    totalCoverage:
      record && record.aspect && (record.aspect.total_coverage as number),
  };
};

export const Component = ({
  artifactMetadataList,
  product,
}: PiCardSimpleProps<ComponentProps>): React.ReactNode => {
  const { coverageData, record, coverageByClass, totalCoverage } =
    getCoverageByClass(artifactMetadataList, product);

  const isLoading = coverageData && coverageData.fetching;
  if (isLoading) return <div className="spinner-border"></div>;
  else {
    return (
      record &&
      renderCoverage(
        (product && product.id) || "coverage",
        record.aspect,
        coverageByClass,
        totalCoverage
      )
    );
  }
};
