import { ReduxAction, createOnAction, registerActions } from "@pihanga/core";
import { MetadataQueryResultItem } from "../ivcap";
export const ACTION_TYPES = registerActions("APP:COLLECTIONS", [
  "PREVIEW_ARTIFACT",

  "SHOW_LISTING",
  "SHOW_INDIVIDUAL",

  "LOAD_MORE_IMAGES",
  "SELECT_ALL_IMAGES",

  "LOCATE_IMG_ON_MAP",

  "LISTING",
  "ANALYTIC_SERVICES",
  "ANALYTIC_SERVICES_SHOWN", // when a analytics task panel is openend
  // "ANALYTIC_SERVICE_DETAILS",
  "IMG_COLLECTION",
  "IMG_META",
  "ERROR",
]);

// PREVIEW_ARTIFACT
export type PreviewArtifactEvent = {
  id: string;
};

export const onPreviewArtifact = createOnAction<PreviewArtifactEvent>(
  ACTION_TYPES.PREVIEW_ARTIFACT
);

export const createPreviewArtifactAction = (
  id: string
): ReduxAction & PreviewArtifactEvent => ({
  type: ACTION_TYPES.PREVIEW_ARTIFACT,
  id,
});

// SHOW_COLLECTION_LISTING

export type ShowCollectionListingEvent = {};

export const onShowCollectionListing =
  createOnAction<ShowCollectionListingEvent>(ACTION_TYPES.SHOW_LISTING);
export const createShowCollectionListingAction = (): ReduxAction &
  ShowCollectionListingEvent => ({
  type: ACTION_TYPES.SHOW_LISTING,
});

// // SHOW_COLLECTION_DETAIL

export type ShowCollectionDetailEvent = {
  collectionID: string;
};

export const onShowCollectionIndividual =
  createOnAction<ShowCollectionDetailEvent>(ACTION_TYPES.SHOW_INDIVIDUAL);

export const createShowCollectionDetailAction = (
  collectionID: string
): ReduxAction & ShowCollectionDetailEvent => ({
  type: ACTION_TYPES.SHOW_INDIVIDUAL,
  collectionID,
});

// ANALYTIC_SERVICES

export type CollectionAnalyticsServicesEvent = {
  page?: string;
  nextPage?: string;
  records: MetadataQueryResultItem[];
};

export const onCollectionAnalyticsService =
  createOnAction<CollectionAnalyticsServicesEvent>(
    ACTION_TYPES.ANALYTIC_SERVICES
  );

// ANALYTIC_SERVICES_SHOWN

export type AnalyticsServicesShownEvent = {
  collectionID: string;
  serviceID: string;
};

export const onAnalyticsServicesShown =
  createOnAction<AnalyticsServicesShownEvent>(
    ACTION_TYPES.ANALYTIC_SERVICES_SHOWN
  );

// // ANALYTIC_SERVICE_DETAILS

// export type CollectionAnalyticsServiceDetailsEvent = {
//   service: ServiceRecord;
// };

// export const onCollectionAnalyticsServiceDetails =
//   createOnAction<CollectionAnalyticsServiceDetailsEvent>(ACTION_TYPES.ANALYTIC_SERVICE_DETAILS);

// LOAD_MORE_IMAGES

export type LoadMoreImagesEvent = {
  collectionID: string;
  page: string;
  loadAll?: boolean;
};

export const onLoadMoreImages = createOnAction<LoadMoreImagesEvent>(
  ACTION_TYPES.LOAD_MORE_IMAGES
);

export const createLoadMoreImagesAction = (
  collectionID: string,
  page: string,
  loadAll?: boolean
): ReduxAction & LoadMoreImagesEvent => ({
  type: ACTION_TYPES.LOAD_MORE_IMAGES,
  collectionID,
  page,
  loadAll,
});

// LOCATE_IMG_ON_MAP

export type LocateImgOnMapEvent = {
  lat: number;
  lng: number;
};

export const onLocateImageOnMap = createOnAction<LocateImgOnMapEvent>(
  ACTION_TYPES.LOCATE_IMG_ON_MAP
);

export const createLocateImageOnMap = (
  lat: number,
  lng: number
): ReduxAction & LocateImgOnMapEvent => ({
  type: ACTION_TYPES.LOCATE_IMG_ON_MAP,
  lat,
  lng,
});

// SELECT_ALL_IMAGES

export type SelectAllImagesEvent = {
  collectionID: string;
  selected: boolean;
};

export const onSelectAllImages = createOnAction<SelectAllImagesEvent>(
  ACTION_TYPES.SELECT_ALL_IMAGES
);

export const createSelectAllImagesAction = (
  collectionID: string,
  selected: boolean
): ReduxAction & SelectAllImagesEvent => ({
  type: ACTION_TYPES.SELECT_ALL_IMAGES,
  collectionID,
  selected,
});
