import {
  PiRegister,
  actionTypesToEvents,
  createCardDeclaration,
  createOnAction,
  registerActions,
} from "@pihanga/core";

import { Component } from "./googleMap.component";
import type { ComponentProps } from "./googleMap.component";

export const CARD_TYPE = "PiGoogleMap";
export const PiGoogleMap = createCardDeclaration<ComponentProps>(CARD_TYPE);

const ACTION_TYPES = registerActions("GMAP", ["MARKERS_IN_BOUNDS", "CLOSE"]);

export const onMarkersInBound = createOnAction(ACTION_TYPES.MARKERS_IN_BOUNDS);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: CARD_TYPE,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
