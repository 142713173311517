import {
  PiRegister,
  dispatchFromReducer,
  ReduxState,
  ReduxAction,
  createOnAction,
} from "@pihanga/core";
import { getAccessToken } from ".";
import {
  ACTION_TYPES,
  BaseEvent,
  createReadAction,
  ListEvent,
  LoadListEvent,
  LoadRecordEvent,
} from "./actions";
import {
  restErrorHandling,
  createListUrlBuilder,
  getNextPage,
  dispatchEvent,
} from "./common";
import { URN } from ".";
import {
  registerGET,
  registerPOST,
  registerPUT,
  registerDELETE,
} from "@pihanga/rest-client";

// // LIST

// type MetadataListEvent = ListEvent & {
//   artifacts: MetadataListItem[];
// }

// export type MetadataListItem = {
//   id: string;
//   name: string;
//   status: string;
//   accountID: string;
// }

// export type LoadMetadataListEvent = LoadListEvent;

// export function dispatchIvcapGetMetadataList(ev: LoadMetadataListEvent) {
//   const a = createListAction(ACTION_TYPES.LOAD_METADATA_LIST, ev)
//   dispatchFromReducer(a)
// }

// export const onMetadataList = createOnAction<MetadataListEvent>(ACTION_TYPES.METADATA_LIST)

// QUERY

export type MetadataQueryEvent<T = {}> = LoadListEvent<T> & {
  // type: ACTION_TYPES.QUERY_METADATA
  entityID?: URN;
  schema?: URN;
  aspectPath?: string;
  filter?: string;

  // actionTemplate?: ReduxActionExt & {
  //   records: MetadataQueryResultItem[];
  // };
};

export function dispatchIvcapQueryMetadata<T = {}>(
  ev: MetadataQueryEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.QUERY_METADATA,
    ...ev,
  });
}

export type MetadataQueryResultItem = {
  recordID: URN;
  entity: URN;
  schema: URN;
  aspect: any;
};

export type MetadataQueryResultEvent = ListEvent & {
  records: MetadataQueryResultItem[];
  request: MetadataQueryEvent;
};

export const onMetadataQueryResult = createOnAction<MetadataQueryResultEvent>(
  ACTION_TYPES.QUERY_RESULT
);

// RECORD

type MetadataRecordEvent = {
  metadata: MetadataRecord;
};

export type MetadataRecord = {
  id: URN;
  entity: URN;
  schema: URN;
  aspect: string;
  validFrom: Date;
  validTo?: Date;
  asserter: URN;
  revoker?: URN;
};

export type LoadMetadataRecordEvent<T = {}> = LoadRecordEvent<T>;

export function dispatchIvcapGetMetadataRecord<T = {}>(
  ev: LoadMetadataRecordEvent<T>
): void {
  const a = createReadAction(ACTION_TYPES.LOAD_METADATA_RECORD, ev);
  dispatchFromReducer(a);
}

export const onMetadataRecord = createOnAction<MetadataRecordEvent>(
  ACTION_TYPES.METADATA_DETAIL
);

// ADD

type AddMetadataEvent<T = {}> = BaseEvent<T> & {
  entity: URN;
  schema: URN;
  aspect: { [k: string]: any };
};

export function dispatchIvcapAddMetadata<T = {}>(
  ev: AddMetadataEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.ADD_METADATA,
    ...ev,
  });
}

type MetadataAddedEvent = {
  recordID: URN;
  entity: URN;
  schema: URN;
  aspect: { [k: string]: any };
};

export const onMetadataAdded = createOnAction<MetadataAddedEvent>(
  ACTION_TYPES.METADATA_ADDED
);

// UPDATE

type UpdateMetadataEvent<T = {}> = BaseEvent<T> & {
  entity: URN;
  schema: URN;
  aspect: { [k: string]: any };
};

export function dispatchIvcapUpdateMetadata<T = {}>(
  ev: UpdateMetadataEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.UPDATE_METADATA,
    ...ev,
  });
}

type MetadataUpdatedEvent = {
  recordID: URN;
  entity: URN;
  schema: URN;
  aspect: { [k: string]: any };
};

export const onMetadataUpdated = createOnAction<MetadataUpdatedEvent>(
  ACTION_TYPES.METADATA_UPDATED
);

// REVOKE

type RevokeMetadataEvent<T = {}> = BaseEvent<T> & {
  recordID: URN;
};

export function dispatchIvcapRevokeMetadata<T = {}>(
  ev: RevokeMetadataEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.REVOKE_METADATA,
    ...ev,
  });
}

type MetadataRevokedEvent = {
  recordID: URN;
};

export const onMetadataRevoked = createOnAction<MetadataRevokedEvent>(
  ACTION_TYPES.METADATA_REVOKED
);

//====== API HANDLER

export function init(register: PiRegister): void {
  registerGET<ReduxState, ReduxAction & MetadataQueryEvent, any>(register)({
    name: "queryMetadata",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("metadata", {
      "entity-id": "?entityID",
      schema: "?schema",
      "aspect-path": "?aspectPath",
    }),
    trigger: ACTION_TYPES.QUERY_METADATA,
    request: (a, _) => a as any,
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, request) => {
      const records = toQueryResult(content);
      const ev: MetadataQueryResultEvent = {
        records,
        nextPage: getNextPage(content.links),
        request,
      };
      dispatchEvent(ev, ACTION_TYPES.QUERY_RESULT, request.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:queryMetadata"),
  });

  registerGET<ReduxState, ReduxAction & LoadMetadataRecordEvent, any>(register)(
    {
      //registerGET<ReduxState, ReduxAction & LoadMetadataRecordEvent, any>({
      name: "getMetadataDetail",
      origin: ({ apiURL }, _) => apiURL,
      url: "/1/metadata/:id",
      trigger: ACTION_TYPES.LOAD_METADATA_RECORD,
      request: ({ id }) => ({ id }),
      headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
      reply: (state, content: any, { template }) => {
        const ev: MetadataRecordEvent = {
          metadata: toMetadataRecord(content),
        };
        dispatchEvent(ev, ACTION_TYPES.METADATA_DETAIL, template);
        return state;
      },
      error: restErrorHandling("ivcap-api:getMetadataDetail"),
    }
  );

  registerPOST<ReduxState, ReduxAction & UpdateMetadataEvent, any>(register)({
    name: "addMetadata",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/metadata?entity-id=:entity&schema=:schema",
    trigger: ACTION_TYPES.ADD_METADATA,
    request: ({ entity, schema, aspect }) => ({
      body: aspect,
      contentType: "application/json",
      bindings: { entity, schema },
    }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, req) => {
      // {"record-id":"urn:ivcap:record:3cb2dc5a-6045-452a-9040-c9f9b104e9ac"}
      const ev: MetadataAddedEvent = {
        recordID: content["record-id"],
        entity: req.entity,
        schema: req.schema,
        aspect: req.aspect,
      };
      dispatchEvent(ev, ACTION_TYPES.METADATA_ADDED, req.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:addMetadata"),
  });

  registerPUT<ReduxState, ReduxAction & UpdateMetadataEvent, any>(register)({
    name: "updateMetadata",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/metadata?entity-id=:entity&schema=:schema",
    trigger: ACTION_TYPES.UPDATE_METADATA,
    request: ({ entity, schema, aspect }) => ({
      body: aspect,
      contentType: "application/json",
      bindings: { entity, schema },
    }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, req) => {
      // {"record-id":"urn:ivcap:record:3cb2dc5a-6045-452a-9040-c9f9b104e9ac"}
      const ev: MetadataUpdatedEvent = {
        recordID: content["record-id"],
        entity: req.entity,
        schema: req.schema,
        aspect: req.aspect,
      };
      dispatchEvent(ev, ACTION_TYPES.METADATA_UPDATED, req.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:updateMetadata"),
  });

  registerDELETE<ReduxState, ReduxAction & RevokeMetadataEvent, any>(register)({
    name: "revokeMetadata",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/metadata/:recordID",
    trigger: ACTION_TYPES.REVOKE_METADATA,
    request: ({ recordID }) => ({ recordID }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, req) => {
      // {"record-id":"urn:ivcap:record:3cb2dc5a-6045-452a-9040-c9f9b104e9ac"}
      const ev: MetadataRevokedEvent = {
        recordID: content["record-id"],
      };
      dispatchEvent(ev, ACTION_TYPES.METADATA_REVOKED, req.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:revokeMetadata"),
  });
}

function toQueryResult(ev: any): MetadataQueryResultItem[] {
  return (ev["items"] || []).map((el: any) => ({
    recordID: el["id"],
    entity: el["entity"],
    schema: el["schema"],
    aspect: el["aspect"],
  }));
}

function toMetadataRecord(els: any): MetadataRecord {
  return {
    id: els.rec_id,
    entity: els.entity,
    schema: els.entity,
    aspect: els.entity,
    validFrom: new Date(els.valid_from),
    validTo: els.valid_to ? new Date(els.valid_to) : undefined,
    asserter: els?.asserter,
    revoker: els?.revoker,
  };
}
