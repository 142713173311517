import React, { FC } from "react";
import { FieldProps } from "formik";

type TbInputComponentProps = {
  label: string;
};

export const TbInputComponent: FC<TbInputComponentProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form,
  ...props
}) => {
  const touched = form.touched[field.name];
  const error = form.errors[field.name];
  return (
    <div className="mb-3">
      <label className="form-label">{props.label}</label>
      <input
        type="text"
        {...field}
        {...props}
        className={`form-control ${
          touched && error ? "is-invalid" : touched && !error && "is-valid"
        }`}
      />
      {touched && error && (
        <div className="invalid-feedback">{error as any}</div>
      )}
    </div>
  );
};
