/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import { init as materialInit } from '@pihanga/material-ui';
import { PiInitF, start as pihangaStart } from "@pihanga/core";
import { init as piCards } from "@pihanga/cards";
import { init as tablerInit } from "@pihanga/tabler";

import { init as ibenthosCardsInit } from "./cards";

import AppComponent from "./app.component";
import initialReduxState from "./app.state";
import initialCards from "./app.pihanga";
import { init as initReducers } from "./app.reducers";

import reportWebVitals from "./reportWebVitals";

/**
 * Setup environment for plugins and collect all their init function.
 */
const inits: PiInitF[] = [initReducers, piCards, tablerInit, ibenthosCardsInit];

// eslint-disable-next-line no-undef
const rootEl = document.getElementById("root");
const startOpts = {
  rootEl,
  rootComponent: AppComponent,
  inits,
  initialReduxState,
  initialCards,
};
pihangaStart(startOpts);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
