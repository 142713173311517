import { PiRegister, createCardDeclaration } from "@pihanga/core";

import { Component } from "./jsonViewer.component";
import type { ComponentProps } from "./jsonViewer.component";

export const CARD_TYPE = "iBenthosJsonViewer";
export const IBenthosJsonViewer =
  createCardDeclaration<ComponentProps>(CARD_TYPE);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: CARD_TYPE,
    component: Component,
  });
}
