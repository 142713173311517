import { dispatchFromReducer, PiCardDef, ReduxActionExt } from "@pihanga/core";
import {
  AppState,
  ArtifactDict,
  MetadataSchema,
  ArtifactMetadataDict,
} from "../app.type";
import {
  ArtifactDataEvent,
  OrderListItem,
  OrderParameter,
  OrderProduct,
  OrderRecord,
} from "../ivcap"; // "@pihanga/ivcap";
import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";
import { ColumnType, TbXlDataTable, Row } from "../cards/tbDataTable";
import {
  DataGridEl,
  DataGridElType,
  TbDataGrid,
} from "@pihanga/tabler/dist/cards/tbDataGrid";
import {
  createShowAnalyticCoverageInModalAction,
  createShowArtifactDetailAction,
  dispatchUpdateArtifactData,
  dispatchUpdateArtifactMetadataList,
} from "../app.actions";
import { TbEmptyList } from "../cards/tbEmptyList";
import { TbOrderDetail } from "../cards/tbOrderDetail";
import { getProjectId, GOOGLE_MAP_KEY } from "../app.state";
import { PiGoogleMap } from "../cards/googleMap";
import {
  CV_PIPELINE_SCHEMA,
  getSourceArtifactId,
} from "../cards/tbAnalyticCard/tbAnalyticCard.component";
import { MapMarkerSelectedEvent } from "../cards/googleMap/googleMap.component";
import {
  NO_OF_ORDER_PRODUCTS_PER_PAGE,
  NO_OF_ORDERS_PER_PAGE,
} from "./analytic.reducer";

const dataFormatter = new Intl.DateTimeFormat([], {
  dateStyle: "short",
  timeStyle: "short",
});

/**
 * Get source image from the given order product.
 * @param product
 * @param artifacts
 * @param artifactMetadataList
 */
export const getSrcImgFromOrderProduct = (
  product: OrderProduct | undefined,
  artifacts: ArtifactDict,
  artifactMetadataList: ArtifactMetadataDict
): ArtifactDataEvent | undefined => {
  const coverageData =
    product &&
    artifactMetadataList[product.id] &&
    artifactMetadataList[product.id][MetadataSchema.DEFAULT];

  const record = coverageData
    ? coverageData.records.find((r) => r.schema === CV_PIPELINE_SCHEMA)
    : undefined;

  const sourceArtifactId = getSourceArtifactId(record);
  const sourceImage = sourceArtifactId
    ? artifacts[sourceArtifactId]
    : undefined;

  return sourceImage;
};

export function init(cards: { [k: string]: PiCardDef }): void {
  cards.analyticsListing = TbXlCard<AppState>({
    title: "Analytics Results",
    contentCard: "analyticsTable",
    infoCards: ["refreshButton"],
  });

  cards.emptyAnalyticListing = TbEmptyList<AppState>({
    title: "No analytic results available.",
  });

  cards.analyticsTable = TbXlDataTable<AppState, OrderListItem>({
    columns: [
      { label: "id_short", title: "Id", type: ColumnType.String },
      { label: "name", title: "Name", sortable: true, type: ColumnType.String },
      {
        label: "status",
        title: "Status",
        sortable: true,
        type: ColumnType.String,
      },
      {
        label: "orderedAt",
        title: "Created At",
        type: ColumnType.Date,
        sortable: true,
      },
      {
        label: "finishedAt",
        title: "Finished By",
        type: ColumnType.Date,
        sortable: true,
      },
    ],
    hasMore: (s) => {
      const nuOfOrdersPerPage =
        s.analyticListing?.loadOrderListEvent.limit || NO_OF_ORDERS_PER_PAGE;
      const canLoadMore =
        (s.analyticListing?.offset || 0) + nuOfOrdersPerPage <
        (s.analyticListing?.list.length || 0);

      return !!s.analyticListing?.nextPage || canLoadMore;
    },
    dataOffset: (s: AppState) => {
      return s.analyticListing ? s.analyticListing?.offset : 0;
    },
    data: (s: AppState) => {
      const nuOfOrdersPerPage =
        s.analyticListing?.loadOrderListEvent.limit || NO_OF_ORDERS_PER_PAGE;

      const offset = s.analyticListing?.offset || 0;
      const data = (s.analyticListing?.list || [])
        .slice(offset, offset + nuOfOrdersPerPage)
        .map((o, idx) => {
          const id = o.id;
          const id_short = id.substring(id.length - 6);
          return {
            id,
            data: { ...o, id_short },
            detailCard: "analyticDetailCard",
          };
        });

      return data;
    },
    dataFormatter: {
      date: (el: Date) => dataFormatter.format(el),
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    cardOnEmpty: (s) =>
      s.analyticListing.fetching
        ? "spinnerCard"
        : "emptyAnalyticProductListing",
  });

  cards.analyticDetailCard = TbDataGrid<AppState>({
    title: (s, _, ctxt) => {
      return (ctxt["row"] as Row<OrderListItem>).data?.name;
    },
    items: (s, _, ctxt) => {
      const order = (ctxt["row"] as Row<OrderListItem>).data;
      return orderDetail(s, order.id);
    },
    cardOnEmpty: "spinnerCard",
  });

  cards.emptyAnalyticProductListing = TbEmptyList<AppState>({
    title: "No analytics results available.",
  });

  cards.analyticProductListing = TbXlDataTable<AppState, OrderProduct>({
    columns: [
      { label: "view", title: "View", type: ColumnType.Button },
      {
        label: "name",
        title: "Name",
        sortable: true,
        type: ColumnType.String,
      },
      {
        label: "sourceImageName",
        title: "Source image",
        type: ColumnType.String,
      },
      {
        label: "mimeType",
        title: "Type",
        sortable: true,
        type: ColumnType.String,
      },
    ],
    context: (s, _, context) => {
      return context;
    },
    hasMore: (s, _, { orderID }) => {
      const record = s.analytics[orderID]?.record;
      const canLoadMore =
        (record?.productsOffset || 0) + NO_OF_ORDER_PRODUCTS_PER_PAGE <
        (record?.products.length || 0);

      return !!record.productsNextPage || canLoadMore;
    },
    dataOffset: (s: AppState, _, { orderID }) => {
      const record = s.analytics[orderID]?.record;

      return record ? record?.productsOffset : 0;
    },
    data: (s, _, { orderID }) => {
      const products = s.analytics[orderID]?.record?.products || [];
      const offset = s.analytics[orderID]?.record?.productsOffset || 0;

      return products
        .slice(offset, offset + NO_OF_ORDER_PRODUCTS_PER_PAGE)
        .map((p, idx) => {
          const id = p.id;
          const id_short = id.substring(id.length - 6);

          const sourceImg = getSrcImgFromOrderProduct(
            p,
            s.artifacts,
            s.artifactMetadataList
          );

          const sourceImgId = sourceImg && sourceImg.artifactID;
          const metadata =
            sourceImgId &&
            s.artifactMetadataList[sourceImgId] &&
            s.artifactMetadataList[sourceImgId][MetadataSchema.IMG_LAT_LNG];

          const aspect =
            metadata && metadata.records[0] && metadata.records[0].aspect;

          return {
            id,
            data: {
              ...p,
              sourceImageName: aspect ? aspect.name : "",
              id_short,
            },
          };
        });
    },

    cardOnEmpty: (s: AppState, _, { orderID }) => {
      const record = s.analytics[orderID]?.record;

      return record.productsFetching ? "spinnerCard" : "emptyAnalyticListing";
    },
  });

  cards.analyticsCreateDetail = TbOrderDetail<AppState>({
    collections: (s) =>
      s.collectionListing.list.filter((el) => el.project === getProjectId(s)),
  });

  cards.imagesMap = PiGoogleMap<AppState>({
    onMarkerSelected:
      (s: AppState, _, { orderID }) =>
      (ev: MapMarkerSelectedEvent) => {
        if (ev.markerIDs.length > 0) {
          const markerID = ev.markerIDs[0];

          const products = s.analytics[orderID]?.record?.products || [];
          const matchProduct = products.find((product) => {
            const sourceImg = getSrcImgFromOrderProduct(
              product,
              s.artifacts,
              s.artifactMetadataList
            );

            const id = sourceImg && sourceImg.artifactID;
            return id === markerID;
          });

          if (matchProduct) {
            dispatchUpdateArtifactData(
              s,
              matchProduct.id,
              matchProduct.dataURL as string,
              matchProduct.name
            );

            dispatchFromReducer(
              createShowAnalyticCoverageInModalAction(
                matchProduct.name,
                matchProduct
              )
            );
          }
        }
      },

    markers: (s: AppState, _, { orderID }) => {
      // Load all the source images
      const products = s.analytics[orderID]?.record?.products || [];

      if (products.length > 0) {
        for (let i = 0; i < products.length; i++) {
          const productId = products[i].id;
          if (
            productId &&
            (!s.artifactMetadataList[productId] ||
              !s.artifactMetadataList[productId][MetadataSchema.DEFAULT])
          ) {
            dispatchUpdateArtifactMetadataList(
              s,
              productId,
              MetadataSchema.DEFAULT
            );
          } else {
            const sourceImg = getSrcImgFromOrderProduct(
              products[i],
              s.artifacts,
              s.artifactMetadataList
            );
            const sourceArtifactId = sourceImg && sourceImg.artifactID;

            if (
              sourceArtifactId &&
              (!s.artifactMetadataList[sourceArtifactId] ||
                !s.artifactMetadataList[sourceArtifactId][
                  MetadataSchema.IMG_LAT_LNG
                ])
            ) {
              dispatchUpdateArtifactMetadataList(
                s,
                sourceArtifactId,
                MetadataSchema.IMG_LAT_LNG
              );
            }
          }
        }
      }

      return products
        .map((product) => {
          const sourceImg = getSrcImgFromOrderProduct(
            product,
            s.artifacts,
            s.artifactMetadataList
          );

          const id = sourceImg && sourceImg.artifactID;
          const metadata =
            id &&
            s.artifactMetadataList[id] &&
            s.artifactMetadataList[id][MetadataSchema.IMG_LAT_LNG];

          const aspect =
            metadata && metadata.records[0] && metadata.records[0].aspect;

          return (
            aspect && {
              id,
              label: product.name,
              lat: aspect.latitude,
              lng: aspect.longitude,
            }
          );
        })
        .filter((img) => img !== undefined);
    },
    height: 600,
    apiKey: GOOGLE_MAP_KEY,
  });
}

function orderDetail(state: AppState, id: string): DataGridEl[] {
  const or = state.analytics[id];
  if (!or) {
    return [];
  }

  const order = or.record;
  let a: DataGridEl[] = ["id", "status"].map((k) => ({
    id: k,
    title: k === "id" ? "Job ID" : undefined,
    type: DataGridElType.Text,
    value: (order as any)[k],
  }));
  a.push({ type: DataGridElType.Separator });
  a = a.concat(
    ["orderedAt", "startedAt", "finishedAt"].map((k) => ({
      id: k,
      type: DataGridElType.Date,
      value: (order as any)[k],
    }))
  );
  a.push({ type: DataGridElType.Separator });
  a = a.concat(
    ["serviceID", "accountID"].map((k) => ({
      id: k,
      type: DataGridElType.Text,
      value: (order as any)[k],
    }))
  );
  a.push({ type: DataGridElType.Separator, title: "Parameters" });
  a = a.concat(order.parameters.map(orderParam));
  a = a.concat(orderProducts(order));
  return a;
}

function orderParam(p: OrderParameter): DataGridEl {
  var value = `${p.value}`;
  var type = DataGridElType.Text;
  var actionTemplate: ReduxActionExt | undefined;
  if (value.startsWith("http://artifact.local/")) {
    value = value.substring("http://artifact.local/".length);
  }
  if (value.startsWith("urn:ivcap:")) {
    type = DataGridElType.Link;
    actionTemplate = createShowArtifactDetailAction(value);
  }
  return {
    id: p.name,
    type,
    value,
    actionTemplate,
  };
}

function orderProducts(order: OrderRecord): DataGridEl[] {
  const products = order.products;
  if (!products || products.length === 0) {
    return [];
  }
  return [
    { type: DataGridElType.Separator, title: "Map" },
    {
      id: "",
      type: DataGridElType.Card,
      cardName: "imagesMap",
      context: { orderID: order.id },
    },

    { type: DataGridElType.Separator, title: "Products" },
    {
      id: "",
      type: DataGridElType.Card,
      cardName: "analyticProductListing",
      context: { orderID: order.id },
    },
  ];
}
