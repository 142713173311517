import { dispatch, PiCardDef } from "@pihanga/core";
import {
  AppState,
  ProjectDetail,
  ProjectSummary,
  SaveProjectDetail,
} from "../app.type";
import { TbButtonType } from "@pihanga/tabler/dist/cards";
import { TbButton } from "@pihanga/tabler/dist/cards/tbButton";
import { ColumnType, Row, TbXlDataTable } from "../cards/tbDataTable";
import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";
import {
  DataGridEl,
  DataGridElType,
  TbDataGrid,
} from "@pihanga/tabler/dist/cards/tbDataGrid";
import { TbProjectDetail } from "../cards/tbProjectDetail";
import { formatRelative } from "date-fns";
import { ACTION_TYPES, createCreateNewProjectAction } from "./project.action";
import { TbEmptyList } from "../cards/tbEmptyList";
import { getProjectId } from "../app.state";
import { NO_OF_PROJECTS_PER_PAGE } from "./project.reducer";

export function init(cards: { [k: string]: PiCardDef }): void {
  cards.projectsListing = TbXlCard<AppState>({
    title: "Projects",
    contentCard: "projectsTable",
    actionCards: ["createProjectButton"],
    infoCards: ["refreshButton"],
  });

  cards.emptyProjectTable = TbEmptyList<AppState>({
    title: "No projects available.",
    actionButtonTitle: "Create new project",
    actionButtonOnClick: () => () => dispatch(createCreateNewProjectAction()),
  });

  cards.projectsTable = TbXlDataTable<AppState, ProjectSummary>({
    columns: [
      { label: "name", title: "Name", sortable: true, type: ColumnType.String },
      {
        label: "country",
        title: "Country",
        sortable: true,
        type: ColumnType.String,
      },
      { label: "id", title: "Id", type: ColumnType.String },
    ],
    data: (s: AppState) => {
      return s.projectListing.list
        .slice(
          s.projectListing.offset || 0,
          s.projectListing.offset + NO_OF_PROJECTS_PER_PAGE
        )
        .map((el, idx) => {
          return {
            id: el.id,
            data: {
              ...el,
            },
            detailCard: "projectsDetailCard", // idx === 2 ? "spinnerCard" : undefined,
          };
        });
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    showPageSizeSelector: false,
    dataOffset: (s: AppState) => {
      return s.projectListing ? s.projectListing.offset || 0 : 0;
    },
    hasMore: (s) => {
      const canLoadMore =
        (s.projectListing.offset || 0) + NO_OF_PROJECTS_PER_PAGE <
        (s.projectListing.list.length || 0);

      return !!s.projectListing.nextPage || canLoadMore;
    },

    cardOnEmpty: (s) =>
      s.projectListing.fetching ? "spinnerCard" : "emptyProjectTable",
  });

  cards.projectsDetailCard = TbDataGrid<AppState>({
    title: (s, _, ctxt) => {
      return (ctxt["row"] as Row<ProjectSummary>).data?.name;
    },
    items: (s, _, ctxt) => {
      const project = (ctxt["row"] as Row<ProjectSummary>).data;
      return projectDetail(s, project.id);
    },
  });

  cards.createProjectButton = TbButton<AppState>({
    name: "create-new-project",
    title: "Create New Project",
    isGhostButton: true,
    buttonType: TbButtonType.Secondary,
    style: {
      textTransform: "uppercase",
    },
    iconName: "plus",
    iconStyle: {
      marginRight: "calc(var(--tblr-btn-padding-x) / 16)",
    },
  });

  cards.projectsDetail = TbProjectDetail<AppState>({
    id: getProjectId,

    projectDetail: (s) => {
      const projectId = getProjectId(s);
      const tmp = projectId ? s.projects[projectId] : undefined;
      return (
        tmp &&
        ({
          name: tmp.name,
          country: tmp.country,
          description: tmp.description,
          createdTime: tmp.createdTime,
          modifiedTime: tmp.modifiedTime,
        } as SaveProjectDetail)
      );
    },
  });

  cards.projectsCreateDetail = TbProjectDetail<AppState>({});
}

const capitaliseFirstChar = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

function projectDetail(state: AppState, id: string): DataGridEl[] {
  const project = state.projects[id] as ProjectDetail;
  if (!project) {
    return [];
  }

  let a: DataGridEl[] = [];

  a.push({
    id: "createdTime",
    title: "Created Time",
    type: DataGridElType.Text,
    value: (project as any).createdTime
      ? capitaliseFirstChar(
          formatRelative(new Date((project as any).createdTime), new Date())
        )
      : "",
  });

  a.push({
    id: "modifiedTime",
    title: "Modified Time",
    type: DataGridElType.Text,
    value: (project as any).modifiedTime
      ? capitaliseFirstChar(
          formatRelative(new Date((project as any).modifiedTime), new Date())
        )
      : "",
  });

  a = a.concat(
    [
      "country",
      "id",

      // TODO: Not sure yet how to get these values from IVCAP
      // "imageCount",
      // "createdByUser",
    ].map((k) => ({
      id: k,
      title: k === "id" ? "Project ID" : undefined,
      type: DataGridElType.Text,
      value: (project as any)[k],
    }))
  );

  a.push({ type: DataGridElType.Separator });
  a.push({
    id: "description",
    title: "Description",
    value: project.description,
    type: DataGridElType.Text,
  });

  a.push({ type: DataGridElType.Separator });
  a.push({
    id: "selectProjectButton",
    title: "Select project",
    label: "Select",
    buttonType: TbButtonType.Secondary,
    type: DataGridElType.Button,
    actionTemplate: {
      type: ACTION_TYPES.SELECT_PROJECT,
      id: project.id,
    },
  });

  a.push({ type: DataGridElType.Separator });
  a.push({
    id: "editProjectButton",
    title: "Edit project",
    label: "Edit",
    buttonType: TbButtonType.Secondary,
    type: DataGridElType.Button,
    actionTemplate: {
      type: ACTION_TYPES.SHOW_INDIVIDUAL,
      id: project.id,
    },
  });

  a.push({
    id: "deleteProjectButton",
    title: "Delete project",
    label: "Delete",
    buttonType: TbButtonType.Danger,
    type: DataGridElType.Button,
    actionTemplate: {
      type: ACTION_TYPES.DELETE_PROJECT,
      name: project.name,
      recordId: project.recordId,
    },
  });
  return a;
}
