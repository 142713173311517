import type { ReduxState } from "@pihanga/core";
import {
  ArtifactDataEvent,
  ErrorAction,
  LoadOrderListEvent,
  MetadataQueryResultItem,
  OrderListItem,
  OrderRecord,
} from "./ivcap"; // "@pihanga/ivcap";
import { ModalState } from "@pihanga/cards/dist/modalWrapper";
import {
  ShowAnalyticCoverageInModalEvent,
  ShowArtifactInModalEvent,
} from "./app.actions";
import { AiBotState } from "./ai-bot/ai-bot.type";
import { SearchStats } from "./ivcap/search";

type AppRoute = {
  // remove after fixing @pihanag/core/router/router.reducer.js:
  //   registerReducer('REDUX', 'INIT', function (state) {
  //     var url;
  //     if (state.route.path) { << should check for 'empty' path

  path: string[] | null;
  query: { [k: string]: any };
  //path: string[];
};

export type ArtifactDict = {
  [id: string]: ArtifactDataEvent;
};

export const MetadataSchema = {
  DEFAULT: "",
  IMG_LAT_LNG: "urn:ibenthos:schema:field_image.2",
};

export type ArtifactMetadataDict = {
  [id: string]: { [schema: string]: ArtifactDetail };
};

export type AppState = ReduxState & {
  projectListing: ProjectListing;
  projects: { [id: string]: ProjectDetail };

  analyticListing: OrderListing;
  analytics: { [id: string]: OrderDetail };

  orderListing: OrderListing;
  orders: { [id: string]: OrderDetail };

  analyticModal?: ShowAnalyticCoverageInModalEvent;

  artifacts: ArtifactDict;
  artifactMetadataList: ArtifactMetadataDict;

  artifactModal?: ShowArtifactInModalEvent & {
    imgURL: string;
    fetching?: boolean;
  };
  collectionListing: CollectionListing;
  collections: { [id: string]: CollectionDetail };

  services: { [id: string]: ServiceDetail };

  aiBot: AiBotState;

  modal: ModalState;

  authorization: AuthorizationState;

  ivcapApi: URL;
  version: string;

  route: AppRoute;
  prevMeaningfulRoute?: AppRoute;

  showing?: string; // urn of current focus
  error?: ErrorAction;
  debug?: { [k: string]: any };
  confirmDelete?: {
    title: string;
    performDelete: () => void;
  };

  featureFlags: Set<string>;
};

export type ArtifactDetail = {
  records: MetadataQueryResultItem[];
  fetching?: boolean;
};

export type URN = string;
export type Date = string;

export type OrderListing = {
  offset: number;
  list: OrderListItem[];
  fetchedAt?: Date;
  fetching: boolean;
  loadOrderListEvent: LoadOrderListEvent;
  nextPage?: string;
};

export type OrderDetail = {
  record: OrderRecord;
  fetchedAt: Date;
};

export type SaveProjectDetail = {
  name: string;
  country: string;
  description: string;
  createdTime: string;
};

export type ProjectSummary = SaveProjectDetail & {
  id: string; // IVCAP Entity ID
  idShort?: string;
  recordId: string; // IVCAP Record ID, used for deleting the project
  modifiedTime?: string;
};

export type ProjectDetail = ProjectSummary & {
  // TODO: not sure yet how to get these from IVCAP
  imageCount?: number;
  createdByUser?: string;
};

export type ProjectListing = {
  offset: number;
  list: ProjectSummary[];
  nextPage?: string;
  fetchedAt?: number;
  fetching: boolean;
};

// COLLECTION

export type CollectionListing = {
  offset: number;
  list: CollectionSummary[];
  fetchedAt?: number;
  nextPage?: string;
  fetching: boolean;
};

export type CollectionSummary = {
  id: string;
  name: string;
  project?: string;
};

export enum CollectionView {
  Map = "map",
  Table = "table",
}

export type CollectionDetail = {
  id: string;
  summary: CollectionSummary;
  images?: CollectionImage[];
  offset: number;
  nextPage?: string;
  fetching?: boolean;
  imageCount?: number;
  stats?: SearchStats;
  showAnalyticsOrder?: boolean;
  selectAllImages?: boolean;
  selectedAnalyticsService?: string;
  selectedAnalyticsServiceList?: {
    list: string[];
    offset: number;
    nextPage?: string;
    fetching?: boolean;
  };
  pendingOrderRef?: {
    serviceId: string;
    imagesCollectionId: string;
    orderRef?: string;
  };
  confirmedOrderID?: string;
  useView?: CollectionView;

  mapCenter?: { lat: number; lng: number };

  latestOrder?: OrderListItem;
  // record: ArtifactRecord;
  // metadata?: URN[]; // Metadata record ID
  // fetchedAt: number;
};

export type CollectionImage = {
  name: string;
  artifactURN: string;
  size: number;
  height: number;
  width: number;
  format: string;
  latitude: number;
  longitude: number;

  selected?: boolean;
};

export type ServiceDetail = {
  id: string;
  name: string;
  description: string;
};

// export type ServiceSummary = {
//   id: URN;
//   links: {
//     self: URL
//   };
//   name: string;
//   provider: {
//     id: URN;
//   };
// }

// export type ServiceDetail = {
//   id: URN;
//   name: string;
//   description: string;
//   metadata: Metadata[];
//   parameters: Parameter[];
//   links: SelfRef;
//   provider: IdRef;
//   account: IdRef;
// }

// export type Parameter = {
//   name: string;
//   description: string;
//   type: string;
//   options?: Option[];
//   default?: string;
//   label?: string;
//   optional?: boolean;
//   unit?: string;
// }

// export type Option = {
//   description: string;
//   value: string;
// }

// // export type Order = {
// //   id: URN;
// //   name: string;
// //   status: string;
// //   links: SelfRef;
// //   ordered_at: Date;
// //   finished_at: Date;
// //   account_id: URN;
// //   service_id: URN;
// // }

// export type IdRef = {
//   id: URN;
// }

// export type SelfRef = {
//   self: URL;
// }

// export type Metadata = {
//   name: string;
//   value: string;
// }

export type AuthorizationState = {
  authorized: boolean;
  justSignedOut?: boolean;
  lastPath?: string[];
  errorMessage?: string;
};

// export type ParamType = 'string' | 'number' | 'date' | 'file' | 'password';

// export enum AlertSeverityE {
//   Error = 'error',
//   Warning = 'warning',
//   Info = 'info',
//   Success = 'success',
// }
