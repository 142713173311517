import { ReduxAction, createOnAction, registerActions } from "@pihanga/core";

export const ACTION_TYPES = registerActions("APP:PROJECTS", [
  "CREATE_NEW_PROJECT",
  "SELECT_PROJECT",
  "SHOW_LISTING",
  "SHOW_INDIVIDUAL",
  "LISTING",
  "EDIT_PROJECT",
  "DELETE_PROJECT",
  "ERROR",
]);

export type CreateNewProjectEvent = {};

export const onCreateNewProjectEvent = createOnAction<CreateNewProjectEvent>(
  ACTION_TYPES.CREATE_NEW_PROJECT
);
export const createCreateNewProjectAction = (): ReduxAction &
  CreateNewProjectEvent => ({
  type: ACTION_TYPES.CREATE_NEW_PROJECT,
});

export type SelectProjectEvent = { id: string };

export const onSelectProject = createOnAction<SelectProjectEvent>(
  ACTION_TYPES.SELECT_PROJECT
);
export const createSelectProjectAction = (
  id: string
): ReduxAction & SelectProjectEvent => ({
  type: ACTION_TYPES.SELECT_PROJECT,
  id,
});

export type ShowProjectListingEvent = {
  updateDataOnly?: boolean;
};

export const onShowProjectListing = createOnAction<ShowProjectListingEvent>(
  ACTION_TYPES.SHOW_LISTING
);
export const createShowProjectListingAction = (
  updateDataOnly?: boolean
): ReduxAction & ShowProjectListingEvent => ({
  type: ACTION_TYPES.SHOW_LISTING,
  updateDataOnly,
});

export type ShowProjectDetailEvent = {
  id: string;
};

export const onShowProjectIndividual = createOnAction<ShowProjectDetailEvent>(
  ACTION_TYPES.SHOW_INDIVIDUAL
);
export const createShowProjectDetailAction = (
  id: string
): ReduxAction & ShowProjectDetailEvent => ({
  type: ACTION_TYPES.SHOW_INDIVIDUAL,
  id,
});

export type DeleteProjectDetailEvent = {
  name: string;
  recordId: string;
};

export const onDeleteProject = createOnAction<DeleteProjectDetailEvent>(
  ACTION_TYPES.DELETE_PROJECT
);
