import { PiCardDef, PiMapProps, PiRegister } from "@pihanga/core";
import { Component } from "./tbGeneric.component";
import type { ComponentProps } from "./tbGeneric.component";

export const TB_GENERIC_COMPONENT = "TbGenericComponent";

type CardProps<S> = PiMapProps<ComponentProps, S>;
export function TbGenericComponent<S>(p: CardProps<S>): PiCardDef {
  return {
    ...p,
    cardType: TB_GENERIC_COMPONENT,
  };
}

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_GENERIC_COMPONENT,
    component: Component,
  });
}
