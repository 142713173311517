import {
  createListUrlBuilder,
  dispatchEvent,
  restErrorHandling,
} from "./common";
import { ACTION_TYPES, DEF_LIST_LIMIT, LoadListEvent } from "./actions";
import { getAccessToken } from "./index";
import { registerPOST } from "@pihanga/rest-client";
import {
  createOnAction,
  dispatchFromReducer,
  PiRegister,
  ReduxAction,
  ReduxState,
} from "@pihanga/core";

export const TOP_SPECIES_NUMBER = 3;
export type SearchGetCollStatsEvent<T = {}> = LoadListEvent<T> & {
  collectionID: string;
};

export type SearchGetCollStatsResultEvent = {
  collectionID: string;
  stats: SearchStats;
};

export type SearchGetImageCountEvent<T = {}> = LoadListEvent<T> & {
  collectionID: string;
};

export type SearchGetImageCountResultEvent = {
  collectionID: string;
  count: number;
};

export const onSearchGetImageCountResult =
  createOnAction<SearchGetImageCountResultEvent>(
    ACTION_TYPES.SEARCH_GET_IMAGE_COUNT_RESULT
  );

export const onSearchGetCollectionStatsResult =
  createOnAction<SearchGetCollStatsResultEvent>(
    ACTION_TYPES.SEARCH_GET_COLLECTION_STATS_RESULT
  );

export function dispatchIvcapSearchGetImageCount<T = {}>(
  ev: SearchGetImageCountEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.SEARCH_GET_IMAGE_COUNT,
    apiURL: ev.apiURL,
    page: ev.page,
    filter: ev.filter,
    limit: ev.limit || DEF_LIST_LIMIT,
    template: ev.template,
    collectionID: ev.collectionID,
  });
}

export function dispatchIvcapSearchGetCollectionStats<T = {}>(
  ev: SearchGetCollStatsEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.SEARCH_GET_COLLECTION_STATS,
    apiURL: ev.apiURL,
    page: ev.page,
    filter: ev.filter,
    limit: ev.limit || DEF_LIST_LIMIT,
    template: ev.template,
    collectionID: ev.collectionID,
  });
}

type SearchResultItem = {
  count: number;
  coverSum: number;
  siteID: string;
  species: string;
};

export type SearchStats = {
  [siteID: string]: {
    [species: string]: number;
  };
};

const findTopSpecies = (
  items: SearchResultItem[],
  topSpeciesNumber: number
): SearchStats => {
  const result: SearchStats = {};

  items.forEach((el) => {
    const siteID = el.siteID;
    const species = el.species;

    if (!result[siteID]) {
      result[siteID] = {};
    }

    result[siteID][species] = el.coverSum / el.count;
  });

  for (const siteID in result) {
    const speciesData = Object.entries(result[siteID]);
    const topSpecies = speciesData
      .sort((a, b) => b[1] - a[1])
      .slice(0, topSpeciesNumber);

    result[siteID] = Object.fromEntries(topSpecies);
  }

  return result;
};

export function init(register: PiRegister): void {
  registerPOST<ReduxState, ReduxAction & SearchGetImageCountEvent, any>(
    register
  )({
    name: "ivcap-api:search:get-image-count",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("search"),
    trigger: ACTION_TYPES.SEARCH_GET_IMAGE_COUNT,
    request: ({ collectionID }) => {
      return {
        body:
          ':load_aspect(/field_image, "urn:ibenthos:schema:field_image.2", [".name", ".field_collection"]).\n' +
          "\n" +
          "unique(N, Count) :-\n" +
          `    field_image(N, "${collectionID}"),\n` +
          "    |> do fn:group_by(N),\n" +
          "       let Count = fn:count().\n" +
          "\n" +
          "query(Count) :-\n" +
          "    unique(_, _),\n" +
          "    |> do fn:group_by(),\n" +
          "       let Count = fn:count().",
        contentType: "application/datalog+mangle",
      };
    },

    headers: () => ({
      Authorization: `Bearer ${getAccessToken()}`,
    }),
    reply: (state, content: any, { template, collectionID }) => {
      const count =
        content.items && content.items.length && content.items[0].count;

      dispatchEvent(
        {
          collectionID,
          count,
        },
        ACTION_TYPES.SEARCH_GET_IMAGE_COUNT_RESULT,
        template
      );

      return state;
    },
    error: restErrorHandling("ivcap-api:search:get-image-count"),
  });

  registerPOST<ReduxState, ReduxAction & SearchGetCollStatsEvent, any>(
    register
  )({
    name: "ivcap-api:search:get-collection-stats",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("search"),
    trigger: ACTION_TYPES.SEARCH_GET_COLLECTION_STATS,
    request: ({ collectionID }) => {
      return {
        // TODO: Asked Max on "iBenthos UI Fortnightly Sync-up" teams chat about how to make this query specific to a given collection
        body:
          ':load_aspect(/survey_image, "urn:ibenthos:schema.survey_image.3", [/entity, ".site_id", ".survey.analysis[0].seagrass_species[*].name|percent_cover"]).\n' +
          "\n" +
          "query(SiteID, Species, CoverSum, Count) :-\n" +
          "    survey_image(_, SiteID, Species, Cover),\n" +
          "    |> do fn:group_by(SiteID, Species),\n" +
          "        let CoverSum = fn:float:sum(Cover),\n" +
          "        let Count = fn:count().",
        contentType: "application/datalog+mangle",
      };
    },

    headers: () => ({
      Authorization: `Bearer ${getAccessToken()}`,
    }),
    reply: (state, content: any, { template, collectionID }) => {
      dispatchEvent(
        {
          collectionID,
          stats: findTopSpecies(content.items, TOP_SPECIES_NUMBER),
        },
        ACTION_TYPES.SEARCH_GET_COLLECTION_STATS_RESULT,
        template
      );

      return state;
    },
    error: restErrorHandling("ivcap-api:search:get-collection-stats"),
  });
}
