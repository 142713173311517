import { dispatchFromReducer, PiCardDef } from "@pihanga/core";
import { AppState, ServiceDetail } from "../app.type";

import { PiFlexGrid } from "@pihanga/cards/dist/flexGrid";
import { PiMarkdown } from "@pihanga/cards/dist/markdown";
import { TbSteps } from "../cards/tbSteps";
import { ColumnType, Row, TbXlDataTable } from "../cards/tbDataTable";
import {
  DataGridEl,
  DataGridElType,
  TbDataGrid,
} from "@pihanga/tabler/dist/cards/tbDataGrid";
import { getCollection } from "../app.state";
import {
  appendBotMessageToServiceDetail,
  appendBotServiceTable,
  appendBotStatusToServiceList,
} from "../ai-bot/ai-bot.pihanga";
import { TbGenericComponent } from "../cards/tbGenericComponent";
import React from "react";
import { createSelectAllImagesAction } from "./collection.action";
import { NO_OF_ANALYTIC_SERVICES_PER_PAGE } from "./collection.reducer";

const SelectAlgoInfo = `
**Select an analytics task from the list below to be performed over
selected images in this collection**
`;

const SelectImagesInfo = `
**Select images in the table below to analyse**
`;

const ReceiptPlaceHolder = "@@ORDER@@";
const ReceiptMesg = `## The job '${ReceiptPlaceHolder}' has been scheduled. `;

type AnalyticsServiceItem = {
  id: string;
  id_short: string;
  name: string;
};

export function init(cards: { [k: string]: PiCardDef }): void {
  cards.createAnalyticsOrder = TbSteps<AppState>({
    id: "analytics-order",
    title: "Request an Analytics Task on this Collection",
    steps: (s) => {
      const collection = getCollection(s);
      const algoSelected = collection && collection.selectedAnalyticsService;
      const imagesSelected =
        collection &&
        (collection.selectAllImages ||
          (collection.images &&
            collection.images.find((image) => image.selected)));

      const orderPlaced = !!(collection && collection.confirmedOrderID);
      return [
        {
          id: "select",
          label: "Select Task",
          card: "analyticsSelectAlgo",
          nextButtonLabel: algoSelected ? "Next" : "Select Algo",
          nextButtonDisabled: !algoSelected,
        },
        {
          id: "select-images",
          label: "Select Images",
          card: "imagesSelectStep",
          nextButtonLabel: imagesSelected ? "Next" : "Select images",
          nextButtonDisabled: !imagesSelected,
        },
        {
          id: "confirm",
          label: "Confirm",
          card: "confirmStep",
          nextButtonLabel: "Confirm",
          nextButtonClass: "btn-warning",
        },
        {
          id: "receipt",
          label: "Receipt",
          card: orderPlaced ? "receiptStep" : "spinnerCard",
          nextButtonLabel: "Done",
          nextButtonDisabled: !orderPlaced,
          hideNavigationButtons: true,
        },
      ];
    },
    // activeStep: 0,
    selfManaged: true,
    vertical: true,
    lastStepIsConfirm: true,
    showCounter: true,
    gap: "2em",
  });

  cards.analyticsSelectAlgo = PiFlexGrid<AppState>({
    cards: ["analyticsSelectAlgoInfo", "analyticsServicesTable"],
    template: {
      area: [[0], [1]],
      rows: ["auto"],
      columns: ["auto"],
      gap: "10px",
    },
  });

  cards.analyticsSelectAlgoInfo = PiMarkdown<AppState>({
    source: SelectAlgoInfo,
  });

  cards.imagesSelectStep = PiFlexGrid<AppState>({
    cards: ["imagesSelectStepInfo", "imagesSelectAll"],
    template: {
      area: [[0], [1]],
      rows: ["auto"],
      columns: ["auto"],
      gap: "10px",
    },
  });

  cards.imagesSelectStepInfo = PiMarkdown<AppState>({
    source: SelectImagesInfo,
  });

  cards.imagesSelectAll = TbGenericComponent<AppState>({
    content: (s) => {
      const coll = getCollection(s);

      return (
        <div>
          <strong>OR</strong>
          <label className="form-check mt-3 form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={coll && coll.selectAllImages}
              onChange={(e): void => {
                if (coll) {
                  dispatchFromReducer(
                    createSelectAllImagesAction(coll.id, e.target.checked)
                  );
                }
              }}
            />
            <span className="form-check-label">Load and select all images</span>
          </label>
        </div>
      );
    },
  });

  cards.confirmStep = PiMarkdown<AppState>({
    source: (s) => {
      const coll = getCollection(s);
      const service =
        s.services[coll?.selectedAnalyticsService || ""]?.name || "???";

      const showSelectAllMsg =
        !coll ||
        coll.selectAllImages ||
        !coll.images ||
        coll.images.filter((i) => i.selected).length === coll.images.length;

      return `## You have selected to analyse ${
        showSelectAllMsg
          ? "all images"
          : `${coll?.images?.filter((i) => i.selected).length} images`
      } in this collection with '${service}'. 
### Please click on the 'Confirm' button to create a job.`;
    },
  });

  cards.receiptStep = PiMarkdown<AppState>({
    source: (s) => {
      const coll = getCollection(s);
      const order = coll?.confirmedOrderID || "???";
      return ReceiptMesg.replace(ReceiptPlaceHolder, order);
    },
  });

  cards.analyticsServicesTable = TbXlDataTable<AppState, AnalyticsServiceItem>({
    columns: (s) => {
      const cols: any[] = [
        // any is ugly, but dataTable does NOT export GenericColumn
        { label: "selected", title: "", type: ColumnType.Toggle },
        // { label: 'aibot', title: 'Bot', type: ColumnType.Icon, iconType: IconType.ReactComponent, headerStyle: { "width": "1%" } },
        {
          label: "id_short",
          title: "Id",
          headerStyle: { width: "1%" },
          type: ColumnType.String,
        },
        {
          label: "name",
          title: "Name",
          sortable: false, // name is a derived value from service record, the metadata list doesn't have this information to sort
          type: ColumnType.String,
        },
        // { label: 'provider', title: 'Provider', sortable: true },
      ];
      appendBotServiceTable(cols, s);
      return cols;
    },
    data: (s: AppState) => {
      const collection = getCollection(s);
      let services: [string, ServiceDetail][];

      let offset = 0;
      if (!collection) {
        services = [];
      } else {
        offset =
          (collection.selectedAnalyticsServiceList &&
            collection.selectedAnalyticsServiceList.offset) ||
          0;

        services = (
          (collection.selectedAnalyticsServiceList &&
            collection.selectedAnalyticsServiceList.list) ||
          []
        )
          .slice(offset, offset + NO_OF_ANALYTIC_SERVICES_PER_PAGE)
          .map((id) => [id, s.services[id]]);
      }

      return services.map(([id, el], idx) => {
        const id_short = id.substring(id.length - 6);
        const selected = collection
          ? collection.selectedAnalyticsService === id
          : false;
        const data = { ...el, id, id_short, selected }; // need to override 'id' from el
        appendBotStatusToServiceList(data, el, s);

        return {
          id,
          data,
          detailCard: "analyticsServiceDetailCard", // idx === 2 ? "spinnerCard" : undefined,
        };
      });
    },
    hasDetails: true,
    manageDetails: true,
    showSearch: false,
    showPageSizeSelector: false,
    dataOffset: (s: AppState) => {
      const col = getCollection(s);
      return col && col.selectedAnalyticsServiceList
        ? col.selectedAnalyticsServiceList.offset
        : 0;
    },
    hasMore: (s) => {
      const col = getCollection(s);

      if (col && col.selectedAnalyticsServiceList) {
        const canLoadMore =
          (col.selectedAnalyticsServiceList.offset || 0) +
            NO_OF_ANALYTIC_SERVICES_PER_PAGE <
          (col.selectedAnalyticsServiceList.list.length || 0);

        return !!col.selectedAnalyticsServiceList.nextPage || canLoadMore;
      } else {
        return false;
      }
    },
  });

  cards.analyticsServiceDetailCard = TbDataGrid<AppState>({
    title: (s, _, ctxt) => {
      return (ctxt["row"] as Row<AnalyticsServiceItem>).data?.name;
    },
    items: (s, _, ctxt) => {
      const service = (ctxt["row"] as Row<AnalyticsServiceItem>).data;
      return serviceDetail(s, service.id);
    },
    cardOnEmpty: "spinnerCard",
  });
}

function serviceDetail(state: AppState, id: string): DataGridEl[] {
  const service = state.services[id];
  if (!service) {
    return [];
  }

  let a: DataGridEl[] = [];

  a.push({
    id: "description",
    title: "Description",
    value: service.description,
    type: DataGridElType.Text,
  });

  appendBotMessageToServiceDetail(a, id, state);

  // id: string;
  // name: string;
  // description: string;
  // parameters: ServiceParameter[];
  // providerID: URN;
  // accountID: URN;

  a.push({ type: DataGridElType.Separator });
  a.push(
    ...["id", "name"].map(
      (k: string) =>
        ({
          id: k,
          title: k === "id" ? "Service ID" : undefined,
          type: DataGridElType.Text,
          value: (service as any)[k],
        } as DataGridEl)
    )
  );

  // a = a.concat(['orderedAt', 'startedAt', 'finishedAt'].map((k) => ({
  //   id: k,
  //   type: DataGridElType.Date,
  //   value: (service as any)[k],
  // })))
  // a.push({ type: DataGridElType.Separator })
  // a = a.concat(['serviceID', 'accountID'].map((k) => ({
  //   id: k,
  //   type: DataGridElType.Text,
  //   value: (service as any)[k],
  // })))
  // a.push({ type: DataGridElType.Separator, title: 'Parameters' })
  // a = a.concat(order.parameters.map(orderParam))
  // a = a.concat(orderProducts(order))
  return a;
}
