import React, { useRef, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import type {
  ClustererOptions,
  Clusterer,
} from "@react-google-maps/marker-clusterer";

// import styled from './facetMap.style';
// import { CardAction, Resources } from '../../../app.type';
import { PiCardSimpleProps } from "@pihanga/core";

// const SELECTED_MARKER = ['/womens-directory/map_marker_single_sel.png', '/womens-directory/map_marker_multi_sel.png'];
// const DEF_MARKER = ['/womens-directory/map_marker_single_def.png', '/womens-directory/map_marker_multi_def.png'];
// const SELECTED_MULTI_MARKER = '/womens-directory/map_marker_multi_sel.png';
// const DEF_MULTI_MARKER = '/womens-directory/map_marker_multi_def.png';

export type ComponentProps = {
  markers: MarkerT[];
  apiKey: string;
  zoomLevel?: number;
  width?: string | number;
  height?: string | number;
  center?: { lat: number; lng: number };
  onMarkerSelected: (ev: MapMarkerSelectedEvent) => void;
};

export type MarkerT = {
  id: string;
  label?: string;
  lat: number;
  lng: number;
};

export type Resources = {
  searchText?: string; // text used to filter records
  searchValue?: string; // currently shown in search box
  geoBounds?: GeoBounds;
  highlight?: HighlightSelection;
  records: ResourceRecord[];
};

export type ResourceRecord = {
  id: number;
  name: string;
  logo: string;
  description: string;
  url: string;
  topics: string[];
  locations: ResourceLocation[];
};

export type ResourceLocation = {
  id: number;
  address: string;
  state: string;
  lat: number;
  lon: number;
};

export type GeoBounds = {
  n: number;
  e: number;
  s: number;
  w: number;
};

export type HighlightSelection = {
  resources: {
    resourceID: number;
    locationID: number;
  }[];
  ts: number;
};

export type MarkerProps = {
  id: string;
  label?: string;
  lat: number;
  lng: number;
  ghash: string;
};

export type MapMarkerSelectedEvent = {
  markerIDs: string[];
};

export type MapMarkersInBoundsEvent = {
  bounds: { n: number; e: number; s: number; w: number };
  markerIDs: string[];
};

export type MapCloseEvent = {};

type ComponentT = ComponentProps & {
  onMarkersInBounds: (ev: MapMarkersInBoundsEvent) => void;
  onMapClose: (ev: MapCloseEvent) => void;
};

const DEFAULT_CENTER = {
  lat: -25.8080134,
  lng: 134.6461083,
};

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const {
    cardName,
    markers,
    apiKey,
    zoomLevel = 3,
    width = "auto",
    height = 400,
    center,
    onMarkerSelected,
    onMarkersInBounds,
  } = props;
  const elRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  // const [infoBoxLoc, setInfoBoxLoc] = useState<google.maps.LatLng | undefined>();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    // ...otherOptions
  });

  // console.log('MAP REF/loaded', map, isLoaded);
  const style = {};

  // function onCloseMap(): void {
  //   onMapClose({});
  // }

  function renderMap(): React.ReactNode {
    if (!isLoaded) return null;

    const locs: MarkerProps[] = markers.map((m, i) => {
      const _id = `${m.id}`;
      const ghash = `${m.lat}:${m.lng}`;
      const label = m.label || "";
      return { id: _id, ghash, label, lat: m.lat, lng: m.lng };
    });

    //     const locs: MarkerProps[] = []

    const containerStyle = { width, height };

    const options: google.maps.MapOptions = {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControlOptions: {
        // position: google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
        // ...otherOptions
      },
    };

    function onBoundsChanged(): void {
      if (map) {
        var b = map.getBounds();
        if (b === undefined) return; // can't get bounds

        const markerIDs = locs
          .filter((l) => {
            const p = new google.maps.LatLng(l.lat, l.lng);
            return b && b.contains(p);
          })
          .map((r) => r.id);
        var sw = b.getSouthWest();
        var ne = b.getNorthEast();
        const bounds = {
          n: ne.lat(),
          e: ne.lng(),
          s: sw.lat(),
          w: sw.lng(),
        };
        onMarkersInBounds({ bounds, markerIDs });
        // console.log('BOUNDS CHANGED', markerIDs.length, sw, ne.toString());
      }
    }

    function onMarker(sel: MarkerProps[]): void {
      const markerIDs = sel.map((s) => s.id);
      onMarkerSelected({ markerIDs });
    }

    const clusterOpts: ClustererOptions = {
      gridSize: 30,
      //imagePath: 'img/markerclusterer/m',
    };

    // deal with potentially multiple markers being at the same location
    // TODO: Should make that fuzzy as we may not zoom in deep enough to separate closeby images
    const locb = locs.reduce((p, m) => {
      const a = p[m.ghash] || [];
      p[m.ghash] = a.concat(m);
      return p;
    }, {} as { [k: string]: MarkerProps[] });

    function renderMarker(
      el: MarkerProps[],
      clusterer: Clusterer
    ): React.ReactElement {
      // console.log("renderMarker", el);
      const isSingle = el.length < 2;
      const l = el[0];
      // const idx = isSingle ? 0 : 1;
      // const icon = DEF_MARKER[idx] // hlid.has(l.rid) ? SELECTED_MARKER[idx] : DEF_MARKER[idx];
      const lat: number = typeof l.lat === "string" ? parseFloat(l.lat) : l.lat;
      const lng: number = typeof l.lng === "string" ? parseFloat(l.lng) : l.lng;
      return (
        <MarkerF
          key={l.ghash}
          position={{ lat, lng }}
          title={isSingle ? l.label : "Co-located resources"}
          // icon={icon}
          onClick={(): void => onMarker(el)}
          clusterer={clusterer}
        />
      );
    }

    function renderMarkerClusterer(): React.ReactNode {
      const markers = Object.values(locb);
      return (
        <MarkerClusterer options={clusterOpts}>
          {(clusterer: Clusterer): any => (
            <>{markers.flatMap((el) => renderMarker(el, clusterer))}</>
          )}
        </MarkerClusterer>
      );
    }

    return (
      <GoogleMap
        options={options}
        mapContainerStyle={containerStyle}
        center={center || DEFAULT_CENTER}
        zoom={zoomLevel}
        onLoad={setMap}
        onBoundsChanged={onBoundsChanged}
      >
        {renderMarkerClusterer()}
        {/* {renderClose()} */}
      </GoogleMap>
    );
  }

  // function renderInfoBox() {
  //   if (!infoBoxLoc) return null;
  //   return (
  //     <InfoWindow position={infoBoxLoc}>
  //       <div style={{color: 'yellow'}}>
  //         <h1>InfoWindow</h1>
  //       </div>
  //     </InfoWindow>
  //   )
  // }

  // function renderClose(): React.ReactNode {
  //   return (
  //     <button
  //       draggable="false"
  //       title="Close map selector"
  //       aria-label="Close map selector"
  //       type="button"
  //       onClick={onCloseMap}
  //       className={`gmap-control-active`}
  //     >
  //       {[666, 333, 111].map((fill, idx) => (
  //         <img
  //           src={getCross(fill)}
  //           alt=""
  //           className={cls(idx > 0 && 'gmap-icon-hover', 'gmap-close-map')}
  //           key={idx}
  //         />
  //       ))
  //       }
  //     </button>
  //   );
  // }

  // function getCross(fill: number): string {
  //   return [
  //     'data:image/svg+xml,',
  //     '%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20',
  //     'height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%20%20%3Cpolygon%20',
  //     'fill%3D%22%23',
  //     fill,
  //     '%22%20points%3D%2218%2C7%2011%2C7%2011%2C0%207%2C0%207%2C7%200%2C7%200%2C11%207%2C11',
  //     '%207%2C18%2011%2C18%2011%2C11%2018%2C11%22%20%20transform%3D%22rotate%28-45%209%209%29',
  //     '%22%2F%3E%0A%3C%2Fsvg%3E%0A',
  //   ].join('');
  // }

  function renderError(): React.ReactNode {
    return (
      <div className={"pi-gmap-error-msg"}>
        Map cannot be loaded right now, sorry.
      </div>
    );
  }

  return (
    <div
      className={`pi-gmap pi-gmap-${cardName}`}
      style={style}
      ref={elRef}
      data-pihange={cardName}
    >
      {loadError ? renderError() : renderMap()}
    </div>
  );
};

// export const Component2 = styled((props: ClassedProps<ComponentT>) => {
//   const {
//     cardName,
//     style,
//     classes,
//   } = props;

//   return (
//     <div className={classes.outer} style={ style }  data-pihange={ cardName }>
//       <img src="/img/oz_map.png" />
//     </div>
//   );
// }) as React.FC<ComponentT>;
