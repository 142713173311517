import {
  createOnAction,
  dispatchFromReducer,
  PiRegister,
  RouterShowPageAction,
  update,
} from "@pihanga/core";
import { v4 as uuidv4 } from "uuid";

import {
  dispatchIvcapGetArtifactRecord,
  dispatchIvcapGetServiceRecord,
  dispatchIvcapQueryMetadata,
  MetadataQueryResultItem,
  onArtifactRecord,
  onOrderReceipt,
  dispatchIvcapCreateOrder,
  dispatchIvcapAddMetadata,
  onMetadataAdded,
  DEF_LIST_LIMIT,
  dispatchIvcapGetOrderList,
  // } from "@pihanga/ivcap";
} from "../ivcap";
import {
  AppState,
  CollectionImage,
  CollectionSummary,
  CollectionView,
} from "../app.type";
import {
  dispatchShowArtifactInModal,
  dispatchUpdateArtifactData,
} from "../app.actions";
import {
  ACTION_TYPES,
  createShowCollectionDetailAction,
  createShowCollectionListingAction,
  onCollectionAnalyticsService,
  onShowCollectionIndividual,
  onShowCollectionListing,
  onLoadMoreImages,
  createLoadMoreImagesAction,
  onSelectAllImages,
  onPreviewArtifact,
  createLocateImageOnMap,
  onLocateImageOnMap,
} from "./collection.action";
import {
  onTbXlDataTableButtonClicked,
  onTbXlDataTableRowSelect,
  onTbXlDataTableCheckboxClicked,
  onTbXlDataTableColumnSort,
  onTbXlDataTableNextPage,
  onTbXlDataTablePreviousPage,
} from "../cards/tbDataTable";
import {
  getCollection,
  getCollectionId,
  getProjectContextRoute,
  getProjectId,
} from "../app.state";
import { onTbButtonClicked } from "@pihanga/tabler/dist/cards/tbButton";
import {
  onNextStep,
  onCancel as onStepsCancel,
  onConfirm as onStepsConfirm,
} from "../cards/tbSteps";

import { onNavBtnClicked } from "../cards/tbCard";
import { ACTION_TYPES as ANALYTIC_ACTION_TYPES } from "../analytic/analytic.action";
import {
  dispatchIvcapSearchGetCollectionStats,
  dispatchIvcapSearchGetImageCount,
  onSearchGetCollectionStatsResult,
  onSearchGetImageCountResult,
} from "../ivcap/search";

export const NO_OF_IMAGES_PER_PAGE = 10;
export const NO_OF_COLLECTIONS_PER_PAGE = 10;
export const NO_OF_ANALYTIC_SERVICES_PER_PAGE = 5;
const NO_OF_ANALYTIC_ORDERS_PER_PAGE = 5;

const dispatchGetCollectionImages = (
  state: AppState,
  collectionID: string,
  page?: string,
  loadAll?: boolean,
  orderBy?: string,
  orderDesc?: boolean
): AppState => {
  dispatchIvcapQueryMetadata({
    apiURL: state.ivcapApi,
    schema: "urn:ibenthos:schema:field_image.2",
    filter: `field_collection~='${collectionID}'`,
    page,
    limit: NO_OF_IMAGES_PER_PAGE,
    orderBy,
    orderDesc,
    template: {
      type: ACTION_TYPES.IMG_COLLECTION,
      collectionID,
      page,
      loadAll,
    },
  });

  return update(state, ["collections", collectionID, "fetching"], true);
};

const COLLECTION_SCHEMA = "urn:ivcap:schema:artifact-collection.1";

const createAnalyticOrder = (
  state: AppState,
  originalCollId: string,
  serviceId: string,
  imagesCollId: string
): AppState => {
  const pa = [
    {
      name: "model",
      value: "urn:ivcap:artifact:b441e73f-d5dd-45d5-826b-6827bc93b9b9",
    },
    {
      name: "images",
      value: imagesCollId,
    },
  ];

  const refID = `${originalCollId}@${Math.trunc(1e6 * Math.random())}`;
  dispatchIvcapCreateOrder<{ refID: string }>({
    apiURL: state.ivcapApi,
    id: refID,
    serviceID: serviceId,
    parameters: pa,
  });

  return update(state, ["collections", originalCollId], {
    pendingOrderRef: {
      serviceId: serviceId,
      imagesCollectionId: imagesCollId,
      orderRef: refID,
    },
  });
};

export function init(register: PiRegister): void {
  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      if (path.length === 0) {
        return state;
      }
      const page = path[2];
      if (page === "collections") {
        const item = path[3];

        if (item) {
          dispatchFromReducer(createShowCollectionDetailAction(item));
        } else {
          dispatchFromReducer(createShowCollectionListingAction());
        }
      }
      return state;
    }
  );

  onShowCollectionListing<AppState>(register, (state) => {
    const p = state.route.path || [];
    if (p[2] !== "collections") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["collections"]),
      });
    } else {
      const projectId = getProjectId(state);

      dispatchIvcapQueryMetadata({
        apiURL: state.ivcapApi,
        schema: "urn:ibenthos:schema:field_collection.%",
        filter: `project~='${projectId}'`,
        limit: NO_OF_COLLECTIONS_PER_PAGE,
        template: {
          type: ACTION_TYPES.LISTING,
        },
      });

      return update(state, ["collectionListing"], {
        offset: 0,
        fetching: true,
      });
    }
    return state;
  });

  // Handle collection listing returned from IVCAP
  createOnAction<{
    page?: string;
    nextPage?: string;
    records: MetadataQueryResultItem[];
  }>(ACTION_TYPES.LISTING)<AppState>(
    register,
    (state, { page, nextPage, records }) => {
      const collections = { ...state.collections };
      const f = function (r: MetadataQueryResultItem): CollectionSummary {
        const id = r.entity;

        const { name, project } =
          typeof r.aspect == "string" ? JSON.parse(r.aspect) : r.aspect; // Temporary fix: Backend should fix it soon, `aspect` should always be in JSON structure.

        const summary = { id, name, project };
        if (!collections[id]) {
          collections[id] = { id, summary, offset: 0 };
        }
        return summary;
      };
      const list = records.map(f);

      let s = update(state, ["collectionListing"], {
        list:
          page &&
          state.collectionListing?.list &&
          state.collectionListing?.list.length
            ? state.collectionListing?.list.concat(list)
            : list,
        nextPage,
        fetchedAt: Date.now(),
        fetching: false,
      });

      s = update(s, ["collections"], collections);

      const selectedCollectionId = getCollectionId(state);
      if (selectedCollectionId) {
        return dispatchGetCollectionImages(s, selectedCollectionId);
      }

      return s;
    }
  );

  onTbXlDataTableRowSelect<AppState>(register, (state, { cardID, row }) => {
    if (cardID === "collectionsTable") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["collections", `${row.id}`]),
      });
    } else if (cardID === "collectionDetailImagesTable") {
      const r = row.data as CollectionImage;
      dispatchFromReducer(
        createLocateImageOnMap(Number(r.latitude), Number(r.longitude))
      );
    }
    return state;
  });

  onShowCollectionIndividual<AppState>(register, (state, { collectionID }) => {
    const event = {
      apiURL: state.ivcapApi,
      limit: NO_OF_ANALYTIC_ORDERS_PER_PAGE,
      filter: `name~='${collectionID}'`,
      orderBy: "ordered-at",
      orderDesc: true,
      template: {
        type: ANALYTIC_ACTION_TYPES.RESULT_LIST,
      },
    };

    dispatchIvcapGetOrderList(event);

    dispatchIvcapSearchGetImageCount({
      apiURL: state.ivcapApi,
      collectionID,
    });

    dispatchIvcapSearchGetCollectionStats({
      apiURL: state.ivcapApi,
      collectionID,
    });

    if (
      state.collectionListing.list.length === 0 &&
      !state.collectionListing.fetching
    ) {
      dispatchFromReducer(createShowCollectionListingAction());
    } else {
      return dispatchGetCollectionImages(state, collectionID);
    }

    return update(state, ["analyticListing"], {
      loadOrderListEvent: event,
      fetching: true,
    });
  });

  onLoadMoreImages<AppState>(
    register,
    (state, { collectionID, page, loadAll }) => {
      return dispatchGetCollectionImages(state, collectionID, page, loadAll);
    }
  );

  // Handle 'IMG_COLLECTION' listing returned from IVCAP
  createOnAction<{
    records: MetadataQueryResultItem[];
    collectionID: string;
    nextPage: string;
    page: string;
    loadAll: boolean;
  }>(ACTION_TYPES.IMG_COLLECTION)<AppState>(
    register,
    (state, { records, collectionID, nextPage, page, loadAll }) => {
      const collection = state.collections[collectionID];
      const images = records
        .map((r) => {
          // Temporary fix: Backend should fix it soon, `aspect` should always be in JSON structure.
          const aspect =
            typeof r.aspect == "string" ? JSON.parse(r.aspect) : r.aspect;

          if (!aspect) return undefined;

          const artifactURN = aspect.url;

          dispatchIvcapGetArtifactRecord({
            id: aspect.url,
            apiURL: state.ivcapApi,
          });

          return {
            name: aspect.name,
            artifactURN,
            size: -1,
            height: aspect.height,
            width: aspect.width,
            format: aspect.format || "unknown",
            latitude: aspect.latitude,
            longitude: aspect.longitude,
            selected: collection && collection.selectAllImages,
          };
        })
        .filter((r) => r !== undefined) as CollectionImage[];

      const summary = state.collectionListing.list.find(
        (el) => el.id === collectionID
      );

      const newState = update(state, ["collections", collectionID], {
        id: collectionID,
        summary,
        images:
          page && collection && collection.images && collection.images?.length
            ? collection.images?.concat(images)
            : images,
        nextPage,
        fetching: false,
      });

      if (loadAll && nextPage) {
        dispatchGetCollectionImages(state, collectionID, nextPage, loadAll);
      }

      return newState;
    }
  );

  onTbXlDataTableButtonClicked<AppState>(register, (state, ev) => {
    switch (ev.cardID) {
      case "imagesSelectStepInfoTable":
      case "collectionDetailImagesTable":
        if (ev.label !== "preview") {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "collectionDetailImagesTable" from unknown label "${ev.label}"`
          );
          return state;
        }

        const id = ev.row?.id as string;
        if (id) {
          dispatchIvcapGetArtifactRecord({
            id,
            apiURL: state.ivcapApi,
          });

          return update(state, ["artifactModal"], {
            fetching: true,
          });
        } else {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "collectionDetailImages" from unknown label "${ev.label}"`
          );
          return state;
        }

      default:
        console.warn(
          `WARN: TBDTABLE:BUTTON_CLICKED from unknown cardID "${ev.cardID}"`
        );
    }
    return state;
  });

  onArtifactRecord<AppState>(register, (state, { artifact }) => {
    if (state.artifactModal?.fetching) {
      return update(
        dispatchShowArtifactInModal(
          state,
          artifact.id,
          artifact.dataURL,
          artifact.id
        ),
        ["artifactModal"],
        {
          fetching: false,
        }
      );
    } else {
      dispatchUpdateArtifactData(
        state,
        artifact.id,
        artifact.dataURL,
        artifact.id
      );

      return state;
    }
  });

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "request-analytics") {
      dispatchIvcapQueryMetadata({
        apiURL: state.ivcapApi,
        schema: "urn:ibenthos:schema:service.1",
        limit: NO_OF_ANALYTIC_SERVICES_PER_PAGE,
        template: {
          type: ACTION_TYPES.ANALYTIC_SERVICES,
        },
      });
      const collID = getCollectionId(state);
      if (!collID) return state;

      const selectedAnalyticsServiceList = Object.keys(state.services).filter(
        (serviceID) => {
          return testServiceSuitability(state, serviceID, collID);
        }
      );

      return update(state, ["collections", collID], {
        showAnalyticsOrder: true,
        selectedAnalyticsService: null,
        selectedAnalyticsServiceList: {
          list: selectedAnalyticsServiceList,
          offset: 0,
        },
        pendingOrderRef: null,
        confirmedOrderID: null,
      });
    } else {
      return state;
    }
  });

  onCollectionAnalyticsService<AppState>(
    register,
    (state, { page, nextPage, records }) => {
      const missingServiceP = (id: string): boolean => {
        return !state.services[id];
      };

      const collID = getCollectionId(state) || "";

      const serviceIds = records
        .map((r) => r.entity.split(":")[3])
        .filter((id) => {
          return testServiceSuitability(state, id, collID);
        });

      serviceIds.filter(missingServiceP).forEach((entity) => {
        dispatchIvcapGetServiceRecord({
          apiURL: state.ivcapApi,
          id: entity,
        });
      });

      const currentList =
        state.collections[collID].selectedAnalyticsServiceList &&
        state.collections[collID].selectedAnalyticsServiceList?.list;

      return update(
        state,
        ["collections", collID, "selectedAnalyticsServiceList"],
        {
          list:
            page && currentList && currentList?.length
              ? currentList?.concat(serviceIds)
              : serviceIds,

          nextPage,
          fetchedAt: Date.now(),
          fetching: false,
        }
      );
    }
  );

  onStepsCancel<AppState>(register, onCloseAnalyticsOrder);
  onStepsConfirm<AppState>(register, onCloseAnalyticsOrder);

  function onCloseAnalyticsOrder(
    state: AppState,
    ev: { id: string }
  ): AppState {
    if (ev.id !== "analytics-order") return state;
    const collID = getCollectionId(state);
    if (!collID) return state;

    return update(state, ["collections", collID], {
      showAnalyticsOrder: false,
      selectedAnalyticsServiceList: {
        list: [],
        offset: 0,
      },
    });
  }

  onNextStep<AppState>(register, (state, { id, currentStep }) => {
    // id: 'analytics-order',
    // currentStepID: 1,
    // currentStep: 'confirm'
    if (id !== "analytics-order" || currentStep !== "confirm") return state;
    const collID = getCollectionId(state);
    if (!collID) return state;

    const coll = state.collections[collID];
    const service = state.services[coll.selectedAnalyticsService || ""];
    if (!service) return state; // should throw some kind of error as it should never happen

    // create specific collection for this order
    const cid = `urn:ibenthos:collection:${uuidv4()}`;

    const aspect = {
      collection: cid,
      artifacts: coll.images
        ?.filter((i) => i.selected)
        .map((el) => el.artifactURN),
    };

    dispatchIvcapAddMetadata({
      entity: cid,
      schema: COLLECTION_SCHEMA,
      aspect,
      apiURL: state.ivcapApi,
    });

    return update(state, ["collections", collID], {
      pendingOrderRef: {
        serviceId: service.id,
        imagesCollectionId: cid,
      },
    });
  });

  onMetadataAdded<AppState>(register, (state, ev) => {
    const collID = getCollectionId(state);
    const coll = getCollection(state);

    if (
      collID &&
      ev.schema === COLLECTION_SCHEMA &&
      coll &&
      coll.pendingOrderRef &&
      ev.entity === coll.pendingOrderRef.imagesCollectionId
    ) {
      return createAnalyticOrder(
        state,
        collID,
        coll.pendingOrderRef.serviceId,
        coll.pendingOrderRef.imagesCollectionId
      );
    }

    return state;
  });

  onOrderReceipt<AppState>(register, (state, { refID, order }) => {
    const collID = refID.split("@")[0];
    const coll = state.collections[collID];
    if (!coll) return state;

    return update(state, ["collections", collID], {
      confirmedOrderID: order.id,
    });
  });

  onTbXlDataTableCheckboxClicked<AppState>(
    register,
    (state, { cardID, selected, row }) => {
      const collID = getCollectionId(state);
      if (!collID) return state;

      switch (cardID) {
        case "analyticsServicesTable":
          const service = selected ? row.id : null;
          return update(state, ["collections", collID], {
            selectedAnalyticsService: service,
          });
        case "collectionDetailImagesTable":
          const collection = state.collections[collID];
          const images = collection.images;
          if (images) {
            const foundIndex = images.findIndex(
              (i) => i.artifactURN === row.id
            );

            if (foundIndex >= 0) images[foundIndex].selected = selected;

            return update(state, ["collections", collID], {
              images,
              selectAllImages: !selected ? false : collection.selectAllImages,
            });
          }

          return state;
        default:
          return state;
      }
    }
  );

  onNavBtnClicked<AppState>(register, (state, { id }) => {
    if (id !== "show-map" && id !== "show-list") return state;
    const collID = getCollectionId(state);
    if (!collID) return state;

    let view;
    if (id === "show-map") {
      view = CollectionView.Map;

      const coll = getCollection(state);
      if (coll && coll.nextPage)
        dispatchGetCollectionImages(state, collID, coll.nextPage, true);
    } else {
      view = CollectionView.Table;
    }

    return update(state, ["collections", collID], {
      useView: view,
    });
  });

  onSelectAllImages<AppState>(register, (state, { collectionID, selected }) => {
    const images = state.collections[collectionID].images || [];

    const coll = getCollection(state);
    if (selected && coll && coll.nextPage)
      dispatchGetCollectionImages(state, coll.id, coll.nextPage, true);

    return update(state, ["collections", collectionID], {
      selectAllImages: selected,
      images: images.map((i) => ({
        ...i,
        selected,
      })),
    });
  });

  onPreviewArtifact<AppState>(register, (state, { id }) => {
    dispatchIvcapGetArtifactRecord({
      id,
      apiURL: state.ivcapApi,
    });

    return update(state, ["artifactModal"], {
      fetching: true,
    });
  });

  onTbXlDataTableColumnSort<AppState>(
    register,
    (state, { cardID, isAscending, col }) => {
      const orderBy = col.label;
      const orderDesc = !isAscending;
      if (cardID === "collectionsTable") {
        dispatchIvcapQueryMetadata({
          apiURL: state.ivcapApi,
          schema: "urn:ibenthos:schema:field_collection.%",
          filter: `project~='${getProjectId(state)}'`,
          orderBy,
          orderDesc,
          limit: NO_OF_COLLECTIONS_PER_PAGE,
          template: {
            type: ACTION_TYPES.LISTING,
          },
        });

        return update(state, ["collectionListing"], {
          offset: 0,
          fetching: true,
        });
      } else if (
        cardID === "collectionDetailImagesTable" ||
        cardID === "imagesSelectStepInfoTable"
      ) {
        const collID = getCollectionId(state) || "";
        return dispatchGetCollectionImages(
          state,
          collID,
          undefined,
          false,
          orderBy,
          orderDesc
        );
      } else if (cardID === "analyticsServicesTable") {
        const collID = getCollectionId(state) || "";
        dispatchIvcapQueryMetadata({
          apiURL: state.ivcapApi,
          schema: "urn:ibenthos:schema:service.1",
          limit: NO_OF_ANALYTIC_SERVICES_PER_PAGE,
          orderBy,
          orderDesc,
          template: {
            type: ACTION_TYPES.ANALYTIC_SERVICES,
          },
        });

        return update(
          state,
          ["collections", collID, "selectedAnalyticsServiceList"],
          {
            fetching: true,
            list: [],
            offset: 0,
          }
        );
      } else return state;
    }
  );

  onTbXlDataTableNextPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing }) => {
      if (cardID === "collectionsTable") {
        if (!state.collectionListing) return state;

        const collectionCount = state.collectionListing.list
          ? state.collectionListing.list.length
          : 0;

        let s = state;
        if (
          recordsShowing + offset >= collectionCount &&
          state.collectionListing.nextPage
        ) {
          dispatchIvcapQueryMetadata({
            apiURL: state.ivcapApi,
            schema: "urn:ibenthos:schema:field_collection.%",
            filter: `project~='${getProjectId(state)}'`,
            page: state.collectionListing.nextPage,
            limit: NO_OF_COLLECTIONS_PER_PAGE,
            template: {
              type: ACTION_TYPES.LISTING,
              page: state.collectionListing.nextPage,
            },
          });

          s = update(s, ["collectionListing", "fetching"], true);
        }

        return update(s, ["collectionListing"], {
          offset: recordsShowing + offset,
        });
      } else if (
        cardID === "collectionDetailImagesTable" ||
        cardID === "imagesSelectStepInfoTable"
      ) {
        const coll = getCollection(state);
        if (!coll) return state;

        const imgCount = coll.images ? coll.images.length : 0;
        const collID = getCollectionId(state) || "???"; // '???' should never happen as we already checked for collection

        let s = state;
        if (recordsShowing + offset >= imgCount && coll.nextPage) {
          dispatchFromReducer(
            createLoadMoreImagesAction(collID, coll.nextPage)
          );

          s = update(s, ["collections", collID, "fetching"], true);
        }

        return update(s, ["collections", collID], {
          offset: recordsShowing + offset,
        });
      } else if (cardID === "analyticsServicesTable") {
        const collID = getCollectionId(state) || "";
        const serviceList =
          state.collections[collID].selectedAnalyticsServiceList;

        const count =
          (serviceList && serviceList.list && serviceList.list.length) || 0;

        let s = state;
        if (recordsShowing + offset >= count && serviceList?.nextPage) {
          dispatchIvcapQueryMetadata({
            apiURL: state.ivcapApi,
            schema: "urn:ibenthos:schema:service.1",
            page: serviceList?.nextPage,
            limit: NO_OF_ANALYTIC_SERVICES_PER_PAGE,
            template: {
              type: ACTION_TYPES.ANALYTIC_SERVICES,
              page: serviceList?.nextPage,
            },
          });

          s = update(
            s,
            ["collections", collID, "selectedAnalyticsServiceList", "fetching"],
            true
          );
        }

        return update(
          s,
          ["collections", collID, "selectedAnalyticsServiceList"],
          {
            offset: recordsShowing + offset,
          }
        );
      } else return state;
    }
  );

  onTbXlDataTablePreviousPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing }) => {
      if (cardID === "collectionsTable") {
        if (!state.collectionListing) return state;

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, NO_OF_COLLECTIONS_PER_PAGE),
          0
        );

        return update(state, ["collectionListing"], {
          offset: newOffset,
        });
      } else if (
        cardID === "collectionDetailImagesTable" ||
        cardID === "imagesSelectStepInfoTable"
      ) {
        const collID = getCollectionId(state);
        if (!collID) return state;

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, DEF_LIST_LIMIT),
          0
        );

        return update(state, ["collections", collID], {
          offset: newOffset,
        });
      } else if (cardID === "analyticsServicesTable") {
        const collID = getCollectionId(state) || "";

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, NO_OF_ANALYTIC_SERVICES_PER_PAGE),
          0
        );

        return update(
          state,
          ["collections", collID, "selectedAnalyticsServiceList"],
          {
            offset: newOffset,
          }
        );
      } else return state;
    }
  );

  onLocateImageOnMap<AppState>(register, (state, { lat, lng }) => {
    const collID = getCollectionId(state);

    if (collID) {
      return update(state, ["collections", collID], {
        mapCenter: { lat, lng },
      });
    } else return state;
  });

  onSearchGetImageCountResult<AppState>(
    register,
    (state, { collectionID, count }) => {
      return update(state, ["collections", collectionID], {
        imageCount: count,
      });
    }
  );

  onSearchGetCollectionStatsResult<AppState>(
    register,
    (state, { collectionID, stats }) => {
      return update(state, ["collections", collectionID], {
        stats,
      });
    }
  );
}

function testServiceSuitability(
  s: AppState,
  serviceID: string,
  collectionID: string
): boolean {
  dispatchFromReducer({
    type: ACTION_TYPES.ANALYTIC_SERVICES_SHOWN,
    collectionID,
    serviceID,
  });
  return true;
}
