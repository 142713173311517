import { PiRegister, update } from "@pihanga/core";
import { AppState } from "../app.type";
import { onServiceRecord } from "../ivcap"; // "@pihanga/ivcap";

export function init(register: PiRegister): void {
  onServiceRecord<AppState>(register, (state, { service }) => {
    const id = service.id.split(":")[3];
    const s = update(state, ["services", id], service);
    return s;
  });

  // onTbButtonClicked<AppState>(register, (state, { name }) => {
  //   if (name === 'create-new-service') {
  //     console.log(">>>>>>>>> TEST ADD META")
  //     dispatchIvcapAddMetadata({
  //       apiURL: state.ivcapApi,
  //       entity: "urn:testing:foo",
  //       schema: "urn:testing:schema:foo.1",
  //       aspect: {
  //         when: new Date().toISOString()
  //       }
  //     })
  //   }
  //   return state
  // })
}
