import {
  PiRegister,
  dispatchFromReducer,
  update,
  RouterShowPageAction,
  ReduxState,
  ReduxAction,
} from "@pihanga/core";
import { registerPOST } from "@pihanga/rest-client";
import {
  ACTION_TYPES,
  createShowOrderListingAction,
  dispatchShowArtifactInModal,
  onOrderMetadata,
  onShowOrderDetail,
  onShowOrderListing,
} from "../app.actions";

import {
  onOrderList,
  onOrderRecord,
  dispatchIvcapGetOrderList,
  dispatchIvcapGetOrderRecord,
  dispatchIvcapQueryMetadata,
  getAccessToken,
  BaseEvent,
  onOrderReceipt,
} from "../ivcap"; // "@pihanga/ivcap";
import { AppState } from "../app.type";
import { onTbXlDataTableButtonClicked } from "@pihanga/tabler/dist/cards/tbDataTable";
import { onTbButtonClicked } from "@pihanga/tabler/dist/cards/tbButton";
import { CREATE_ROUTE_PATH } from "../app.pihanga";
import { onCloseModal, onCreateNewOrder } from "../cards/tbOrderDetail";
import { restErrorHandling } from "../ivcap"; // "@pihanga/ivcap/dist/common";
import { getProjectContextRoute } from "../app.state";

const createOrderReducer = (state: AppState): AppState => {
  dispatchFromReducer({
    type: "ROUTER:SHOW_PAGE",
    path: getProjectContextRoute(state, ["orders", CREATE_ROUTE_PATH]),
  });

  return update(state, ["prevMeaningfulRoute"], state.route);
};

type AddOrderEvent = BaseEvent & {
  collection: string;
};

type OrderAddedEvent = BaseEvent & {
  id: string;
  name: string;
};

export function init(register: PiRegister): void {
  registerPOST<ReduxState, ReduxAction & AddOrderEvent, any>(register)({
    name: "addOrder",
    origin: ({ apiURL }: AddOrderEvent) => apiURL,
    url: "/1/orders",
    trigger: ACTION_TYPES.ADD_ORDER,
    request: ({ collection }: AddOrderEvent) => ({
      body: {
        accountId: "urn:ivcap:account:00000000-0000-0000-0000-000000000000",
        // FIXME: dynamically drive this value from getting the list of services
        serviceID:
          "ivcap:service:dbf6f2fb-ac29-5acd-8d57-bf631a89557b:mmclassification-serving",

        parameters: [
          {
            images: collection,
          },
        ],
      },
      contentType: "application/json",
    }),

    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any) => {
      dispatchFromReducer({
        type: ACTION_TYPES.ORDER_ADDED,
        id: content["id"],
        name: content["name"],
      } as ReduxAction & OrderAddedEvent);
      return state;
    },
    error: restErrorHandling("ivcap-api:addOrder"),
  });

  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      if (path.length === 0) {
        return state;
      }
      const page = path[2];
      if (page === "orders") {
        const item = path[3];
        if (!item) {
          dispatchFromReducer(createShowOrderListingAction());
        }
      }
      return state;
    }
  );

  onShowOrderListing<AppState>(register, (state) => {
    const p = state.route.path || [];
    if (p[2] !== "orders") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["orders"]),
      });
    } else {
      dispatchIvcapGetOrderList({ apiURL: state.ivcapApi });
      return update(state, ["orderListing", "fetching"], true);
    }
    return state;
  });

  onShowOrderDetail<AppState>(register, (state, { orderID }) => {
    const p = state.route.path || [];
    if (p[2] !== "orders" || p[3] !== orderID) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["orders", orderID]),
      });
    } else {
      if (!state.orders[orderID]) {
        dispatchIvcapGetOrderRecord({ id: orderID, apiURL: state.ivcapApi });
        dispatchIvcapQueryMetadata({
          apiURL: state.ivcapApi,
          entityID: orderID,
          template: {
            type: ACTION_TYPES.ORDER_METADATA,
            orderID,
            records: [], //placeholder
          },
        });
      }
    }
    return state;
  });

  onOrderList<AppState>(register, (state, { orders, nextPage }) => {
    return update(state, ["orderListing"], {
      list: orders,
      fetchedAt: Date.now(),
      fetching: false,
      nextPage,
    });
  });

  onOrderRecord<AppState>(register, (state, { order }) => {
    const rec = state.orders[order.id] || {};
    return update(state, ["orders", order.id], {
      ...rec,
      record: order,
      fetchedAt: Date.now(),
    });
  });

  onOrderMetadata<AppState>(register, (state, { orderID, records }) => {
    const rec = state.orders[orderID] || {};
    return update(state, ["orders", orderID], {
      ...rec,
      metadata: records,
      fetchedAt: Date.now(),
    });
  });

  onTbXlDataTableButtonClicked<AppState>(register, (state, ev) => {
    switch (ev.cardID) {
      case "orderProductListing":
        if (ev.label !== "preview") {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "orderProductListing" from unknown label "${ev.label}"`
          );
          return state;
        }

        const id = ev.row?.id as string;
        const dataURL = ev.row?.data?.dataURL;

        if (id && dataURL) {
          return dispatchShowArtifactInModal(
            state,
            id,
            dataURL,
            ev.row?.data?.name
          );
        } else {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "orderProductListing" from unknown label "${ev.label}"`
          );
        }
        return state;

      default:
        console.warn(
          `WARN: TBDTABLE:BUTTON_CLICKED from unknown cardID "${ev.cardID}"`
        );
    }
    return state;
  });

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "create-new-order") {
      return createOrderReducer(state);
    } else {
      return state;
    }
  });

  onCloseModal<AppState>(register, (state) => {
    if (state.prevMeaningfulRoute) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.prevMeaningfulRoute.path,
      });

      return update(state, ["prevMeaningfulRoute"], undefined);
    } else {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["orders"],
      });
    }

    return state;
  });

  onCreateNewOrder<AppState>(register, (state, { collection }) => {
    dispatchFromReducer({
      apiURL: state.ivcapApi,
      type: ACTION_TYPES.ADD_ORDER,
      collection,
    });

    return state;
  });

  onOrderReceipt<AppState>(register, (state, { order }) => {
    return update(state, ["orders", order.id], {
      record: order,
      fetchedAt: Date.now(),
    });
  });
}
