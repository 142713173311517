import {
  createOnAction,
  dispatchFromReducer,
  PiRegister,
  RouterShowPageAction,
  update,
} from "@pihanga/core";

import {
  dispatchIvcapAddMetadata,
  dispatchIvcapQueryMetadata,
  dispatchIvcapRevokeMetadata,
  dispatchIvcapUpdateMetadata,
  MetadataQueryResultItem,
  onMetadataAdded,
  onMetadataRevoked,
  onMetadataUpdated,
} from "../ivcap"; // "@pihanga/ivcap";
import { AppState, ProjectSummary } from "../app.type";
import {
  ACTION_TYPES,
  createShowProjectListingAction,
  onDeleteProject,
  onShowProjectIndividual,
  onShowProjectListing,
  onSelectProject,
  onCreateNewProjectEvent,
} from "./project.action";

import {
  onTbXlDataTableRowSelect,
  onTbXlDataTableColumnSort,
  onTbXlDataTableNextPage,
  onTbXlDataTablePreviousPage,
} from "../cards/tbDataTable";
import { onTbButtonClicked } from "@pihanga/tabler/dist/cards/tbButton";
import {
  onCloseModal,
  onCreateNewProject,
  onSaveProject,
} from "../cards/tbProjectDetail";
import shortUUID from "short-uuid";
import { CONFIRM_DELETE_ROUTE_PATH, CREATE_ROUTE_PATH } from "../app.pihanga";

const createProjectReducer = (state: AppState): AppState => {
  dispatchFromReducer({
    type: "ROUTER:SHOW_PAGE",
    path: ["projects", CREATE_ROUTE_PATH],
  });

  return update(state, ["prevMeaningfulRoute"], state.route);
};

const PROJECT_SCHEMA = "urn:ibenthos:schema:project";
export const NO_OF_PROJECTS_PER_PAGE = 10;

export function init(register: PiRegister): void {
  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      if (path.length === 0) {
        return state;
      }
      const page = path[0];
      if (page === "projects") {
        const item = path[1];
        if (
          (!item || state.projectListing.list.length === 0) &&
          !state.projectListing.fetching
        ) {
          dispatchFromReducer(createShowProjectListingAction(true));
        }
      }
      return state;
    }
  );

  onSelectProject<AppState>(register, (state, { id }) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: [
        "projects",
        id,
        state.route.path && state.route.path.length >= 3
          ? state.route.path[2]
          : "collections",
      ],
    });
    return state;
  });

  onShowProjectListing<AppState>(register, (state, { updateDataOnly }) => {
    if (!updateDataOnly) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["projects"],
      });
    }

    dispatchIvcapQueryMetadata({
      apiURL: state.ivcapApi,
      schema: "urn:ibenthos:schema:project",
      limit: NO_OF_PROJECTS_PER_PAGE,
      template: {
        type: ACTION_TYPES.LISTING,
      },
    });

    return update(state, ["projectListing"], {
      offset: 0,
      fetching: true,
    });
  });

  // Handle project listing returned from IVCAP
  createOnAction<{
    page?: string;
    nextPage?: string;
    records: MetadataQueryResultItem[];
  }>(ACTION_TYPES.LISTING)<AppState>(
    register,
    (state, { page, nextPage, records }) => {
      const projects = { ...state.projects };
      const f = function (
        r: MetadataQueryResultItem
      ): ProjectSummary | undefined {
        const id = r.entity;
        const recordId = r.recordID;

        // Temporary fix: Backend should fix it soon, `aspect` should always be in JSON structure.
        const aspect =
          typeof r.aspect == "string" ? JSON.parse(r.aspect) : r.aspect;

        if (!aspect) return undefined;
        const { name, country, description, createdTime, modifiedTime } =
          aspect;

        const summary: ProjectSummary = {
          id,
          recordId,
          name,
          country,
          description,
          createdTime,
          modifiedTime,
        };

        projects[id] = {
          id,
          recordId,
          name,
          country,
          description,
          createdTime,
          modifiedTime,
        };

        return summary;
      };

      const list = records
        .map(f)
        .filter((i) => i !== undefined) as ProjectSummary[];

      const s = update(state, ["projectListing"], {
        list:
          page &&
          state.projectListing?.list &&
          state.projectListing?.list.length
            ? state.projectListing?.list.concat(list)
            : list,
        nextPage,
        fetchedAt: Date.now(),
        fetching: false,
      });

      return update(s, ["projects"], projects);
    }
  );

  onTbXlDataTableRowSelect<AppState>(register, (state, { cardID, row }) => {
    if (cardID === "projectsTable") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["projects", row.id],
      });
    }
    return state;
  });

  onTbXlDataTableColumnSort<AppState>(
    register,
    (state, { cardID, isAscending, col }) => {
      if (cardID === "projectsTable") {
        dispatchIvcapQueryMetadata({
          apiURL: state.ivcapApi,
          schema: "urn:ibenthos:schema:project",
          orderBy: col.label,
          orderDesc: !isAscending,
          limit: NO_OF_PROJECTS_PER_PAGE,
          template: {
            type: ACTION_TYPES.LISTING,
          },
        });

        return update(state, ["projectListing"], {
          offset: 0,
          fetching: true,
        });
      }
      return state;
    }
  );

  onCreateNewProjectEvent<AppState>(register, createProjectReducer);

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "create-new-project") {
      return createProjectReducer(state);
    } else {
      return state;
    }
  });

  onCloseModal<AppState>(register, (state) => {
    if (state.prevMeaningfulRoute) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.prevMeaningfulRoute.path,
      });

      return update(state, ["prevMeaningfulRoute"], undefined);
    } else {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["projects"],
      });
    }

    return state;
  });

  onCreateNewProject<AppState>(
    register,
    (state, { name, country, description }) => {
      dispatchIvcapAddMetadata({
        apiURL: state.ivcapApi,
        entity: `urn:ibenthos:project:${shortUUID.generate()}`,
        schema: PROJECT_SCHEMA,
        aspect: {
          name,
          country,
          description,
          createdTime: new Date().toISOString(),
        },
      });

      return state;
    }
  );

  onSaveProject<AppState>(register, (state, { id, projectDetail }) => {
    dispatchIvcapUpdateMetadata({
      apiURL: state.ivcapApi,
      entity: id,
      schema: PROJECT_SCHEMA,
      aspect: {
        ...projectDetail,
        modifiedTime: new Date().toISOString(),
      },
    });

    return state;
  });

  onDeleteProject<AppState>(register, (state, { name, recordId }) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: state.route.path?.concat([CONFIRM_DELETE_ROUTE_PATH]),
    });

    return update(state, ["confirmDelete"], {
      title: `Delete project: ${name}`,
      performDelete: () => {
        dispatchIvcapRevokeMetadata({
          apiURL: state.ivcapApi,
          recordID: recordId,
        });
      },
    });
  });

  onShowProjectIndividual<AppState>(register, (state, { id }) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: ["projects", id, "edit"],
    });

    return update(state, ["prevMeaningfulRoute"], state.route);
  });

  onMetadataAdded<AppState>(register, (state, ev) => {
    if (ev.schema === PROJECT_SCHEMA) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["projects"],
      });
    }

    return state;
  });

  onMetadataUpdated<AppState>(register, (state, ev) => {
    if (ev.schema === PROJECT_SCHEMA) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["projects"],
      });
    }

    return state;
  });

  onMetadataRevoked<AppState>(register, (state, ev) => {
    // FIXME: "schema" info is not available. Going to "projects" is most likely incorrect in other cases
    // if (ev.schema === PROJECT_SCHEMA) {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: ["projects"],
    });
    // }

    return state;
  });

  onTbXlDataTableNextPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing }) => {
      if (cardID === "projectsTable") {
        if (!state.projectListing) return state;

        const projectCount = state.projectListing.list
          ? state.projectListing.list.length
          : 0;

        let s = state;
        if (
          recordsShowing + offset >= projectCount &&
          state.projectListing.nextPage
        ) {
          dispatchIvcapQueryMetadata({
            apiURL: state.ivcapApi,
            schema: "urn:ibenthos:schema:project",
            page: state.projectListing.nextPage,
            limit: NO_OF_PROJECTS_PER_PAGE,
            template: {
              type: ACTION_TYPES.LISTING,
              page: state.projectListing.nextPage,
            },
          });

          s = update(s, ["projectListing", "fetching"], true);
        }

        return update(s, ["projectListing"], {
          offset: recordsShowing + offset,
        });
      } else return state;
    }
  );

  onTbXlDataTablePreviousPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing }) => {
      if (cardID === "projectsTable") {
        if (!state.projectListing) return state;

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, NO_OF_PROJECTS_PER_PAGE),
          0
        );

        return update(state, ["projectListing"], {
          offset: newOffset,
        });
      }

      return state;
    }
  );
}
