import React, { FC } from "react";
import { FieldProps } from "formik";

type TbSelectComponentProps = {
  label: string;
  options: [{ value: string; label: string }];
  placeholder?: string;
  noOptionsLabel?: string;
};

export const TbSelectComponent: FC<TbSelectComponentProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form,
  placeholder = "Choose an option",
  noOptionsLabel = "No options available",
  ...props
}) => {
  const touched = form.touched[field.name];
  const error = form.errors[field.name];

  return (
    <div className="mb-3">
      <label className="form-label">{props.label}</label>
      <select
        {...field}
        {...props}
        className={`form-select ${
          touched && error ? "is-invalid" : touched && !error && "is-valid"
        }`}
      >
        {props.options.map(({ value, label }) => {
          return (
            <option value={value} selected={value === field.value}>
              {label}
            </option>
          );
        })}

        <option value="" disabled selected hidden>
          {props.options && props.options.length > 0
            ? placeholder
            : noOptionsLabel}
        </option>
      </select>

      {touched && error && (
        <div className="invalid-feedback">{error as any}</div>
      )}
    </div>
  );
};
