import React, { MutableRefObject } from "react";
import { PiCardSimpleProps } from "@pihanga/core";
import { ArtifactDataEvent } from "../../ivcap";
import { IconDownload } from "@tabler/icons-react";
import { IconEyeOff, IconEye } from "@tabler/icons-react";
import { downloadBlob } from "../../download-blob";

export type ComponentProps = {
  image?: ArtifactDataEvent;
  segmentationMap?: ArtifactDataEvent;
  colours: (string | undefined)[];
};

function draw(
  img: HTMLImageElement,
  canvas: HTMLCanvasElement,
  clearCanvas?: boolean
): void {
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  if (clearCanvas) ctx.clearRect(0, 0, canvas.width, canvas.height);

  const hRatio = canvas.width / img.width;
  const vRatio = canvas.height / img.height;
  const ratio = Math.min(hRatio, vRatio);

  const xOffset = (canvas.width - img.width * ratio) / 2;
  const yOffset = (canvas.height - img.height * ratio) / 2;

  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    xOffset,
    yOffset,
    img.width * ratio,
    img.height * ratio
  );
}

const hexToRgb = (h: string): { red: number; green: number; blue: number } => {
  // Remove the hash character (#) if present
  const hex = h.replace(/^#/, "");

  // Parse the hex value into individual RGB components
  const bigint = parseInt(hex, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;

  // Return the RGB values as an object
  return { red, green, blue };
};

export const Component = ({
  image,
  segmentationMap,
  colours,
}: PiCardSimpleProps<ComponentProps>): React.ReactNode => {
  const [hideClasses, setHideClasses] = React.useState<boolean>(false);
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const hiddenCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(
    () => {
      const canvas = canvasRef.current;

      if (canvas && image && image.imgURL) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = image.imgURL;
        img.onload = (): void => {
          // Draw the source image
          draw(img, canvas, true);

          if (hideClasses) return;

          const hiddenCanvas = hiddenCanvasRef.current;
          if (hiddenCanvas && segmentationMap && segmentationMap.imgURL) {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = segmentationMap.imgURL;
            img.onload = (): void => {
              draw(img, hiddenCanvas, true);
              const hiddenCanvasCtx = hiddenCanvas.getContext("2d");

              const imageData =
                hiddenCanvasCtx &&
                hiddenCanvasCtx.getImageData(0, 0, img.width, img.height);

              if (imageData) {
                let i = 0;
                while (i < imageData.data.length) {
                  const classIndex = imageData.data[i]; // using red value
                  const rgb = hexToRgb(colours[classIndex] || "");

                  imageData.data[i] = rgb.red;
                  imageData.data[++i] = rgb.green;
                  imageData.data[++i] = rgb.blue;
                  imageData.data[++i] = classIndex === 0 ? 0 : 255;
                  i++;
                }

                hiddenCanvasCtx.putImageData(imageData, 0, 0);

                const ctx = canvas.getContext("2d");
                if (ctx) {
                  ctx.globalAlpha = 0.5;
                  ctx.drawImage(hiddenCanvas, 0, 0);
                  ctx.globalAlpha = 1;
                }
              }
            };
          }
        };
      }
    },

    /* eslint-disable react-hooks/exhaustive-deps */
    [
      image && image.imgURL,
      segmentationMap && segmentationMap.imgURL,
      hideClasses,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  const renderImage = (
    ref: MutableRefObject<HTMLCanvasElement | null>,
    isHidden?: boolean
  ): React.ReactNode => {
    return (
      <canvas
        ref={ref}
        width="600"
        height="450"
        style={isHidden ? { display: "none" } : {}}
      />
    );
  };

  const isLoading =
    (image && image.fetching) || (segmentationMap && segmentationMap.fetching);

  if (isLoading) return <div className="spinner-border"></div>;
  else {
    return (
      <div
        className="table-responsive"
        style={{
          display: "grid",
          placeItems: "center",
          gridTemplate: "auto auto / auto auto auto auto",
        }}
      >
        <div
          style={{
            gridColumn: "1/5",
          }}
        >
          {renderImage(canvasRef)}
          {renderImage(hiddenCanvasRef, true)}
        </div>

        {image && image.imgURL && (
          <button
            style={{
              gridColumn: "2/3",
            }}
            className={`btn ${hideClasses && "btn-primary"}`}
            onClick={(): void => setHideClasses(!hideClasses)}
          >
            {hideClasses ? <IconEye /> : <IconEyeOff />}
            {hideClasses ? "Show" : "Hide"} results
          </button>
        )}

        <button
          type="button"
          className="btn"
          style={{
            gridColumn: "3/4",
          }}
          onClick={(): void => {
            const canvas = canvasRef.current;

            if (canvas) {
              canvas.toBlob((blob) => {
                blob && downloadBlob(blob, image && image.artifactID);
              });
            }
          }}
        >
          <IconDownload />
          Download image
        </button>
      </div>
    );
  }
};
