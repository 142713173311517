import {
  PiRegister,
  actionTypesToEvents,
  createOnAction,
  registerActions,
  createCardDeclaration,
} from "@pihanga/core";
import { Component } from "./tbConfirmDelete.component";
import type { ComponentProps } from "./tbConfirmDelete.component";

const TB_CONFIRM_DELETE = "TbConfirmDelete";

const ACTION_TYPES = registerActions("TB_CONFIRM_DELETE", [
  "CONFIRM_DELETE",
  "CLOSE_MODAL",
]);

export const onConfirmDelete = createOnAction(ACTION_TYPES.CONFIRM_DELETE);
export const onCloseModal = createOnAction(ACTION_TYPES.CLOSE_MODAL);

export const TbConfirmDelete =
  createCardDeclaration<ComponentProps>(TB_CONFIRM_DELETE);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_CONFIRM_DELETE,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
