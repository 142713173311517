import {
  setAccessToken,
  getAccessToken,
  clearAccessToken,
  //} from "@pihanga/ivcap";
} from "./ivcap";

const STORAGE_KEY_PREFIX = "ibenthosUI_";
const ACCESS_TOKEN_STORAGE_KEY = `${STORAGE_KEY_PREFIX}AccessKey`;
const NOT_FIRST_TIME_USER_STORAGE_KEY = `${STORAGE_KEY_PREFIX}IsNotFirstTimeUser`;

const isWebStorageSupported = typeof Storage !== "undefined";
export const saveAndSetAccessToken = (token: string): void => {
  if (isWebStorageSupported) {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  }

  setAccessToken(token);
};

export const getSavedAccessToken = (): string | undefined => {
  if (isWebStorageSupported) {
    const tmp = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) as string;
    if (tmp && getAccessToken() !== tmp) {
      setAccessToken(tmp);
    }
    return tmp;
  }

  return getAccessToken();
};

export const removeAccessToken = (): void => {
  if (isWebStorageSupported) {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  clearAccessToken();
};

export const isFirstTimeUser = (): boolean => {
  if (isWebStorageSupported) {
    const isNotFirstTimeUser = localStorage.getItem(
      NOT_FIRST_TIME_USER_STORAGE_KEY
    );

    return !isNotFirstTimeUser;
  } else {
    return false;
  }
};

export const setIsNotFirstTimeUser = (): void => {
  if (isWebStorageSupported) {
    localStorage.setItem(NOT_FIRST_TIME_USER_STORAGE_KEY, "true");
  }
};
