import { PiCardDef, PiMapProps, PiRegister } from "@pihanga/core";
import { Component } from "./tbParticipantInfo.component";
import type { ComponentProps } from "./tbParticipantInfo.component";

export const TB_PARTICIPANT_INFO = "TbParticipantInfo";

type CardProps<S> = PiMapProps<ComponentProps, S>;
export function TbParticipantInfo<S>(p: CardProps<S>): PiCardDef {
  return {
    ...p,
    cardType: TB_PARTICIPANT_INFO,
  };
}

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_PARTICIPANT_INFO,
    component: Component,
  });
}
