import {
  ReduxAction,
  createOnAction,
  registerActions,
  dispatchFromReducer,
  update,
} from "@pihanga/core";
import {
  ArtifactDataEvent,
  dispatchIvcapGetArtifactData,
  dispatchIvcapQueryMetadata,
  MetadataQueryResultItem,
  OrderProduct,
} from "./ivcap";
import { AppState, MetadataSchema } from "./app.type"; // "@pihanga/ivcap";

export const ACTION_TYPES = registerActions("APP", [
  // orders
  "ADD_ORDER",
  "ORDER_ADDED",

  "SHOW_ANALYTIC_LISTING",
  "SHOW_ANALYTIC_DETAIL",
  "SHOW_ANALYTIC_COVERAGE_IN_MODAL",

  "SHOW_ORDER_LISTING",
  "SHOW_ORDER_DETAIL",
  "ORDER_METADATA",

  // artifacts
  "SHOW_ARTIFACT_LISTING",
  "SHOW_ARTIFACT_DETAIL",
  "UPDATE_ARTIFACT_DATA",
  "SHOW_ARTIFACT_IN_MODAL",
  "ARTIFACT_METADATA",

  "UPDATE_ARTIFACT_METADATA_LIST",

  "ERROR",
]);

// SHOW_ANALYTIC_LISTING

export type ShowAnalyticListingEvent = {};

export const onShowAnalyticListing = createOnAction<ShowAnalyticListingEvent>(
  ACTION_TYPES.SHOW_ANALYTIC_LISTING
);
export const createShowAnalyticListingAction = (): ReduxAction &
  ShowAnalyticListingEvent => ({
  type: ACTION_TYPES.SHOW_ANALYTIC_LISTING,
});

// SHOW_ANALYTIC_DETAIL

export type ShowAnalyticDetailEvent = {
  orderID: string;
};

export const onShowAnalyticDetail = createOnAction<ShowAnalyticDetailEvent>(
  ACTION_TYPES.SHOW_ANALYTIC_DETAIL
);
export const createShowAnalyticDetailAction = (
  orderID: string
): ReduxAction & ShowAnalyticDetailEvent => ({
  type: ACTION_TYPES.SHOW_ANALYTIC_DETAIL,
  orderID,
});

// SHOW_ANALYTIC_COVERAGE_IN_MODAL

export type ShowAnalyticCoverageInModalEvent = {
  title: string;
  product: OrderProduct;
};

export const createShowAnalyticCoverageInModalAction = (
  title: string,
  product: OrderProduct
): ReduxAction & ShowAnalyticCoverageInModalEvent => ({
  type: ACTION_TYPES.SHOW_ANALYTIC_COVERAGE_IN_MODAL,
  title,
  product,
});

// SHOW_ORDER_LISTING

export type ShowOrderListingEvent = {};

export const onShowOrderListing = createOnAction<ShowOrderListingEvent>(
  ACTION_TYPES.SHOW_ORDER_LISTING
);
export const createShowOrderListingAction = (): ReduxAction &
  ShowOrderListingEvent => ({
  type: ACTION_TYPES.SHOW_ORDER_LISTING,
});

// SHOW_ORDER_DETAIL

export type ShowOrderDetailEvent = {
  orderID: string;
};

export const onShowOrderDetail = createOnAction<ShowOrderDetailEvent>(
  ACTION_TYPES.SHOW_ORDER_DETAIL
);
export const createShowOrderDetailAction = (
  orderID: string
): ReduxAction & ShowOrderDetailEvent => ({
  type: ACTION_TYPES.SHOW_ORDER_DETAIL,
  orderID,
});

// ORDER_METADATA

export type OrderMetadataEvent = {
  orderID: string;
  records: MetadataQueryResultItem[];
};

export const onOrderMetadata = createOnAction<OrderMetadataEvent>(
  ACTION_TYPES.ORDER_METADATA
);
export const createOrderMetadataAction = (
  orderID: string,
  records: MetadataQueryResultItem[]
): ReduxAction & OrderMetadataEvent => ({
  type: ACTION_TYPES.ORDER_METADATA,
  orderID,
  records,
});

// SHOW_ARTIFACT_LISTING

export type ShowArtifactListingEvent = {};

export const onShowArtifactListing = createOnAction<ShowArtifactListingEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_LISTING
);
export const createShowArtifactListingAction = (): ReduxAction &
  ShowArtifactListingEvent => ({
  type: ACTION_TYPES.SHOW_ARTIFACT_LISTING,
});

// SHOW_ARTIFACT_DETAIL

export type ShowArtifactDetailEvent = {
  artifactID: string;
};

export const onShowArtifactDetail = createOnAction<ShowArtifactDetailEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_DETAIL
);
export const createShowArtifactDetailAction = (
  artifactID: string
): ReduxAction & ShowArtifactDetailEvent => ({
  type: ACTION_TYPES.SHOW_ARTIFACT_DETAIL,
  artifactID,
});

// SHOW_ARTIFACT_IN_MODAL

export type ShowArtifactInModalEvent = ArtifactDataEvent & {
  title: string;
};

export const onShowArtifactInModal = createOnAction<ShowArtifactInModalEvent>(
  ACTION_TYPES.SHOW_ARTIFACT_IN_MODAL
);

export const dispatchUpdateArtifactData = (
  state: AppState,
  id: string,
  dataURL: string,
  title: string
): AppState => {
  const template = {
    type: ACTION_TYPES.UPDATE_ARTIFACT_DATA,
    title,
  };

  return dispatchIvcapGetArtifactData(state, {
    id,
    dataURL,
    template,
    apiURL: state.ivcapApi,
  });
};

export const dispatchUpdateArtifactMetadataList = (
  state: AppState,
  id: string,
  schema: string = MetadataSchema.DEFAULT
): AppState => {
  const template = {
    type: ACTION_TYPES.UPDATE_ARTIFACT_METADATA_LIST,
    schema,
  };

  if (
    state.artifactMetadataList[id] &&
    state.artifactMetadataList[id][schema]
  ) {
    dispatchFromReducer({
      ...template,
      ...state.artifactMetadataList[id][schema],
    });

    return state;
  } else {
    dispatchIvcapQueryMetadata({
      apiURL: state.ivcapApi,
      entityID: id,
      schema,
      template: {
        ...template,
        id,
      },
    });

    return update(state, ["artifactMetadataList", id, schema], {
      fetching: true,
    });
  }
};

export const dispatchShowArtifactInModal = (
  state: AppState,
  id: string,
  dataURL: string,
  title: string
): AppState => {
  const template = {
    type: ACTION_TYPES.SHOW_ARTIFACT_IN_MODAL,
    title: title,
  };

  if (state.artifacts[id]) {
    dispatchFromReducer({
      ...state.artifacts[id],
      ...template,
    });

    return state;
  } else {
    return dispatchIvcapGetArtifactData(state, {
      id,
      dataURL,
      template,
      apiURL: state.ivcapApi,
    });
  }
};

// ARTIFACT_METADATA

export type ArtifactMetadataEvent = {
  // type: ACTION_TYPES.ARTIFACT_METADATA,
  artifactID: string;
  records: MetadataQueryResultItem[];
};

export const onArtifactMetadata = createOnAction<ArtifactMetadataEvent>(
  ACTION_TYPES.ARTIFACT_METADATA
);
export const createArtifactMetadataAction = (
  artifactID: string,
  records: MetadataQueryResultItem[]
): ReduxAction & ArtifactMetadataEvent => ({
  type: ACTION_TYPES.ARTIFACT_METADATA,
  artifactID,
  records,
});
