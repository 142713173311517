import { PiCardSimpleProps } from "@pihanga/core";
import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TbSelectComponent } from "../tbSelect.component";
import { ModalComponent } from "../modal.component";
import { CollectionSummary } from "../../app.type";

export type CreateNewOrderEvent = {
  collection: string;
};

export type ComponentProps = {
  collections: CollectionSummary[];
};

type ComponentT = ComponentProps & {
  onCreateNewOrder: (ev: CreateNewOrderEvent) => void;
  onCloseModal: () => void;
};

const ORDER_SCHEMA = Yup.object({
  collection: Yup.string().required(),
});

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const { collections, onCreateNewOrder, onCloseModal } = props;

  return (
    <Formik
      onSubmit={(values, resetForm): void => {
        onCreateNewOrder({
          collection: values.collection,
        });
      }}
      validationSchema={ORDER_SCHEMA}
      initialValues={{} as CreateNewOrderEvent}
      enableReinitialize
    >
      {({ dirty, handleSubmit }): React.ReactNode => (
        <ModalComponent
          title={"Create job"}
          onClose={onCloseModal}
          confirmTitle={"Create"}
          onConfirm={handleSubmit}
          disableConfirm={!dirty}
        >
          <div className="card-body">
            <Form>
              <Field
                name="collection"
                component={TbSelectComponent}
                options={collections.map((s) => ({
                  value: s.id,
                  label: s.name,
                }))}
                placeholder="Choose a collection"
                label="Collection"
                noOptionsLabel="No collections available"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};
