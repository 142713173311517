import {
  PiRegister,
  dispatchFromReducer,
  ReduxState,
  ReduxAction,
  createOnAction,
  ReduxActionExt,
} from "@pihanga/core";
import { registerGET } from "@pihanga/rest-client";
import { getAccessToken } from ".";
import {
  ACTION_TYPES,
  BaseEvent,
  createListAction,
  createReadAction,
  ListEvent,
  LoadListEvent,
} from "./actions";
import {
  restErrorHandling,
  createListUrlBuilder,
  getNextPage,
  dispatchEvent,
} from "./common";
import { URN } from ".";

type ServiceListEvent = ListEvent & {
  services: ServiceListItem[];
};

export type ServiceListItem = {
  id: string;
  name: string;
};

export type LoadServiceListEvent<T = {}> = LoadListEvent<T>;

export function dispatchIvcapGetServiceList<T = {}>(
  ev: LoadServiceListEvent<T>
): void {
  const a = createListAction(ACTION_TYPES.LOAD_SERVICE_LIST, ev);
  dispatchFromReducer(a);
}

export const onServiceList = createOnAction<ServiceListEvent>(
  ACTION_TYPES.SERVICE_LIST
);

// RECORD

type ServiceRecordEvent = {
  service: ServiceRecord;
};

export type ServiceRecord = {
  id: string;
  name: string;
  description: string;
  parameters: ServiceParameter[];
  accountID: URN;
};

export enum ParameterType {
  String = "string",
  Int = "int",
  Float = "float",
  Bool = "bool",
  Option = "option",
  Artifact = "artifact",
  Unknown = "unknown",
}

// ivcap-core:common/design/t_common.go
//
// var ParameterDefT = Type("ParameterDefT", func() {
//   Field(1, "name", String)
//   Field(2, "label", String)
//   Field(3, "type", String)
//   Field(4, "description", String)
//   Field(6, "unit", String)
//   Field(7, "constant", Boolean)
//   Field(9, "optional", Boolean)
//   Field(10, "default", String)
//   Field(11, "options", ArrayOf(ParameterOptT))
// })
export type ServiceParameter = {
  name: string;
  label?: string;
  type: ParameterType;
  description: string;
  unit?: string;
  isConstant: boolean;
  isOptional: boolean;
  defaultValue?: any;
  options?: ServiceParameterOption[];
};

// ivcap-core:common/design/t_common.go
//
// var ParameterOptT = Type("ParameterOptT", func() {
//   Field(1, "value", String)
//   Field(2, "description", String)
// })

export type ServiceParameterOption = {
  value: any;
  description?: string;
};

export type ServiceRecordActionTemplate = ReduxActionExt & {
  service: ServiceRecord;
};

export type LoadServiceRecordEvent<T = {}> = BaseEvent<T> & {
  id: string;
  template?: ServiceRecordActionTemplate;
};

export function dispatchIvcapGetServiceRecord<T = {}>(
  ev: LoadServiceRecordEvent<T>
): void {
  const a = createReadAction(ACTION_TYPES.LOAD_SERVICE_RECORD, ev);
  dispatchFromReducer(a);
}

export const onServiceRecord = createOnAction<ServiceRecordEvent>(
  ACTION_TYPES.SERVICE_DETAIL
);

//====== API HANDLER

export function init(register: PiRegister): void {
  registerGET<ReduxState, ReduxAction & LoadServiceListEvent, any>(register)({
    name: "loadServiceList",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("services"),
    trigger: ACTION_TYPES.LOAD_SERVICE_LIST,
    request: (a, _) => a as any,
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, req) => {
      const ev: ServiceListEvent = {
        nextPage: getNextPage(content.links),
        services: (content.services || []).map(toServiceListItem),
      };
      dispatchEvent(ev, ACTION_TYPES.SERVICE_LIST, req.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:loadServiceList"),
  });

  registerGET<ReduxState, ReduxAction & LoadServiceRecordEvent, any>(register)({
    name: "getServiceDetail",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/services/:id",
    trigger: ACTION_TYPES.LOAD_SERVICE_RECORD,
    request: ({ id }) => ({ id }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, req) => {
      const service = toServiceRecord(content);
      if (req.template) {
        dispatchFromReducer({ ...req.template, service });
      } else {
        const ev: ServiceRecordEvent = {
          service,
        };
        dispatchEvent(ev, ACTION_TYPES.SERVICE_DETAIL, req.template);
      }
      return state;
    },
    error: restErrorHandling("ivcap-api:getServiceDetail"),
  });
}

function toServiceListItem(els: any): ServiceListItem {
  // {
  //   "id": "ivcap:service:266cf1ad-0949-5c40-b6a7-a0ebcdb5b8b5",
  //   "name": "simple-python-service",
  //   "provider": {
  //       "id": "ivcap:provider:fe7745a4-a325-5cf2-9011-679ee487a44d"
  //   },
  //   "links": {
  //       "self": "http://localhost:8080/1/services/266cf1ad-0949-5c40-b6a7-a0ebcdb5b8b5"
  //   }
  // }
  return {
    id: els.id,
    name: els.name,
  };
}

function toServiceRecord(el: any): ServiceRecord {
  // {
  //   id: 'urn:ivcap:service:8773f79e-d46c-559a-a63c-54c4e2a9d9a1',
  //   name: 'infer-with-paddle-paddle',
  //   description: 'A service which applies a PaddlePaddle model to a set of images',
  //   parameters: [

  //   ],
  //   provider: {
  //     id: 'urn:ivcap:provider:4c65b865-df6a-4977-982a-f96b19c1fda0'
  //   },
  //   account: {
  //     id: 'urn:ivcap:account:4c65b865-df6a-4977-982a-f96b19c1fda0'
  //   },
  //   links: {
  //     self: 'https://develop.ivcap.net/1/services/8773f79e-d46c-559a-a63c-54c4e2a9d9a1'
  //   }
  // },

  return {
    id: el.id,
    name: el.name,
    description: el.description,
    parameters: toParameterList(el.parameters),
    accountID: el.account,
  };
}

function toParameterList(parameters: any[] = []): ServiceParameter[] {
  //   {
  //     name: 'device',
  //     label: '',
  //     type: 'option',
  //     description: 'Select which device to inference, defaults to gpu.',
  //     unit: '',
  //     constant: false
  //     optional: false,
  //     'default': 'cpu',
  //     options: [
  //       {
  //         value: 'cpu',
  //         description: ''
  //       },
  //       {
  //         value: 'gpu',
  //         description: ''
  //       }
  //     ]
  //   },
  // export type ServiceParameter = {
  //   name: string;
  //   label?: string;
  //   type: ParameterType;
  //   description: string;
  //   unit?: string;
  //   isConstant: boolean;
  //   isOptional: boolean;
  //   defaultValue?: any;
  //   options?: ServiceParameterOption[];
  // }

  return parameters.map(
    (el) =>
      ({
        name: el.name,
        label: el.label,
        type: toParameterType(el.type),
        description: el.name,
        unit: el.name,
        isConstant: !!el.constant,
        isOptional: !!el.optional,
        defaultValue: el["default"],
        options: toOptions(el.options),
      } as ServiceParameter)
  );
}

function toParameterType(el: string | undefined): ParameterType {
  var pt;
  if (el) {
    pt = enumFromStringValue(ParameterType, el);
    if (!pt) pt = ParameterType.Unknown;
  } else {
    pt = ParameterType.String;
  }
  return pt;
}

function toOptions(els: any[] = []): ServiceParameterOption[] {
  return els.map(
    (el) =>
      ({
        value: el.value,
        description: el.description,
      } as ServiceParameterOption)
  );
}

// https://stackoverflow.com/a/41548441/3528225
function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}
