import { PiCardSimpleProps } from "@pihanga/core";
import React from "react";
import { ModalComponent } from "../modal.component";

export type ComponentProps = {
  onConfirm: () => void;
};

export const Component = ({
  onConfirm,
}: PiCardSimpleProps<ComponentProps>): React.ReactNode => {
  return (
    <ModalComponent
      title={"Research Participant Information Sheet"}
      confirmTitle="Confirm"
      onConfirm={onConfirm}
    >
      <div className="card-body">{content}</div>
    </ModalComponent>
  );
};

const content = (
  <div>
    <h3>
      IBenthos Platform for data analysis in the context of blue carbon storage
      and sequestration in seagrass ecosystems{" "}
    </h3>

    <h2>Project overview</h2>
    <p>
      You are invited to take part in iBenthos platform (also refers to website
      in this context) for analyzing data collected in seagrass ecosystems using
      machine learning (ML) models. The website is being created by a team of
      CSIRO researchers and engineers and is a jointly effort between the
      Australian Government’s Department of Foreign Affairs and Trade (DFAT),
      Google and CSIRO. The purpose of this platform is to support international
      marine scientists in the seagrass mapping space.{" "}
    </p>

    <h2>What does participation involve?</h2>
    <p>
      Participation in the iBenthos website will be researchers and marine
      scientists who will:
    </p>
    <ol type="1">
      <li>Create and manage their user profile, </li>
      <li>
        Upload and annotate their datasets, which are images and spatial data
        from marine surveys for seagrasses,{" "}
      </li>
      <li>Manage their data, </li>
      <li>
        Analyse their data in terms of seagrass coverage and composition using
        machine learning models, and spatial distribution/coverage,
      </li>
      <li>
        Refine shared machine learning models with their uploaded datasets and
        their annotations.
      </li>
    </ol>
    <p>There will not be any interviews involved in iBenthos platform.</p>

    <h2>Risks and benefits</h2>
    <p>Our website's implementation offers significant benefits including:</p>
    <ol type="1">
      <li>
        Your contribute to scientific research on seagrass ecosystems by
        analysing the seagrass coverage ratios. This can help researchers
        understand the health, dynamics and growth of seagrass beds,
        contributing to the broader scientific understanding of marine
        ecosystem,
      </li>
      <li>
        By engaging you and other users in the monitoring and analysis of
        seagrass coverage, the website promotes awareness and conservation
        efforts. The information gathered through user-contributed data and
        analysis can be used to inform conservation initiatives, promote
        sustainable practices, and aid in the protection and restoration of
        seagrass habitats.
      </li>
      <li>
        The website serves as an educational tool, allowing you and other users
        to learn about seagrass ecosystems, their importance, and the challenges
        they face. By actively involving users in data collection and analysis,
        the website fosters a deeper understanding of marine conservation and
        promotes environmental stewardship.
      </li>
    </ol>
    <p>However, it also carries potential risks including:</p>
    <ol type="1">
      <li>
        As users upload images and create profiles, there is a risk of
        unauthorized access or data breaches. We will implement robust security
        measures, including encryption, secure storage, and access controls, to
        protect user data. Additionally, we will obtain informed consent from
        users regarding data usage and ensuring compliance with applicable data
        protection regulations.
      </li>
      <li>
        The accuracy and reliability of machine learning models depend on
        various factors, including the quality of training data and the model's
        design. Users should be aware that the analysis results may not always
        be perfectly accurate and should interpret the outputs with caution.
        Providing clear disclaimers and instructions for interpreting the
        results can mitigate any potential misunderstandings.
      </li>
      <li>
        The website relies on user-generated content, including images of seabed
        seagrass. The usage of this content is ethically complying with
        copyright laws and user consent. The platform is operated under
        established guidelines for content usage and respect user ownership.
        User-uploaded data will not be shared or published without explicit
        permissions/consent from the user.
      </li>
    </ol>

    <h2>Withdrawal from the research project</h2>
    <p>
      The involvement of participants who use the iBenthos website is entirely
      voluntary and users have the right to withdraw their participation at any
      stage of the website, without facing any negative consequences or
      penalties.
    </p>
    <p>
      Clear instructions will be provided to users regarding the withdrawal
      process. This can be achieved by implementing user-friendly features
      within the website, such as a dedicated "Withdraw" button or a contact
      form, where participants can indicate their decision to withdraw.
    </p>
    <p>
      Upon receiving a withdrawal request, all identifiable participant data
      (e.g. name and affiliation) will be removed promptly from the website. The
      uploaded or annotated datasets and the associated machine learning results
      will remain in the system, and will be used for and only for further
      retraining of the machine learning models.
    </p>

    <h2>Confidentiality</h2>
    <p>
      All information provided by you will be treated confidentially. Your name,
      workplace or any other personal information will not be included in any
      publications resulting from the platform. All data collected in this
      platform will be reported in such a way that responses will not be able to
      be linked to any individuals. De-identified, non-sensitive data collected
      by the project may be shared with other researchers for the purposes of
      verifying published results or advancing other research on this topic. Any
      data collected as part of this platform will be securely stored as per
      CSIRO’s Recordkeeping Procedure.
    </p>

    <h2>How will my information be used?</h2>
    <p>
      The primary purpose of collecting your information is to facilitate the
      iBenthos platform focused on seagrass coverage analysis. Your uploaded
      images and associated data will be used to calculate seagrass coverage
      estimation using our trained machine learning models. The aggregated and
      anonymized data may also be used for broader research purposes, such as
      understanding seagrass ecosystems and contributing to scientific
      knowledge.
    </p>
    <p>
      All collected information will be securely stored using industry-standard
      security measures. We will employ appropriate technical and organizational
      safeguards to protect your data from unauthorized access, loss, or misuse.
      Any identifiable personal details will be stored separately from the
      uploaded images and associated analysis results to ensure confidentiality.
      De-identified information may be shared with other researchers for the
      purposes of verifying published results or future research. Sharing of
      this information may include putting it in data repositories where anyone
      can easily access it. We will ensure this information cannot be linked to
      you before it is published or shared.
    </p>
    <p>
      Your data will not be shared with any third parties without your explicit
      consent, except where required by law or for the purposes of research
      collaboration with other academic or scientific institutions. If data
      sharing is deemed necessary, we will ensure that appropriate data sharing
      agreements are in place to safeguard your privacy and comply with relevant
      regulations.
    </p>

    <h2>What if I have any questions about this study?</h2>
    <p>
      If you have any questions about this project, please feel free to contact
      the project leaders, Dr. Jiajun Liu, at jiajun.liu@csiro.au or Dr Andy
      Steven at andy.steven@csiro.au.
    </p>

    <p>
      This study has been approved by CSIRO’s Social Science Human Research
      Ethics Committee in accordance with the National Statement on Ethical
      Conduct in Human Research (2007). Any concerns or complaints about the
      conduct of this study can be raised with the Manager of Social
      Responsibility and Ethics on (07) 3833 5693 or by email at
      csshrec@csiro.au.
    </p>

    <strong>
      If you consent to take part in this survey, please click the ‘Confirm’
      button below to begin.
    </strong>
  </div>
);
