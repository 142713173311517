import {
  dispatchFromReducer,
  PiRegister,
  ReduxAction,
  RouterShowPageAction,
  update,
} from "@pihanga/core";
import { onTbActionBarSelect } from "@pihanga/tabler/dist/cards/tbActionBar";
import { onTbLoginToken } from "./cards/tbLoginPage";
import {
  init as ivcapInit,
  hasAccessToken,
  dispatchIvcapGetOrderRecord,
  onIvcapUnauthorizedError,
  dispatchIvcapGetServiceRecord,
  onIvcapError,
  ArtifactDataEvent,
  MetadataQueryResultEvent,
  dispatchIvcapGetArtifactRecord,
  //} from "@pihanga/ivcap";
} from "./ivcap";
import { AppState, MetadataSchema, AuthorizationState } from "./app.type";
import {
  onTbXlDataTableRowSelect,
  onTbXlDataTableShowDetail,
} from "@pihanga/tabler/dist/cards/tbDataTable";
import {
  onTbXlDataTableRowSelect as onTbXlDataTableRowSelectiBenthos,
  onTbXlDataTableShowDetail as onTbXlDataTableShowDetailiBenthos,
} from "./cards/tbDataTable";
import { onTbPageLogout } from "@pihanga/tabler/dist/cards/tbPage";
import { init as analyticInit } from "./analytic/analytic.reducer";
import { init as orderInit } from "./orders/order.reducer";
import { init as collectionInit } from "./collection/collection.reducer";
import { init as serviceInit } from "./service/service.reducer";
import { init as projectInit } from "./project/project.reducer";
import { init as aiBotInit } from "./ai-bot/ai-bot.reducer";

import {
  ModalState,
  onModalCloseRequest,
} from "@pihanga/cards/dist/modalWrapper";
import { onCloseModal as onCloseModalConfirmDelete } from "./cards/tbConfirmDelete";
import { onTbButtonClicked } from "@pihanga/tabler/dist/cards/tbButton";
import { onConfirmDelete } from "./cards/tbConfirmDelete";
import {
  ACTION_TYPES,
  onShowArtifactInModal,
  ShowAnalyticCoverageInModalEvent,
} from "./app.actions";
import {
  saveAndSetAccessToken,
  removeAccessToken,
  isFirstTimeUser,
} from "./app.localStorage";
import { getProjectContextRoute } from "./app.state";
import {
  DEF_AUTHORISED_PAGE,
  DEF_PUBLIC_PAGE,
  LANDING_PAGE_ROUTE_PATH,
} from "./app.route";
import {
  CV_PIPELINE_SCHEMA,
  getSourceArtifactId,
} from "./cards/tbAnalyticCard/tbAnalyticCard.component";

export const ERROR_ROUTE_PATH = "error";

const showFirstTimeUserDialog = (s: AppState): AppState => {
  if (isFirstTimeUser()) {
    return update(s, ["modal"], {
      modalCard: "participantInfoDialog",
    } as ModalState);
  } else {
    return s;
  }
};

export function init(register: PiRegister): void {
  ivcapInit(register);
  analyticInit(register);
  orderInit(register);
  collectionInit(register);
  projectInit(register);
  serviceInit(register);
  aiBotInit(register);

  register.reducer("REDUX:INIT", (state: AppState) => {
    return update(state, ["authorization"], {
      authorized: hasAccessToken(),
    } as AuthorizationState);
  });

  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      let tmpState = state;
      if (path.length === 0) {
        // Need to go to login page to verify the authorisation code from Auth0
        const hasCodeFromAuth0 = state.route.query.code;

        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: hasCodeFromAuth0 ? ["login"] : [DEF_PUBLIC_PAGE],
        });
      } else {
        const page = path[0];

        if (page === LANDING_PAGE_ROUTE_PATH) {
          return state;
        }

        if (page === "login" && state.authorization?.authorized) {
          dispatchFromReducer({
            type: "ROUTER:SHOW_PAGE",
            path: [DEF_AUTHORISED_PAGE],
          });
        } else if (page !== "login" && !state.authorization?.authorized) {
          dispatchFromReducer({
            type: "ROUTER:SHOW_PAGE",
            path: ["login"],
          });

          tmpState = update(state, ["authorization"], {
            authorized: false,
            lastPath: path,
          } as AuthorizationState);
        }
      }

      return showFirstTimeUserDialog(tmpState);
    }
  );

  onTbLoginToken<AppState>(register, (state, { token, error }) => {
    if (token) {
      saveAndSetAccessToken(token);

      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path:
          state.authorization?.lastPath &&
          state.authorization?.lastPath[0] !== "login"
            ? state.authorization?.lastPath
            : [DEF_AUTHORISED_PAGE],
      });

      return update(state, ["authorization"], {
        authorized: true,
      } as AuthorizationState);
    } else {
      removeAccessToken();
      return update(state, ["authorization"], {
        authorized: false,
        errorMessage: error || "Invalid token",
      } as AuthorizationState);
    }
  });

  onTbPageLogout<AppState>(register, (state) => {
    removeAccessToken();
    return update(state, ["authorization"], {
      authorized: false,
      justSignedOut: true,
      errorMessage: undefined,
    } as AuthorizationState);
  });

  onIvcapUnauthorizedError<AppState>(register, (state) => {
    if (!hasAccessToken()) {
      return update(state, ["authorization"], {
        authorized: false,
        lastPath: state.route?.path,
      } as AuthorizationState);
    } else {
      removeAccessToken();
      return update(state, ["authorization"], {
        authorized: false,
        errorMessage: "Expired or unknown Token",
        lastPath: state.route?.path,
      } as AuthorizationState);
    }
  });

  function requestDetails(
    cardID: string,
    rowID: string | number,
    state: AppState
  ): void {
    switch (cardID) {
      case "ordersTable": {
        const id = `${rowID}`;
        dispatchIvcapGetOrderRecord({ id, apiURL: state.ivcapApi });
        break;
      }
      case "analyticsTable": {
        const id = `${rowID}`;
        dispatchIvcapGetOrderRecord({ id, apiURL: state.ivcapApi });
        break;
      }
      case "projectTable": {
        const id = `${rowID}`;
        dispatchIvcapGetServiceRecord({ id, apiURL: state.ivcapApi });
        break;
      }
      default:
        console.log(`WARN: TABLE_ROW_SELECT from unknown card ${cardID}`);
    }
  }

  onTbXlDataTableRowSelect<AppState>(register, (state, { cardID, row }) => {
    requestDetails(cardID, row.id, state);
    return state;
  });

  onTbXlDataTableShowDetail<AppState>(register, (state, { cardID, row }) => {
    requestDetails(cardID, row.id, state);
    return state;
  });

  onTbXlDataTableRowSelectiBenthos<AppState>(
    register,
    (state, { cardID, row }) => {
      requestDetails(cardID, row.id, state);
      return state;
    }
  );

  onTbXlDataTableShowDetailiBenthos<AppState>(
    register,
    (state, { cardID, row }) => {
      requestDetails(cardID, row.id, state);
      return state;
    }
  );

  onTbActionBarSelect<AppState>(register, (state, ev) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: getProjectContextRoute(state, [ev.actionID]),
    });
    return state;
  });

  onTbButtonClicked<AppState>(register, (state, { name }) => {
    if (name === "refresh") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.route.path,
      });
    }

    return state;
  });

  // FIXME: This will not catch other errors like "REST:POST_INTERNAL_ERROR:addMetadata"
  // due to a bug in @pihanga/rest-client
  onIvcapError<AppState>(register, (state, error) => {
    dispatchFromReducer({
      type: "ROUTER:SHOW_PAGE",
      path: (state.route.path || []).concat([ERROR_ROUTE_PATH]),
    });

    return update(state, ["error"], error);
  });

  const goBack = (state: AppState): AppState => {
    let s = state;
    s = update(s, ["error"], undefined);

    if (state.prevMeaningfulRoute) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.prevMeaningfulRoute.path,
      });

      s = update(s, ["prevMeaningfulRoute"], undefined);
    } else {
      const path = state.route.path;
      if (path && path[path.length - 1] === ERROR_ROUTE_PATH) {
        dispatchFromReducer({
          type: "ROUTER:SHOW_PAGE",
          path: path.slice(0, path.length - 1),
        });
      }
    }

    if (state.modal) {
      s = update(s, ["modal"], undefined);
    }

    return s;
  };

  onModalCloseRequest<AppState>(register, goBack);
  onCloseModalConfirmDelete<AppState>(register, goBack);

  onConfirmDelete<AppState>(register, (state) => {
    state.confirmDelete?.performDelete();
    return update(state, ["confirmDelete"], undefined);
  });

  const updateArtifactData = (
    state: AppState,
    ev: ArtifactDataEvent
  ): AppState => {
    return update(state, ["artifacts", ev.artifactID], ev);
  };

  onShowArtifactInModal<AppState>(register, (state, ev) => {
    const s1 = update(state, ["artifactModal"], ev);
    return update(updateArtifactData(s1, ev), ["modal"], {
      modalCard: "artifactPreview",
    } as ModalState);
  });

  register.reducer<AppState, ReduxAction & ShowAnalyticCoverageInModalEvent>(
    ACTION_TYPES.SHOW_ANALYTIC_COVERAGE_IN_MODAL,
    (state, ev) => {
      const s1 = update(state, ["analyticModal"], ev);
      return update(s1, ["modal"], {
        modalCard: "analyticResultView",
      } as ModalState);
    }
  );

  register.reducer<AppState, ReduxAction & ArtifactDataEvent>(
    ACTION_TYPES.UPDATE_ARTIFACT_DATA,
    updateArtifactData
  );

  register.reducer<
    AppState,
    ReduxAction & MetadataQueryResultEvent & { id: string; schema: string }
  >(
    ACTION_TYPES.UPDATE_ARTIFACT_METADATA_LIST,
    (
      state: AppState,
      ev: MetadataQueryResultEvent & { id: string; schema: string }
    ): AppState => {
      if (ev.schema === MetadataSchema.DEFAULT) {
        const sourceArtifactId = getSourceArtifactId(
          ev.records.find((r) => r.schema === CV_PIPELINE_SCHEMA)
        );

        if (sourceArtifactId) {
          dispatchIvcapGetArtifactRecord({
            id: sourceArtifactId,
            apiURL: state.ivcapApi,
          });
        }
      }

      return update(state, ["artifactMetadataList", ev.id, ev.schema], {
        ...ev,
        fetching: false,
      });
    }
  );
}
