import { PiCardSimpleProps } from "@pihanga/core";
import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import countryList from "country-list";
import { TbInputComponent } from "../tbInput.component";
import { TbSelectComponent } from "../tbSelect.component";
import { ModalComponent } from "../modal.component";
import { SaveProjectDetail } from "../../app.type";

export type SaveProjectEvent = {
  id: string;
  projectDetail: SaveProjectDetail;
};

export type CreateNewProjectEvent = {
  name: string;
  country: string;
  description: string;
};

export type ComponentProps = {
  id?: string;
  projectDetail?: SaveProjectDetail;
};

type ComponentT = ComponentProps & {
  onSaveProject: (ev: SaveProjectEvent) => void;
  onCreateNewProject: (ev: CreateNewProjectEvent) => void;
  onCloseModal: () => void;
};

const COUNTRY_LIST = countryList.getNames();
const PROJECT_SCHEMA = Yup.object({
  name: Yup.string().required(),
  country: Yup.mixed().oneOf(COUNTRY_LIST).required(),
  description: Yup.string().required(),
});

const DEFAULT_COUNTRY = "Australia";
export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const { id, projectDetail, onSaveProject, onCreateNewProject, onCloseModal } =
    props;

  return (
    <Formik
      onSubmit={(values, resetForm): void => {
        const { name, country, description } = values;
        if (id && projectDetail && projectDetail.createdTime) {
          onSaveProject({
            id,
            projectDetail: {
              name,
              country,
              description,
              createdTime: projectDetail.createdTime as string,
            },
          });
        } else {
          onCreateNewProject({
            name,
            country,
            description,
          });
        }
      }}
      validationSchema={PROJECT_SCHEMA}
      initialValues={
        projectDetail || {
          name: "",
          country: DEFAULT_COUNTRY,
          description: "",
        }
      }
      enableReinitialize
    >
      {({ dirty, handleSubmit }): React.ReactNode => (
        <ModalComponent
          title={id ? "Edit project" : "Create project"}
          onClose={onCloseModal}
          confirmTitle={id ? "Save" : "Create"}
          onConfirm={handleSubmit}
          disableConfirm={!dirty}
        >
          <div className="card-body">
            <Form>
              <Field
                name="name"
                component={TbInputComponent}
                placeholder="Name"
                label="Name"
              />

              <Field
                name="country"
                component={TbSelectComponent}
                options={COUNTRY_LIST.map((s) => ({ value: s, label: s }))}
                placeholder="Country"
                label="Country"
              />

              <Field
                name="description"
                component={TbInputComponent}
                placeholder="Description"
                label="Description"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};
