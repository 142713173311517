import {
  PiRegister,
  PiMapProps,
  PiCardDef,
  registerActions,
  createOnAction,
  actionTypesToEvents,
} from "@pihanga/core";
import { Component, ComponentProps } from "./landing-page.component";

const TB_LANDING_PAGE = "TbLandingPage";

type CardProps<S> = PiMapProps<ComponentProps, S>;
export function TbLandingPage<S>(p: CardProps<S>): PiCardDef {
  return {
    ...p,
    cardType: TB_LANDING_PAGE,
  };
}

export type LoginClickedEvent = {
  token?: string;
  error?: string;
};

const ACTION_TYPES = registerActions("TB_LANDING_PAGE", ["LOGIN_CLICKED"]);

export const onLoginClicked = createOnAction<LoginClickedEvent>(
  ACTION_TYPES.LOGIN_CLICKED
);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_LANDING_PAGE,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
