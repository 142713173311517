import {
  PiRegister,
  dispatchFromReducer,
  update,
  RouterShowPageAction,
} from "@pihanga/core";
import {
  ACTION_TYPES,
  createShowAnalyticCoverageInModalAction,
  createShowAnalyticListingAction,
  dispatchShowArtifactInModal,
  dispatchUpdateArtifactData,
  dispatchUpdateArtifactMetadataList,
  onOrderMetadata,
  onShowAnalyticDetail,
  onShowAnalyticListing,
} from "../app.actions";

import {
  onOrderRecord,
  dispatchIvcapGetOrderList,
  dispatchIvcapGetOrderRecord,
  dispatchIvcapQueryMetadata,
  onOrderReceipt,
  dispatchIvcapGetOrderProductList,
  OrderProduct,
} from "../ivcap"; // "@pihanga/ivcap";
import { AppState } from "../app.type";
import {
  onTbXlDataTableButtonClicked,
  onTbXlDataTableColumnSort,
  onTbXlDataTableNextPage,
  onTbXlDataTablePreviousPage,
} from "../cards/tbDataTable";
import { onCloseModal, onCreateNewOrder } from "../cards/tbOrderDetail";
import { getProjectContextRoute, getProjectId } from "../app.state";
import {
  ACTION_TYPES as ANALYTIC_ACTION_TYPES,
  createLoadMoreOrderProducts,
  createLoadMoreResultsAction,
  onLoadMoreOrderProducts,
  onLoadMoreResults,
  onOrderProductList,
  onResultList,
} from "./analytic.action";

export const NO_OF_ORDERS_PER_PAGE = 10;
export const NO_OF_ORDER_PRODUCTS_PER_PAGE = 5;

export function init(register: PiRegister): void {
  register.reducer<AppState, RouterShowPageAction>(
    "ROUTER:SHOW_PAGE",
    (state, { path }) => {
      if (path.length === 0) {
        return state;
      }
      const page = path[2];
      if (page === "analytics") {
        const item = path[3];
        if (!item) {
          dispatchFromReducer(createShowAnalyticListingAction());
        }
      }
      return state;
    }
  );

  onShowAnalyticListing<AppState>(register, (state) => {
    const p = state.route.path || [];
    if (p[2] !== "analytics") {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["analytics"]),
      });
    } else {
      const projectId = getProjectId(state);
      const event = {
        apiURL: state.ivcapApi,
        limit: NO_OF_ORDERS_PER_PAGE,
        filter: `name~='${projectId}'`,
        template: {
          type: ANALYTIC_ACTION_TYPES.RESULT_LIST,
        },
      };

      dispatchIvcapGetOrderList(event);
      return update(state, ["analyticListing"], {
        loadOrderListEvent: event,
        fetching: true,
      });
    }
    return state;
  });

  onShowAnalyticDetail<AppState>(register, (state, { orderID }) => {
    const p = state.route.path || [];
    if (p[2] !== "analytics" || p[3] !== orderID) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: getProjectContextRoute(state, ["analytics", orderID]),
      });
    } else {
      if (!state.analytics[orderID]) {
        dispatchIvcapGetOrderRecord({ id: orderID, apiURL: state.ivcapApi });
        dispatchIvcapQueryMetadata({
          apiURL: state.ivcapApi,
          entityID: orderID,
          template: {
            type: ACTION_TYPES.ORDER_METADATA,
            orderID,
            records: [], //placeholder
          },
        });
      }
    }
    return state;
  });

  onResultList<AppState>(register, (state, { page, orders, nextPage }) => {
    const wasLoadingMore =
      state.analyticListing &&
      state.analyticListing.nextPage === page &&
      state.analyticListing.list &&
      state.analyticListing.list?.length;

    return update(state, ["analyticListing"], {
      list: wasLoadingMore ? state.analyticListing.list.concat(orders) : orders,
      offset: wasLoadingMore ? state.analyticListing.offset : 0,
      fetchedAt: Date.now(),
      fetching: false,
      nextPage,
    });
  });

  onOrderRecord<AppState>(register, (state, { order }) => {
    const rec = state.analytics[order.id] || {};
    return update(state, ["analytics", order.id], {
      ...rec,
      record: order,
      fetchedAt: Date.now(),
    });
  });

  onOrderMetadata<AppState>(register, (state, { orderID, records }) => {
    const rec = state.analytics[orderID] || {};
    return update(state, ["analytics", orderID], {
      ...rec,
      metadata: records,
      fetchedAt: Date.now(),
    });
  });

  onTbXlDataTableButtonClicked<AppState>(register, (state, ev) => {
    switch (ev.cardID) {
      case "analyticProductListing":
        if (ev.label !== "view") {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "analyticProductListing" from unknown label "${ev.label}"`
          );
          return state;
        }

        const id = ev.row?.id as string;
        const dataURL = ev.row?.data?.dataURL;

        if (id && dataURL) {
          const name = ev.row?.data?.name;

          if (name.endsWith("_indexed.png")) {
            dispatchUpdateArtifactData(state, id, dataURL as string, name);
            dispatchUpdateArtifactMetadataList(state, id);

            dispatchFromReducer(
              createShowAnalyticCoverageInModalAction(name, ev.row?.data)
            );
          } else {
            return dispatchShowArtifactInModal(state, id, dataURL, name);
          }
        } else {
          console.warn(
            `WARN: TBDTABLE:BUTTON_CLICKED on "analyticProductListing" from unknown label "${ev.label}"`
          );
        }
        return state;

      default:
        console.warn(
          `WARN: TBDTABLE:BUTTON_CLICKED from unknown cardID "${ev.cardID}"`
        );
    }
    return state;
  });

  onCloseModal<AppState>(register, (state) => {
    if (state.prevMeaningfulRoute) {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: state.prevMeaningfulRoute.path,
      });

      return update(state, ["prevMeaningfulRoute"], undefined);
    } else {
      dispatchFromReducer({
        type: "ROUTER:SHOW_PAGE",
        path: ["analytics"],
      });
    }

    return state;
  });

  onCreateNewOrder<AppState>(register, (state, { collection }) => {
    dispatchFromReducer({
      apiURL: state.ivcapApi,
      type: ACTION_TYPES.ADD_ORDER,
      collection,
    });

    return state;
  });

  onOrderReceipt<AppState>(register, (state, { order }) => {
    return update(state, ["analytics", order.id], {
      record: order,
      fetchedAt: Date.now(),
    });
  });

  onTbXlDataTableColumnSort<AppState>(
    register,
    (state, { cardID, isAscending, col, context }) => {
      const orderBy = col.label;
      const orderDesc = !isAscending;

      if (cardID === "analyticsTable") {
        const colLabelToOrderBy = (s: string): string => {
          switch (s) {
            case "orderedAt":
              return "ordered-at";
            case "finishedAt":
              return "finished-at";
            default:
              return s;
          }
        };

        const event = {
          ...state.analyticListing.loadOrderListEvent,
          orderBy: colLabelToOrderBy(orderBy),
          orderDesc,
          template: {
            type: ANALYTIC_ACTION_TYPES.RESULT_LIST,
          },
        };

        dispatchIvcapGetOrderList(event);

        return update(state, ["analyticListing"], {
          offset: 0,
          fetching: true,
          loadOrderListEvent: event,
        });
      } else if (cardID === "analyticProductListing") {
        const orderId = context?.orderID as string;
        if (!state.analytics[orderId]) return state;

        const collLabelToOrderBy = (orderBy: string): string => {
          switch (orderBy) {
            case "mimeType":
              return "mime-type";
            default:
              return orderBy;
          }
        };

        dispatchIvcapGetOrderProductList({
          apiURL: state.ivcapApi,
          orderId,
          orderBy: collLabelToOrderBy(orderBy),
          orderDesc,
          limit: NO_OF_ORDER_PRODUCTS_PER_PAGE,
          template: {
            type: ANALYTIC_ACTION_TYPES.ORDER_PRODUCT_LIST,
            orderId,
          },
        });

        return update(
          state,
          ["analytics", orderId, "record", "productsFetching"],
          true
        );
      }

      return state;
    }
  );

  onTbXlDataTableNextPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing, context }) => {
      if (cardID === "analyticsTable") {
        if (!state.analyticListing.list) return state;

        const count = state.analyticListing.list
          ? state.analyticListing.list.length
          : 0;

        if (
          recordsShowing + offset >= count &&
          state.analyticListing.nextPage
        ) {
          dispatchFromReducer(
            createLoadMoreResultsAction(state.analyticListing.nextPage)
          );
        }

        return update(state, ["analyticListing"], {
          offset: recordsShowing + offset,
        });
      } else if (cardID === "analyticProductListing") {
        if (!context) return state;

        const orderId = context.orderID as string;
        if (!state.analytics[orderId]) return state;

        const count = state.analytics[orderId].record.products
          ? state.analytics[orderId].record.products.length
          : 0;

        if (
          recordsShowing + offset >= count &&
          state.analytics[orderId].record.productsNextPage
        ) {
          dispatchFromReducer(
            createLoadMoreOrderProducts(
              orderId,
              state.analytics[orderId].record?.productsNextPage as string
            )
          );
        }

        return update(state, ["analytics", orderId, "record"], {
          productsOffset: recordsShowing + offset,
        });
      }

      return state;
    }
  );

  onTbXlDataTablePreviousPage<AppState>(
    register,
    (state, { cardID, offset, recordsShowing, context }) => {
      if (cardID === "analyticsTable") {
        if (!state.analyticListing.list) return state;

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, NO_OF_ORDERS_PER_PAGE),
          0
        );

        return update(state, ["analyticListing"], {
          offset: newOffset,
        });
      } else if (cardID === "analyticProductListing") {
        if (!context) return state;

        const orderId = context.orderID as string;
        if (!state.analytics[orderId]) return state;

        const newOffset = Math.max(
          offset - Math.max(recordsShowing, NO_OF_ORDER_PRODUCTS_PER_PAGE),
          0
        );

        return update(state, ["analytics", orderId, "record"], {
          productsOffset: newOffset,
        });
      }
      return state;
    }
  );

  onLoadMoreResults<AppState>(register, (state, { page }) => {
    const event = {
      ...state.analyticListing.loadOrderListEvent,
      page,
      template: {
        type: ANALYTIC_ACTION_TYPES.RESULT_LIST,
        page,
      },
    };

    dispatchIvcapGetOrderList(event);
    return update(state, ["analyticListing"], {
      fetching: true,
      loadOrderListEvent: event,
    });
  });

  onLoadMoreOrderProducts<AppState>(register, (state, { orderId, page }) => {
    dispatchIvcapGetOrderProductList({
      apiURL: state.ivcapApi,
      page,
      orderId,
      limit: NO_OF_ORDER_PRODUCTS_PER_PAGE,
      template: {
        type: ANALYTIC_ACTION_TYPES.ORDER_PRODUCT_LIST,
        page,
        orderId,
      },
    });

    return update(
      state,
      ["analytics", orderId, "record", "productsFetching"],
      true
    );
  });

  onOrderProductList<AppState>(
    register,
    (state, { orderId, page, items, nextPage }) => {
      const wasLoadingMore =
        state.analytics &&
        state.analytics[orderId].record.productsNextPage === page &&
        state.analytics[orderId].record.products &&
        state.analytics[orderId].record.products?.length;

      const s = update(state, ["analytics", orderId], {
        fetchedAt: Date.now(),
      });

      const products: OrderProduct[] = items.map((i) => ({
        id: i.id,
        name: i.name || "",
        status: i.status,
        mimeType: i["mime-type"],
        dataURL: i["data-href"],
        size: i.size,
      }));

      return update(s, ["analytics", orderId, "record"], {
        products: wasLoadingMore
          ? state.analytics[orderId].record.products.concat(products)
          : products,

        productsOffset: wasLoadingMore
          ? state.analytics[orderId].record.productsOffset
          : 0,

        productsFetching: false,
        productsNextPage: nextPage,
      });
    }
  );
}
