import {
  PiRegister,
  dispatchFromReducer,
  ReduxState,
  ReduxAction,
  createOnAction,
  dispatch,
  update,
} from "@pihanga/core";
import { registerGET, registerPOST, registerPATCH } from "@pihanga/rest-client";
import { URN, getAccessToken } from ".";
import {
  ACTION_TYPES,
  BaseEvent,
  createListAction,
  createReadAction,
  ListEvent,
  LoadListEvent,
  LoadRecordEvent,
} from "./actions";
import {
  getNextPage,
  createListUrlBuilder,
  restErrorHandling,
  dispatchEvent,
} from "./common";
import { AppState } from "../app.type";

// LIST

type ArtifactListEvent = ListEvent & {
  items: ArtifactListItem[];
};

export type ArtifactListItem = {
  id: string;
  name: string;
  status: string;
  accountID: string;
  //template?: Template<T>
};

export type LoadArtifactListEvent<T = {}> = LoadListEvent<T>;

export function dispatchIvcapGetArtifactList<T = {}>(
  ev: LoadArtifactListEvent<T>
): void {
  const a = createListAction(ACTION_TYPES.LOAD_ARTIFACT_LIST, ev);
  dispatchFromReducer(a);
}

export const onArtifactList = createOnAction<ArtifactListEvent>(
  ACTION_TYPES.ARTIFACT_LIST
);

// RECORD

type ArtifactRecordEvent = {
  artifact: ArtifactRecord;
};

export type ArtifactRecord = {
  id: string;
  name: string;
  status: string;
  mimeType: string;
  size: number;
  dataURL: string;
  accountID: string;
};

export type LoadArtifactRecordEvent<T = {}> = LoadRecordEvent<T>;

export function dispatchIvcapGetArtifactRecord<T = {}>(
  ev: LoadArtifactRecordEvent<T>
): void {
  const a = createReadAction(ACTION_TYPES.LOAD_ARTIFACT_RECORD, ev);
  dispatchFromReducer(a);
}

export const onArtifactRecord = createOnAction<ArtifactRecordEvent>(
  ACTION_TYPES.ARTIFACT_DETAIL
);

// DATA DOWNLOAD

export type ArtifactDataEvent = {
  artifactID: string;
  data: Blob | any;
  dataJSON?: any;
  imgURL?: string;
  size: number;
  mimeType: string;
  fetching?: boolean;
};

export type LoadArtifactDataEvent<T = {}> = BaseEvent<T> & {
  id: string;
  dataURL: string;
};

export function dispatchIvcapGetArtifactData<T = {}>(
  state: AppState,
  ev: LoadArtifactDataEvent<T>
): AppState {
  const a = createReadAction(ACTION_TYPES.LOAD_ARTIFACT_DATA, ev);
  dispatchFromReducer(a);
  return update(state, ["artifacts", ev.id], {
    fetching: true,
  });
}

export const onArtifactData = createOnAction<ArtifactDataEvent>(
  ACTION_TYPES.ARTIFACT_DATA
);

// DATA UPLOAD

export type UploadArtifactDataEvent<T = {}> = BaseEvent<T> & {
  file: File;
  name?: string; // if not set, use file.name
  refID?: string;
};

export type ArtifactUploadedEvent = {
  name: string;
  artifactURN: URN;
  size: number;
  contentType: string;
  refID?: string;
};

export function dispatchIvcapUploadArtifactData<T = {}>(
  ev: UploadArtifactDataEvent<T>
): void {
  const a = { type: ACTION_TYPES.UPLOAD_ARTIFACT_DATA, ...ev };
  dispatchFromReducer(a);
}

export const onArtifactUploaded = createOnAction<ArtifactUploadedEvent>(
  ACTION_TYPES.UPLOADED_ARTIFACT_RECORD
);

export type ArtifactPartialUploadEvent<T = {}> = BaseEvent<T> & {
  name: string;
  artifactURN: URN;
  refID?: string;
  content: ArrayBuffer;
  contentType: string;
  offset: number;
  size: number;
  chunkSize: number;
  timeStamp: number;
  // template?: {
  //   type: string;
  //   [key: string]: any;
  // };
};

export type ArtifactUploadProgressEvent = {
  name: string;
  artifactURN: URN;
  refID?: string;
  progress: number; // percent
  uploadRate: number; // byes/sec
};

//====== API HANDLER

export function init(register: PiRegister): void {
  registerGET<ReduxState, ReduxAction & LoadArtifactListEvent, any>(register)({
    name: "loadArtifactList",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("artifacts"),
    trigger: ACTION_TYPES.LOAD_ARTIFACT_LIST,
    request: (a, _) => a as any,
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, { template }) => {
      const ev: ArtifactListEvent = {
        nextPage: getNextPage(content.links),
        items: (content.items || []).map(toArtifactListItem),
      };
      dispatchEvent(ev, ACTION_TYPES.ARTIFACT_LIST, template);
      return state;
    },
    error: restErrorHandling("ivcap-api:loadArtifactList"),
  });

  registerGET<ReduxState, ReduxAction & LoadArtifactRecordEvent, any>(register)(
    {
      name: "getArtifactDetail",
      origin: ({ apiURL }, _) => apiURL,
      url: "/1/artifacts/:id",
      trigger: ACTION_TYPES.LOAD_ARTIFACT_RECORD,
      request: ({ id }, _) => ({ id }),
      headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
      reply: (state, content: any, { template }) => {
        const ev: ArtifactRecordEvent = {
          artifact: toArtifactRecord(content),
        };
        dispatchEvent(ev, ACTION_TYPES.ARTIFACT_DETAIL, template);
        return state;
      },
      error: restErrorHandling("ivcap-api:getArtifactDetail"),
    }
  );

  registerGET<ReduxState, ReduxAction & LoadArtifactDataEvent, any>(register)({
    name: "getArtifactData",
    origin: ({ dataURL }, _) => new URL(dataURL).origin,
    url: "/1/artifacts/:id/blob",
    trigger: ACTION_TYPES.LOAD_ARTIFACT_DATA,
    // request: ({ dataURL }) => ({ path: new URL(dataURL).pathname }),
    request: (a, _) => {
      const id = a.id.split(":")[3];
      return { id };
    },
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, { id, template }, mimeType) => {
      const blob = content as Blob;

      const evb: ArtifactDataEvent = {
        artifactID: id,
        data: blob,
        dataJSON: undefined,
        imgURL: URL.createObjectURL(blob),
        mimeType,
        size: blob.size,
        fetching: false,
      };

      // FIXME: temporary check if the file is JSON or other format due to incorrect Content-Type issue with IVCAP:
      // https://github.com/reinventingscience/ivcap-core/issues/246
      const t = template as any;
      if (t && t.title && t.title.endsWith(".json")) {
        blob.text().then((d) => {
          dispatchEvent(
            {
              ...evb,
              dataJSON: JSON.parse(d),
            },
            ACTION_TYPES.ARTIFACT_DATA,
            template
          );
        });
      } else {
        dispatchEvent(evb, ACTION_TYPES.ARTIFACT_DATA, template);
      }

      return state;
    },
    error: restErrorHandling("ivcap-api:getArtifactData"),
  });

  registerPOST<ReduxState, ReduxAction & UploadArtifactDataEvent, any>(
    register
  )({
    name: "createArtifact",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/artifacts",
    trigger: ACTION_TYPES.UPLOAD_ARTIFACT_DATA,
    request: ({ file }) => {
      return {
        body: "",
        contentType: file.type,
      };
    },
    headers: ({ name, file }) => ({
      Authorization: `Bearer ${getAccessToken()}`,
      "X-Content-Length": `${file.size}`,
      "X-Content-Type": `${file.type}`,
      "Upload-Length": `${file.size}`,
      "X-Name": btoa(name || file.name),
    }),
    reply: (state, reply: any, req) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(req.file);
      reader.onload = (): void => {
        const content = reader.result;
        const size = req.file.size;
        let chunkSize = Math.min(size / 5, 4000000);
        chunkSize = Math.trunc(Math.max(chunkSize, 2000000));

        dispatch<ReduxAction & ArtifactPartialUploadEvent>({
          name: req.name || req.file.name,
          type: ACTION_TYPES.UPLOAD_ARTIFACT_PARTIAL,
          apiURL: req.apiURL,
          artifactURN: reply["id"],
          content: content as ArrayBuffer,
          contentType: req.file.type,
          refID: req.refID,
          offset: 0,
          size,
          chunkSize,
          timeStamp: Date.now(),
          template: req.template,
        });
      };
      reader.onerror = (): void => {
        restErrorHandling("ivcap-api:loadFile");
      };
      return state;
    },
    error: restErrorHandling("ivcap-api:createArtifact"),
  });

  registerPATCH<ReduxState, ReduxAction & ArtifactPartialUploadEvent, any>(
    register
  )({
    name: "uploadArtifactPartial",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/artifacts/:id/blob",
    trigger: ACTION_TYPES.UPLOAD_ARTIFACT_PARTIAL,
    request: ({ artifactURN, content, offset, chunkSize, contentType }) => {
      const body = content.slice(offset, offset + chunkSize);
      return {
        bindings: { id: artifactURN },
        body,
        contentType,
      };
    },
    headers: ({ offset, chunkSize, size }) => {
      const l = Math.min(chunkSize, size - offset);
      return {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/offset+octet-stream",
        "Content-Length": `${l}`,
        "Upload-Length": `${size}`,
        "Upload-Offset": `${offset}`,
        "Tus-Resumable": "1.0.0",
      };
    },
    reply: (state, _, req) => {
      const offset = Math.min(req.offset + req.chunkSize, req.size);
      if (offset >= req.size) {
        // we are done
        const evd: ArtifactUploadedEvent = {
          name: req.name,
          artifactURN: req.artifactURN,
          size: req.size,
          contentType: req.contentType,
          refID: req.refID,
        };
        dispatchEvent(evd, ACTION_TYPES.ARTIFACT_LIST, req.template);
      } else {
        const now = Date.now();
        dispatchFromReducer<ReduxAction & ArtifactPartialUploadEvent>({
          ...req,
          offset,
          timeStamp: now,
        });
        const uploadRate =
          (1000 * (offset - req.offset)) / (now - req.timeStamp);
        const progress = (1.0 * offset) / req.size;
        dispatchFromReducer<ReduxAction & ArtifactUploadProgressEvent>({
          type: ACTION_TYPES.UPLOAD_ARTIFACT_PROGRESS,
          name: req.name,
          artifactURN: req.artifactURN,
          refID: req.refID,
          progress,
          uploadRate,
        });
      }
      return state;
    },
    error: restErrorHandling("ivcap-api:uploadArtifactPartial"),
  });
}

function toArtifactListItem(els: any): ArtifactListItem {
  // {
  //   id: 'urn:ivcap:artifact:cbcc1748-1a45-4369-85ee-e631b99676c4',
  //   name: 'tmppsmdglsm-1154x866.pseudo.png',
  //   status: 'ready'
  // }
  return {
    id: els.id,
    name: els.name,
    status: els.status,
    accountID: els.account_id,
  };
}

function toArtifactRecord(els: any): ArtifactRecord {
  // id: 'urn:ivcap:artifact:76fc1192-0eae-469a-b3a5-bda29f4924ce',
  // name: 'tmp4kjnvxl9-1154x866.pseudo.png',
  // data: {
  //   self: 'https://develop.ivcap.net/1/artifacts/76fc1192-0eae-469a-b3a5-bda29f4924ce/blob'
  // },
  // status: 'ready',
  // 'mime-type': 'image/jpeg',
  // size: 18317,
  // account: {
  //   id: 'urn:ivcap:account:4c65b865-df6a-4977-982a-f96b19c1fda0'
  // }
  return {
    id: els.id,
    name: els.name,
    status: els.status,
    mimeType: els["mime-type"],
    size: els.size,
    dataURL: els["data-href"],
    accountID: els.account,
  };
}
