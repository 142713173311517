import React from "react";
import { Card, PiCardSimpleProps } from "@pihanga/core";

export type TemplateT = {
  area: number[][]; // index into cards[]
  rows: string[];
  columns: string[];
  gap?: string;
};

export type ComponentProps = {
  cards: string[];
  template: TemplateT;
  height?: string;
  margin?: string;
  overflow?: string;
};

type ComponentT = ComponentProps;

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const {
    cardName,
    cards = [],
    template,
    height = "auto", //'100vh',
    margin = 0,
    overflow = "hidden", // 'scroll',
  } = props;

  const areaRows = template.area.map((rn) => {
    const r = rn.map((c) => `a${c}`);
    return `"${r.join(" ")}"`;
  });
  const area = areaRows.join(" ");

  const style = {
    display: "grid",
    gridTemplateAreas: area,
    gridTemplateRows: template.rows.join(" "),
    gridTemplateColumns: template.columns.join(" "),
    gridGap: template.gap || "10px",
    height,
    margin,
    width: "100%",
  }; // having type issues with gridTemplateXXX

  function renderGridCard(name: string, idx: number): JSX.Element {
    const style = {
      gridArea: `a${idx}`, // c2n[name],
      overflow,
    };
    return (
      <div style={style} key={idx}>
        <Card cardName={name} />
      </div>
    );
  }

  return (
    <div style={style} data-pihanga={cardName}>
      {cards.map(renderGridCard)}
    </div>
  );
};
