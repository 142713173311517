import { ReduxAction, createOnAction, registerActions } from "@pihanga/core";
import { AiBotRating } from "./ai-bot.type";

export const ACTION_TYPES = registerActions("APP:AI_BOT", [
  "INVESTIGATE",
  "INVESTIGATE_RESULT",
]);

// INVESTIGATE

export type InvestigateEvent = {
  serviceID: string;
  collectionID: string;
};

export const onInvestigate = createOnAction<InvestigateEvent>(
  ACTION_TYPES.INVESTIGATE
);

export const createInvestigateAction = (
  serviceID: string,
  collectionID: string
): ReduxAction & InvestigateEvent => ({
  type: ACTION_TYPES.INVESTIGATE,
  serviceID,
  collectionID,
});

// INVESTIGATE_RESULT

export type InvestigateResultEvent = {
  serviceID: string;
  collectionID: string;
  rating: AiBotRating;
  explanation: string;
};

export const onInvestigateResult = createOnAction<InvestigateResultEvent>(
  ACTION_TYPES.INVESTIGATE_RESULT
);

export const createInvestigateResultAction = (
  serviceID: string,
  collectionID: string,
  rating: AiBotRating,
  explanation: string
): ReduxAction & InvestigateResultEvent => ({
  type: ACTION_TYPES.INVESTIGATE_RESULT,
  serviceID,
  collectionID,
  rating,
  explanation,
});
