import { dispatchFromReducer, PiCardDef } from "@pihanga/core";
import { AppState, CollectionView } from "../app.type";
import { ColumnType, TbXlDataTable } from "../cards/tbDataTable";
import {
  GenericColumn,
  IconType,
} from "../cards/tbDataTable/datatable.component";

import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";
import {
  DataGridElType,
  TbDataGrid,
} from "@pihanga/tabler/dist/cards/tbDataGrid";

import { getCollection, getCollectionId, GOOGLE_MAP_KEY } from "../app.state";
import { TbButton } from "@pihanga/tabler/dist/cards/tbButton";
import { PiFlexGrid } from "../cards/flexGrid";
import { TbButtonType } from "@pihanga/tabler/dist/cards";
import { TbCard, TbNavButton } from "../cards/tbCard";

import { init as orderInit } from "./collection.order.pihanga";
import { TbEmptyList } from "../cards/tbEmptyList";
import { PiGoogleMap } from "../cards/googleMap";
import { createPreviewArtifactAction } from "./collection.action";
import { MapMarkerSelectedEvent } from "../cards/googleMap/googleMap.component";
import { NO_OF_IMAGES_PER_PAGE } from "./collection.reducer";
import { TOP_SPECIES_NUMBER } from "../ivcap/search";
import { IBenthosJsonViewer } from "../cards/jsonViewer";

export function init(cards: { [k: string]: PiCardDef }): void {
  orderInit(cards);

  cards.collectionsDetail = TbXlCard<AppState>({
    title: (s) =>
      `Collection: ${getCollectionName((s.route.path || [])[3], s)}`,
    contentCard: "collectionsDetailCard",
    wrapContentInCard: false,
    actionCards: ["requestAnalyticsButton"],
    infoCards: ["refreshButton"],
  });

  cards.requestAnalyticsButton = TbButton<AppState>({
    name: "request-analytics",
    title: "Request Analytics",
    isGhostButton: true,
    buttonType: TbButtonType.Secondary,
    style: {
      textTransform: "uppercase",
    },
    iconName: "plus",
    iconStyle: {
      marginRight: "calc(var(--tblr-btn-padding-x) / 16)",
    },
  });

  cards.collectionsDetailCard = PiFlexGrid<AppState>({
    cards: (s) => {
      const coll = getCollection(s);
      if (coll && coll.showAnalyticsOrder) {
        return ["createAnalyticsOrder", "collectionDetailImages"];
      } else {
        return [
          "collectionsDetailSummary",
          "collectionDetailImages",
          "collectionAnalyticsListing",
        ];
      }
    },
    template: {
      area: [[0], [1], [2], [3]],
      rows: ["auto"],
      columns: ["auto"],
      gap: "10px",
    },
  });

  cards.collectionsDetailSummary = TbDataGrid<AppState>({
    title: "Summary",
    items: (s) => {
      const coll = s.collections[getCollectionId(s)!];
      return [
        { id: "name", type: DataGridElType.Text, value: coll?.summary?.name },
        {
          id: "project",
          type: DataGridElType.Text,
          value: coll?.summary?.project || "Unknown",
        },
        {
          id: `Top ${TOP_SPECIES_NUMBER} Classes by Average Percent Coverage`,
          type: DataGridElType.Card,
          cardName: "collectionStatsViewer",
        },
      ];
    },
    wrapInCard: true,
  });

  cards.collectionStatsViewer = IBenthosJsonViewer<AppState>({
    source: (s: AppState) => {
      const coll = s.collections[getCollectionId(s)!];
      return coll?.stats;
    },
    name: "Coverage By Site",
    collapsed: true,
  });

  cards.collectionAnalyticsListing = TbXlCard<AppState>({
    title: "Collection’s Analytics Results",
    contentCard: "analyticsTable",
    infoCards: ["refreshButton"],
  });

  cards.collectionDetailImages = TbCard<AppState>({
    title: "Images",
    contentCard: (s) => {
      const view = getCollection(s)?.useView || CollectionView.Table;
      return view === CollectionView.Map
        ? "collectionDetailMap"
        : "collectionDetailImagesSplitView";
    },
    headerRightCards: ["showListButton", "showMapButton"],
  });

  cards.collectionDetailImagesSplitView = PiFlexGrid<AppState>({
    cards: (s) => {
      return ["collectionDetailMap", "collectionDetailImagesTable"];
    },
    template: {
      area: [[0, 1]],
      rows: ["auto"],
      columns: ["1fr", "1fr"],
      gap: "10px",
    },
  });

  cards.showListButton = TbNavButton<AppState>({
    id: "show-list",
    iconName: "table", // defined in app.pihanga
    isActive: (s) => {
      const view = getCollection(s)?.useView || CollectionView.Table;
      return view === CollectionView.Table;
    },
  });

  cards.showMapButton = TbNavButton<AppState>({
    id: "show-map",
    iconName: "map", // defined in app.pihanga
    isActive: (s) => {
      const view = getCollection(s)?.useView;
      return view === CollectionView.Map;
    },
  });

  cards.collectionDetailMap = PiGoogleMap<AppState>({
    zoomLevel: (s: AppState) => {
      const collectionId = getCollectionId(s);
      const collection = s.collections[collectionId!];
      const mapCenter = collection && collection.mapCenter;
      return mapCenter && mapCenter.lat ? 30 : undefined;
    },
    center: (s: AppState) => {
      const collectionId = getCollectionId(s);
      const collection = s.collections[collectionId!];
      return collection && collection.mapCenter;
    },
    onMarkerSelected: (s: AppState) => (ev: MapMarkerSelectedEvent) => {
      if (ev.markerIDs.length > 0) {
        const id = ev.markerIDs[0];
        dispatchFromReducer(createPreviewArtifactAction(id));
      }
    },
    markers: (s: AppState) => {
      const collectionId = getCollectionId(s);
      const coll = s.collections[collectionId!];
      return (coll?.images || []).map((img, i) => ({
        id: img.artifactURN,
        label: img.name,
        lat: img.latitude,
        lng: img.longitude,
      }));
    },
    height: 705,
    apiKey: GOOGLE_MAP_KEY,
  });

  cards.collectionDetailImagesTable = TbXlDataTable<AppState>({
    columns: (s: AppState) => {
      const coll = getCollection(s);
      const selectImages = coll && coll.showAnalyticsOrder;

      let columns: GenericColumn[] = [
        { label: "preview", title: "Preview", type: ColumnType.Button },
        {
          label: "image",
          title: "Image",
          type: ColumnType.Icon,
          iconType: IconType.SVG,
        },
        {
          label: "name",
          title: "Name",
          sortable: true,
          type: ColumnType.String,
        },
        {
          label: "width",
          title: "Width",
          sortable: false,
          type: ColumnType.Number,
        },
        {
          label: "height",
          title: "Height",
          sortable: false,
          type: ColumnType.Number,
        },
        {
          label: "latitude",
          title: "Latitude",
          sortable: false,
          type: ColumnType.Number,
        },
        {
          label: "longitude",
          title: "Longitude",
          sortable: false,
          type: ColumnType.Number,
        },
      ];

      if (selectImages) {
        columns.unshift({
          label: "selected",
          title: "",
          type: ColumnType.Toggle,
        });
      }

      return columns;
    },
    data: (s: AppState) => {
      const collectionId = getCollectionId(s);
      const coll = s.collections[collectionId!];
      const offset = coll?.offset || 0;
      const images = (coll?.images || []).slice(
        offset,
        offset + NO_OF_IMAGES_PER_PAGE
      );

      return images.map(
        ({
          artifactURN,
          name,
          width,
          height,
          latitude,
          longitude,
          selected,
        }) => {
          const imgURL =
            (s.artifacts &&
              s.artifacts[artifactURN] &&
              s.artifacts[artifactURN].imgURL) ||
            "";

          return {
            id: artifactURN,
            data: {
              image: imgURL,
              name,
              width,
              height,
              latitude,
              longitude,
              artifactURN: artifactURN,
              selected,
            },
          };
        }
      );
    },
    dataOffset: (s: AppState) => {
      const coll = getCollection(s);
      return coll ? coll.offset : 0;
    },
    hasMore: (s) => {
      const coll = getCollection(s);
      const canLoadMore =
        (coll?.offset || 0) + NO_OF_IMAGES_PER_PAGE <
        (coll?.images?.length || 0);

      return !!coll?.nextPage || canLoadMore;
    },
    showSearch: false,
    // FIXME: handle "fetching" collection detail state
    cardOnEmpty: (s) => {
      const collection = s.collections[getCollectionId(s)!];

      return collection && collection.fetching
        ? "spinnerCard"
        : "emptyCollectionDetailImages";
    },
    wrapInCard: true,
    recordCount: (s: AppState) => {
      const coll = getCollection(s);
      return coll && coll.imageCount;
    },
  });

  cards.emptyCollectionDetailImages = TbEmptyList<AppState>({
    title: "No images available.",
  });
}

function getCollectionName(id: string, state: AppState): string {
  const rec = state.collections[id];
  if (rec) {
    return rec.summary?.name;
  } else {
    return "????";
  }
}
