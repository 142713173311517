import { AppState, CollectionDetail } from "./app.type";
import { getSavedAccessToken, saveAndSetAccessToken } from "./app.localStorage";

const VERSION = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:version"]'
)?.content;

const IVCAP_API_URL = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:api-url"]'
)?.content;

const IVCAP_FEATURE_FLAGS = document.querySelector<HTMLMetaElement>(
  '[name="ivcap:feature-flags"]'
)?.content;

const _GOOGLE_MAP_KEY = document.querySelector<HTMLMetaElement>(
  '[name="gmap:api-key"]'
)?.content;

export const GOOGLE_MAP_KEY =
  _GOOGLE_MAP_KEY && _GOOGLE_MAP_KEY !== "@@GOOGLE_MAP_API_KEY@@"
    ? _GOOGLE_MAP_KEY
    : process.env.REACT_APP_GOOGLE_MAP_KEY || "???";

export const getProjectId = (s: AppState): string | undefined => {
  return s.route.path && s.route.path[0] === "projects"
    ? s.route.path[1]
    : undefined;
};

export const getCollectionId = (s: AppState): string | undefined => {
  return s.route.path && s.route.path[2] === "collections"
    ? s.route.path[3]
    : undefined;
};

export const getCollection = (s: AppState): CollectionDetail | undefined => {
  const collID = getCollectionId(s);
  return collID ? s.collections[collID] : undefined;
};

/**
 * Get a route path to a page that depends on a project being selected.
 * @param s App state
 * @param routePath
 */
export const getProjectContextRoute = (
  s: AppState,
  routePath: string[]
): string[] => {
  const selectedProjectId = getProjectId(s);
  if (selectedProjectId !== undefined)
    return ["projects", selectedProjectId, ...routePath];
  else return ["projects"];
};

const AUTO_LOGIN_TOKEN = process.env.REACT_APP_AUTO_LOGIN_TOKEN;
const apiURL =
  IVCAP_API_URL && IVCAP_API_URL !== "@@API_URL@@"
    ? new URL(IVCAP_API_URL)
    : new URL(process.env.REACT_APP_API_URL || "http:unknown.com");

const state: AppState = {
  projectListing: { offset: -1, list: [], fetching: false },
  projects: {},

  analyticListing: {
    offset: -1,
    list: [],
    fetching: false,
    loadOrderListEvent: { apiURL },
  },
  analytics: {},

  artifacts: {},
  artifactMetadataList: {},

  orderListing: {
    offset: -1,
    list: [],
    fetching: false,
    loadOrderListEvent: { apiURL },
  },
  orders: {},

  collectionListing: { offset: -1, list: [], fetching: false },
  collections: {},

  services: {},

  aiBot: {},

  modal: {},

  version:
    VERSION && VERSION !== "@@VERSION@@"
      ? VERSION
      : process.env.REACT_APP_VERSION || "???",

  ivcapApi: apiURL,

  error: undefined,
  confirmDelete: undefined,

  authorization: {
    authorized: AUTO_LOGIN_TOKEN ? true : false,
  },

  route: {
    // remove after fixing @pihanag/core/router/router.reducer.js:
    //   registerReducer('REDUX', 'INIT', function (state) {
    //     var url;
    //     if (state.route.path) { << should check for 'empty' path
    path: null,
    query: {},
  },

  featureFlags: getFeatureFlags(),

  // pihanga: {},
};

if (AUTO_LOGIN_TOKEN) {
  saveAndSetAccessToken(AUTO_LOGIN_TOKEN);
} else {
  getSavedAccessToken();
}

function getFeatureFlags(): Set<string> {
  let ff: string;
  if (IVCAP_FEATURE_FLAGS && IVCAP_FEATURE_FLAGS !== "@@FEATURE_FLAGS@@") {
    ff = IVCAP_FEATURE_FLAGS;
  } else {
    ff = process.env.REACT_APP_FEATURE_FLAGS || "";
  }
  return new Set(ff.split(","));
}

export default state;
