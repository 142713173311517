import { PiCardSimpleProps } from "@pihanga/core";
import React from "react";

export type ComponentProps = {
  content?: React.ReactNode;
};

export const Component = ({
  content,
}: PiCardSimpleProps<ComponentProps>): React.ReactNode => {
  return content;
};
