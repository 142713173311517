import { PiCardDef } from "@pihanga/core";
import { AppState, ServiceDetail } from "../app.type";
// import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";

// import { PiFlexGrid } from "@pihanga/cards/dist/flexGrid";
// import { Row, TbXlDataTable } from "@pihanga/tabler/dist/cards/tbDataTable";
import {
  DataGridEl,
  DataGridElType,
} from "@pihanga/tabler/dist/cards/tbDataGrid";
import { PiMarkdown } from "@pihanga/cards/dist/markdown";
import { ThreeDotsBounce, Alert } from "./icons";
import { getTask } from "./ai-bot.reducer";
import { AiBotRating } from "./ai-bot.type";
import { ColumnType } from "@pihanga/tabler/dist/cards/tbDataTable";
import { IconType } from "@pihanga/tabler/dist/cards/tbDataTable/datatable.component";

const dangerStyle = {
  border: "2px solid red",
  backgroundColor: "#ffe100",
  padding: "3px",
};

export function init(cards: { [k: string]: PiCardDef }): void {
  cards.aiBotAlgoInfo = PiMarkdown<AppState>({
    source: (s, _, { serviceID }) => {
      const t = getTask(serviceID, null, s);
      return t?.explanation ? t?.explanation : "";
    },
    extraStyle: (s, _, { serviceID }) => {
      const t = getTask(serviceID, null, s);
      return t?.rating === AiBotRating.Warning ? dangerStyle : {};
    },
  });
}

export function appendBotServiceTable(cols: any[], s: AppState): void {
  if (!featureEnabled(s)) return;

  cols.splice(1, 0, {
    label: "aibot",
    title: "Bot",
    type: ColumnType.Icon,
    iconType: IconType.ReactComponent,
    headerStyle: { width: "1%" },
  });
}

export function appendBotStatusToServiceList(
  data: { [k: string]: any },
  service: ServiceDetail,
  s: AppState
): void {
  if (!featureEnabled(s)) return;

  const task = getTask(service.id, null, s);
  if (!task) return;

  data["aibot"] = task.pending ? ThreeDotsBounce() : Alert();
}

export function appendBotMessageToServiceDetail(
  a: DataGridEl[],
  serviceID: string,
  s: AppState
): void {
  if (!featureEnabled(s)) return;

  const task = getTask(serviceID, null, s);
  if (!task || task.pending || task.rating === AiBotRating.OK) return;

  const context = { serviceID };
  a.push({ type: DataGridElType.Separator });
  a.push({
    id: "aibot",
    title: "SD Bot Message",
    cardName: "aiBotAlgoInfo",
    context,
    type: DataGridElType.Card,
  });
}

export function featureEnabled(s: AppState): boolean {
  return s.featureFlags.has("ai-bot");
}
