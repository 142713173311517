import { PiRegister, createCardDeclaration } from "@pihanga/core";

import { Component } from "./tbAnalyticCard.component";
import type { ComponentProps } from "./tbAnalyticCard.component";

export const CARD_TYPE = "TbAnalyticCard";
export const TbAnalyticCard = createCardDeclaration<ComponentProps>(CARD_TYPE);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: CARD_TYPE,
    component: Component,
  });
}
