import React from "react";
import { PiCardSimpleProps } from "@pihanga/core";
import { ReactComponent as LoginIcon } from "../../assets/img/login-2.svg";

export type ComponentProps = {};

export const Component = (
  props: PiCardSimpleProps<ComponentProps>
): React.ReactNode => {
  const renderCarousel = (): React.ReactNode => {
    return (
      <div
        id="carousel-captions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              alt="Seagrass ecosystems"
              src="/img/seagrass_ecosystems.jpeg"
            />

            <div className="carousel-caption-background d-none d-md-block"></div>

            <div className="carousel-caption d-none d-md-block">
              <p>
                Seagrass ecosystems are among the most extensive and valuable
                coastal ecosystems across the Indo - Pacific, providing benefits
                directly linked to sustainable livelihoods, and climate
                resilience and adaptation.
              </p>
            </div>
          </div>

          <div className="carousel-item">
            <img
              className="d-block w-100"
              alt="Placement of a 100 meter transect"
              src="/img/placement_of_a_100_meter_transect.jpeg"
            />
            <div className="carousel-caption-background d-none d-md-block"></div>
            <div className="carousel-caption d-none d-md-block">
              <p>
                Placement of a 100 meter transect across a gradient of seagrass
                habitat types in order to train machine learning models for
                seagrass coverage and biomass.
              </p>
            </div>
          </div>

          <div className="carousel-item">
            <img
              className="d-block w-100"
              alt="Collection of seagrass cores by Indonesian researchers"
              src="/img/collection_of_seagrass_cores.jpeg"
            />
            <div className="carousel-caption-background d-none d-md-block"></div>
            <div className="carousel-caption d-none d-md-block">
              <p>
                Collection of seagrass cores by Indonesian researchers from Nusa
                Cendana University for mapping carbon stocks.
              </p>
            </div>
          </div>
        </div>

        <a
          href="."
          className="carousel-control-prev"
          data-bs-target="#carousel-captions"
          role="button"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </a>
        <a
          href="."
          className="carousel-control-next"
          data-bs-target="#carousel-captions"
          role="button"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </a>
      </div>
    );
  };

  const renderTextRow = (str: string | React.ReactNode): React.ReactNode => {
    return (
      <div className="row row-deck justify-content-center">
        <div className="col-9 justify-content-center">
          <p
            className="page-subtitle mt-4 mb-6"
            style={{
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            {str}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="page">
      <header className={`navbar navbar-expand-md d-print-none sticky-top`}>
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <a href="/">
              <img
                width="7%"
                height="auto"
                alt="CSIRO's Data61 Logo"
                src="/img/csiro-d61-logo.png"
              />
              <span style={{ marginLeft: "15px" }}>iBenthos</span>
            </a>
          </h1>

          <div className="navbar-nav flex-row order-md-last">
            <div className="nav-item">
              <a href="/login" className="nav-link d-flex lh-1 text-reset p-0">
                <button type="button" className="btn">
                  <LoginIcon />
                  Login
                </button>

                {/*<span className="avatar avatar-sm">*/}
                {/*  <LoginIcon />*/}
                {/*</span>*/}

                {/*<div className="d-none d-xl-block ps-2 vertical-align-center">*/}
                {/*  <div>Login</div>*/}
                {/*</div>*/}
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="page-wrapper">
        <div className="page-body">
          <div className="container-xl justify-content-center">
            <div className="row row-deck justify-content-center">
              <div className="col-8 justify-content-center">
                <h1
                  className="page-title"
                  style={{
                    fontSize: "3rem",
                    lineHeight: "9.25rem",
                  }}
                >
                  iBenthos
                </h1>
              </div>
            </div>

            {renderTextRow(
              <span>
                Seagrass mapping made easy with our artificial
                intelligence-assisted workflow. Upload, manage, annotate, and
                analyse geo-referenced survey datasets with a shared AI model
                that evolves with the collective knowledge of the community.
              </span>
            )}

            {renderCarousel()}
          </div>

          <div className="container mt-4 mb-4">
            {renderTextRow(
              "A collaboration between CSIRO (Australia’s National Science Agency), Australia's Department of Foreign Affairs and Trade (DFAT), and Google Australia."
            )}
            <div className="row row-deck justify-content-center">
              <div className="col-4 justify-content-center">
                <a href="https://www.csiro.au/">
                  <img
                    width="130px"
                    height="auto"
                    alt="CSIRO's Data61 Logo"
                    src="/img/csiro-d61-logo.png"
                  />
                </a>
              </div>

              <div className="col-4 justify-content-center">
                <a href="https://www.dfat.gov.au/">
                  <img
                    width="150px"
                    height="auto"
                    alt="DFAT Logo"
                    src="/img/dfat-logo.png"
                  />
                </a>
              </div>

              <div className="col-4 justify-content-center">
                <a href="https://google.com.au/">
                  <img
                    width="70px"
                    height="auto"
                    alt="Google Logo"
                    src="/img/google-logo.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
