import chroma from "chroma-js";

// Generate a set of visually distinct green-ish colours
const generateGreenishColours = (numColours: number): string[] => {
  return chroma.scale(["#b3ffb3", "#009900"]).mode("lab").colors(numColours);
};

// Generate a set of visually distinct colours, excluding the green spectrum
const generateColours = (numColours: number): string[] => {
  return chroma
    .scale([
      "#f44336",
      "#2196F3",
      "#FFEB3B",
      "#9C27B0",
      "#FF9800",
      "#00BCD4",
      "#E91E63",
    ])
    .mode("lab")
    .colors(numColours);
};

const NO_OF_GREENISH_COLOURS =
  Number(process.env.REACT_APP_NO_OF_ML_RESULT_SEAGRASS_CLASSES) || 8;

const NO_OF_OTHER_COLOURS =
  Number(process.env.REACT_APP_NO_OF_ML_RESULT_OTHER_CLASSES) || 12;

const GREENISH_COLOURS = generateGreenishColours(NO_OF_GREENISH_COLOURS);
const OTHER_COLOURS = generateColours(NO_OF_OTHER_COLOURS);

export const getColourForClass = (
  classIndex: number,
  weighting?: number
): string => {
  if (weighting && weighting > 0) {
    return GREENISH_COLOURS[classIndex % GREENISH_COLOURS.length];
  } else {
    return OTHER_COLOURS[classIndex % OTHER_COLOURS.length];
  }
};
