import { PiCardSimpleProps } from "@pihanga/core";
import { TbIcon } from "@pihanga/tabler/dist/components";

// const SELECTED_MARKER = ['/womens-directory/map_marker_single_sel.png', '/womens-directory/map_marker_multi_sel.png'];
// const DEF_MARKER = ['/womens-directory/map_marker_single_def.png', '/womens-directory/map_marker_multi_def.png'];
// const SELECTED_MULTI_MARKER = '/womens-directory/map_marker_multi_sel.png';
// const DEF_MULTI_MARKER = '/womens-directory/map_marker_multi_def.png';

export type ComponentProps = {
  id: string; // used in event
  title?: string;
  iconName?: string;
  isActive: boolean;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;

  iconStyle?: React.CSSProperties;
};

export type ButtonClickedEvent = {
  id: string;
  isActive: boolean;
};

type ComponentT = ComponentProps & {
  onClicked: (ev: ButtonClickedEvent) => void;
};

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const {
    cardName,
    id,
    title,
    iconName,
    iconStyle,
    titleStyle,
    isActive,
    onClicked,
  } = props;

  function onClick(): void {
    onClicked({ id, isActive });
  }

  const cls = `nav-link nav-link-${cardName} ${isActive ? "active" : ""}`;
  const ts = titleStyle || {};
  return (
    <button onClick={onClick} className={cls} data-pihanga={cardName}>
      <TbIcon iconName={iconName} iconStyle={iconStyle} />
      <span style={ts}>{title}</span>
    </button>
  );
};
