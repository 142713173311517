import { PiCardSimpleProps } from "@pihanga/core";
import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TbInputComponent } from "../tbInput.component";
import { ModalComponent } from "../modal.component";

export type ComponentProps = {
  title?: string;
};

type ComponentT = ComponentProps & {
  onConfirmDelete: () => void;
  onCloseModal: () => void;
};

const SCHEMA = Yup.object({
  delete: Yup.string().oneOf(["delete"], "type delete to confirm").defined(),
});

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const { title, onConfirmDelete, onCloseModal } = props;

  return (
    <Formik
      onSubmit={onConfirmDelete}
      validationSchema={SCHEMA}
      initialValues={{
        delete: "",
      }}
      enableReinitialize
    >
      {({ dirty, handleSubmit }): React.ReactNode => (
        <ModalComponent
          title={title || "Confirm delete"}
          onClose={onCloseModal}
          confirmTitle="Delete"
          confirmButtonClass="btn-danger"
          onConfirm={handleSubmit}
          disableConfirm={!dirty}
        >
          <div className="card-body">
            <Form>
              <Field
                name="delete"
                component={TbInputComponent}
                placeholder="delete"
                label="Are you sure you want to delete? Type delete to confirm"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};
