import { createOnAction, ReduxAction, registerActions } from "@pihanga/core";
import { ListEvent, OrderListItem, OrderProductListItem } from "../ivcap";

export const ACTION_TYPES = registerActions("APP:ANALYTIC", [
  "LOAD_MORE_RESULTS",
  "LOAD_MORE_ORDER_PRODUCTS",
  "ORDER_PRODUCT_LIST",
  "RESULT_LIST",
]);

type LoadMoreResultsEvent = {
  page: string;
};

type LoadMoreOrderProductsEvent = {
  orderId: string;
  page: string;
};

export type ResultListEvent = ListEvent & {
  page?: string; // to identify if there was a page before this page
  orders: OrderListItem[];
};

type OrderProductListEvent = ListEvent & {
  orderId: string;
  page?: string; // to identify if there was a page before this page
  items: OrderProductListItem[];
};

export const onLoadMoreResults = createOnAction<LoadMoreResultsEvent>(
  ACTION_TYPES.LOAD_MORE_RESULTS
);

export const createLoadMoreResultsAction = (
  page: string
): ReduxAction & LoadMoreResultsEvent => ({
  type: ACTION_TYPES.LOAD_MORE_RESULTS,
  page,
});

export const onLoadMoreOrderProducts =
  createOnAction<LoadMoreOrderProductsEvent>(
    ACTION_TYPES.LOAD_MORE_ORDER_PRODUCTS
  );

export const createLoadMoreOrderProducts = (
  orderId: string,
  page: string
): ReduxAction & LoadMoreOrderProductsEvent => ({
  type: ACTION_TYPES.LOAD_MORE_ORDER_PRODUCTS,
  orderId,
  page,
});

export const onResultList = createOnAction<ResultListEvent>(
  ACTION_TYPES.RESULT_LIST
);

export const onOrderProductList = createOnAction<OrderProductListEvent>(
  ACTION_TYPES.ORDER_PRODUCT_LIST
);
