export type AiBotState = { [k: string]: AiBotTask };

export type AiBotTask = {
  serviceID: string;
  collectionID: string;
  pending: boolean;
  rating?: AiBotRating;
  explanation?: string;
};

export enum AiBotRating {
  OK = "ok",
  Note = "note",
  Warning = "warning",
}
