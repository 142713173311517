/**
 * Download a blob from the browser by adding a temporary anchor element in the DOM
 * @param blob
 * @param filename
 */
export const downloadBlob = (
  blob: Blob,
  filename?: string
): HTMLAnchorElement => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.href = url;
  a.download = filename || "download";

  const clickHandler = (): void => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      window.removeEventListener("click", clickHandler);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();

  return a;
};
