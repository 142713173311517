import React from "react";
import { Card } from "@pihanga/core";
import "./assets/css/index.css";
import { Auth0Provider } from "@auth0/auth0-react";

const App = (): React.ReactElement => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        useRefreshTokens: true,
      }}
    >
      <Card cardName="page" />
    </Auth0Provider>
  );
};

export default App;
