import {
  PiRegister,
  actionTypesToEvents,
  createOnAction,
  registerActions,
  createCardDeclaration,
} from "@pihanga/core";
import { Component, SaveProjectEvent } from "./tbProjectDetail.component";
import type {
  ComponentProps,
  CreateNewProjectEvent,
} from "./tbProjectDetail.component";

const TB_PROJECT_DETAIL = "TbProjectDetail";

const ACTION_TYPES = registerActions("TB_PROJECT_DETAIL", [
  "SAVE_PROJECT",
  "CREATE_NEW_PROJECT",
  "CLOSE_MODAL",
]);

export const onSaveProject = createOnAction<SaveProjectEvent>(
  ACTION_TYPES.SAVE_PROJECT
);

export const onCreateNewProject = createOnAction<CreateNewProjectEvent>(
  ACTION_TYPES.CREATE_NEW_PROJECT
);

export const onCloseModal = createOnAction<CreateNewProjectEvent>(
  ACTION_TYPES.CLOSE_MODAL
);

export const TbProjectDetail =
  createCardDeclaration<ComponentProps>(TB_PROJECT_DETAIL);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_PROJECT_DETAIL,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
