import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Icon as TablerIcon } from "@tabler/icons-react";
import { hasAccessToken } from "./ivcap"; // "@pihanga/ivcap";

type LoginButtonProps = {
  authorizationErrorMsg?: string;
  label: string;
  icon: TablerIcon;
  onGetAuthTokenSuccess: (_: string) => void;
  onGetAuthTokenError: (_: string) => void;
  justSignedOut?: boolean;
};

const AUTO_LOGIN_TOKEN = process.env.REACT_APP_AUTO_LOGIN_TOKEN;

export const Auth0LoginButton: React.FC<LoginButtonProps> = ({
  authorizationErrorMsg,
  label,
  icon,
  onGetAuthTokenSuccess,
  onGetAuthTokenError,
  justSignedOut,
}) => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } =
    useAuth0();

  const [loadingToken, setLoadingToken] = React.useState(false);

  React.useEffect(() => {
    if (justSignedOut && isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: window.location.origin + "/logout",
        },
      });
      return;
    }

    const getAccessToken = async (): Promise<void> => {
      try {
        setLoadingToken(true);
        const token = await getAccessTokenSilently();
        onGetAuthTokenSuccess(token);
      } catch (e: any) {
        if (e.error === "login_required") {
          await loginWithRedirect();
        } else if (e.error === "consent_required") {
          await loginWithRedirect();
        } else {
          onGetAuthTokenError(e.error);
        }
      }
      setLoadingToken(false);
    };

    if (
      isAuthenticated &&
      !hasAccessToken() &&
      !authorizationErrorMsg &&
      !loadingToken
    ) {
      getAccessToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, justSignedOut]);

  return (
    <button
      className="btn"
      onClick={(): void => {
        if (AUTO_LOGIN_TOKEN) {
          onGetAuthTokenSuccess(AUTO_LOGIN_TOKEN);
        } else {
          setLoadingToken(true);
          loginWithRedirect();
        }
      }}
      disabled={loadingToken}
    >
      {loadingToken ? (
        <span className="spinner-border spinner-border-sm me-2" role="status" />
      ) : (
        icon && React.createElement(icon)
      )}

      {label}
    </button>
  );
};
