import { ReduxState, dispatchFromReducer } from "@pihanga/core";
import { ErrorAction as RestErrAction } from "@pihanga/rest-client";
import { ACTION_TYPES, ErrorAction, Template } from "./actions";

export function dispatchEvent<T>(
  ev: any,
  type: string,
  template?: Template<T>
): void {
  if (template) {
    dispatchFromReducer({ ...ev, ...template });
  } else {
    dispatchFromReducer({ type, ...ev });
  }
}

export function restErrorHandling<A>(source: string) {
  return (
    state: ReduxState,
    action: RestErrAction<A>,
    requestAction: A
  ): ReduxState => {
    const code = action.statusCode;
    if (code === 401) {
      dispatchFromReducer({
        type: ACTION_TYPES.NOT_AUTHORIZED,
        message: "Not authorized",
        source,
        cause: action,
        requestAction,
      } as ErrorAction);
    } else {
      dispatchFromReducer({
        type: ACTION_TYPES.ERROR,
        message: `${action.content?.error || "error"}`,
        source,
        cause: action,
        requestAction,
      } as ErrorAction);
    }
    return state;
  };
}

export function createListUrlBuilder(
  service: string,
  extend?: { [k: string]: string }
): string {
  let q = Object.entries({
    limit: "limit",
    page: "page",
    filter: "filter",
    "order-by": "orderBy",
    "order-desc": "orderDesc",
    "at-time": "atTime",
  }).map(([k, v]) => `${k}=?${v}`);
  if (extend) {
    const q2 = Object.entries(extend).map(([k, v]) => `${k}=${v}`);
    q = q.concat(q2);
  }

  const u = `/1/${service}?${q.join("&")}`;
  return u;
}

type Link = {
  rel: "describedBy" | "first" | "self" | "next";
  type: string;
  href: string;
};

export function getNextPage(links: Link[]): string | undefined {
  const nextLink = links && links.find((l) => l.rel === "next");
  if (nextLink) {
    return nextLink.href.split("?page=")[1];
  }
  return undefined;
}
