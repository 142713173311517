import {
  PiCardDef,
  PiMapProps,
  PiRegister,
  actionTypesToEvents,
  createOnAction,
  registerActions,
} from "@pihanga/core";
import {
  AuthProviderLoginEvent,
  Component,
  LoginTokenEvent,
  SignupEvent,
} from "./tbLoginPage.component";
import type {
  ComponentProps,
  LoginPasswordEvent,
} from "./tbLoginPage.component";

const TB_LOGIN_PAGE_CT = "TbLoginPageiBenthos";

type CardProps<S> = PiMapProps<ComponentProps, S>;
export function TbLoginPageCustom<S>(p: CardProps<S>): PiCardDef {
  return {
    ...p,
    cardType: TB_LOGIN_PAGE_CT,
  };
}

const ACTION_TYPES = registerActions("TB_LOGIN_IBENTHOS", [
  "LOGIN_PASSWORD",
  "LOGIN_TOKEN",
  "AUTH_PROVIDER",
  "SIGN_UP",
]);

export const onTbLoginPassword = createOnAction<LoginPasswordEvent>(
  ACTION_TYPES.LOGIN_PASSWORD
);
export const onTbLoginToken = createOnAction<LoginTokenEvent>(
  ACTION_TYPES.LOGIN_TOKEN
);
export const onTbLoginAuthProvider = createOnAction<AuthProviderLoginEvent>(
  ACTION_TYPES.AUTH_PROVIDER
);
export const onTbLoginSignup = createOnAction<SignupEvent>(
  ACTION_TYPES.SIGN_UP
);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_LOGIN_PAGE_CT,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
