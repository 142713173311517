import {
  PiRegister,
  actionTypesToEvents,
  createOnAction,
  registerActions,
  createCardDeclaration,
} from "@pihanga/core";
import { Component } from "./tbOrderDetail.component";
import type {
  ComponentProps,
  CreateNewOrderEvent,
} from "./tbOrderDetail.component";

const TB_ORDER_DETAIL = "TbOrderDetail";

const ACTION_TYPES = registerActions("TB_ORDER_DETAIL", [
  "CREATE_NEW_ORDER",
  "CLOSE_MODAL",
]);

export const onCreateNewOrder = createOnAction<CreateNewOrderEvent>(
  ACTION_TYPES.CREATE_NEW_ORDER
);

export const onCloseModal = createOnAction<CreateNewOrderEvent>(
  ACTION_TYPES.CLOSE_MODAL
);

export const TbOrderDetail =
  createCardDeclaration<ComponentProps>(TB_ORDER_DETAIL);

export function init(register: PiRegister): void {
  register.cardComponent({
    name: TB_ORDER_DETAIL,
    component: Component,
    events: actionTypesToEvents(ACTION_TYPES),
  });
}
