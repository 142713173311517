import { PiCardDef } from "@pihanga/core";
import { AppState } from "../app.type";
import { ColumnType, TbXlDataTable } from "..//cards/tbDataTable";
import { TbXlCard } from "@pihanga/tabler/dist/cards/tbXLCard";

import { init as detailsInit } from "./collection.detail.pihanga";
import { TbEmptyList } from "../cards/tbEmptyList";
import { NO_OF_COLLECTIONS_PER_PAGE } from "./collection.reducer";

export function init(cards: { [k: string]: PiCardDef }): void {
  detailsInit(cards);

  cards.collectionsListing = TbXlCard<AppState>({
    title: "Collections",
    contentCard: "collectionsTable",
    actionCards: [],
    infoCards: ["refreshButton"],
  });

  cards.emptyCollectionListing = TbEmptyList<AppState>({
    title: "No collections available.",
    subTitle: (
      <span>
        Try adding collections using{" "}
        <a href="https://bitbucket.csiro.au/projects/IU/repos/ibenthos-tooling/browse">
          ibenthos-tooling
        </a>
        .
      </span>
    ),
  });

  cards.collectionsTable = TbXlDataTable<AppState>({
    columns: [
      { label: "name", title: "Name", sortable: true, type: ColumnType.String },
      {
        label: "project",
        title: "Project ID",
        type: ColumnType.String,
      },
    ],
    data: (s: AppState) => {
      return (
        s.collectionListing.list
          // .filter((el) => el.project === getProjectId(s))
          .slice(
            s.collectionListing.offset,
            s.collectionListing.offset + NO_OF_COLLECTIONS_PER_PAGE
          )
          .map((el, idx) => {
            const id = el.id;
            return {
              id,
              data: { ...el },
            };
          })
      );
    },
    showSearch: false,
    dataOffset: (s: AppState) => {
      return s.collectionListing ? s.collectionListing.offset : 0;
    },
    hasMore: (s) => {
      const canLoadMore =
        (s.collectionListing.offset || 0) + NO_OF_COLLECTIONS_PER_PAGE <
        (s.collectionListing.list.length || 0);

      return !!s.collectionListing.nextPage || canLoadMore;
    },
    cardOnEmpty: (s) =>
      s.collectionListing.fetching ? "spinnerCard" : "emptyCollectionListing",
  });

  // cards.collectionsDetail = TbXlCard<AppState>({
  //   title: (s) =>
  //     `Collection: ${getCollectionName((s.route.path || [])[3], s)}`,
  //   contentCard: "collectionsDetailContent",
  //   infoCards: ["refreshButton"],
  // });

  // cards.collectionsDetailContent = TbDataGrid<AppState>({
  //   items: collectionDetail,
  //   cardOnEmpty: "spinnerCard",
  // });

  // cards.collectionsDetailSummary = TbDataGrid<AppState>({
  //   items: (s) => {
  //     const coll = s.collections[getCollectionId(s)!];
  //     return [
  //       { id: "name", type: DataGridElType.Text, value: coll?.summary?.name },
  //       {
  //         id: "project",
  //         type: DataGridElType.Text,
  //         value: coll?.summary?.project || "???",
  //       },
  //     ];
  //   },
  // });

  // cards.collectionDetailImages = TbXlDataTable<AppState>({
  //   columns: [
  //     { label: "preview", title: "Preview", type: ColumnType.Button },
  //     { label: "name", title: "Name", sortable: true },
  //     {
  //       label: "width",
  //       title: "Width",
  //       sortable: true,
  //       type: ColumnType.Number,
  //     },
  //     {
  //       label: "height",
  //       title: "Height",
  //       sortable: true,
  //       type: ColumnType.Number,
  //     },
  //     {
  //       label: "latitude",
  //       title: "Latitude",
  //       sortable: false,
  //       type: ColumnType.Number,
  //     },
  //     {
  //       label: "longitude",
  //       title: "Longitude",
  //       sortable: false,
  //       type: ColumnType.Number,
  //     },
  //     {
  //       label: "artifactURN",
  //       title: "ID",
  //       sortable: false,
  //       type: ColumnType.String,
  //     },
  //   ],
  //   data: (s: AppState) => {
  //     const collectionId = getCollectionId(s);
  //     const coll = s.collections[collectionId!]?.images || [];

  //     return coll.map(
  //       ({ artifactURN, name, width, height, latitude, longitude }) => {
  //         return {
  //           id: artifactURN,
  //           data: {
  //             name,
  //             width,
  //             height,
  //             latitude,
  //             longitude,
  //             artifactURN: artifactURN,
  //           },
  //         };
  //       }
  //     );
  //   },
  //   showSearch: false,

  //   // FIXME: handle "fetching" collection detail state
  //   cardOnEmpty: (s) => {
  //     const collection = s.collections[getCollectionId(s)!];

  //     return collection && collection.fetching
  //       ? "spinnerCard"
  //       : "emptyCollectionDetailImages";
  //   },
  // });

  // cards.emptyCollectionDetailImages = TbEmptyList<AppState>({
  //   title: "No images available.",
  // });
}

// function getCollectionName(id: string, state: AppState): string {
//   const rec = state.collections[id];
//   if (rec) {
//     return rec.summary?.name;
//   } else {
//     return "????";
//   }
// }
