import React, { useLayoutEffect, useRef } from "react";
import { PiCardSimpleProps } from "@pihanga/core";
import ReactJson, { ReactJsonViewProps, ThemeKeys } from "react-json-view";
export type ComponentProps = {
  source: any;
  theme?: ThemeKeys;
  name?: string;
  collapsed?: boolean;
  iconStyle?: "circle" | "triangle" | "square";
  // When set to true, objects and arrays are labeled with size
  displayObjectSize?: boolean;
  // When set to true, data type labels prefix values
  displayDataTypes?: boolean;
  // When set, called before json is rendered to the browser
  modifyFn?: ModifyFn;
};

export type ModifyFn = (source: any, el: HTMLElement | null) => void;

type ComponentT = ComponentProps;

export const Component = (
  props: PiCardSimpleProps<ComponentT>
): React.ReactNode => {
  const {
    source,
    theme,
    name,
    collapsed,
    iconStyle,
    displayObjectSize = false,
    displayDataTypes = false,
    modifyFn,
    cardName,
  } = props;
  const elRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (modifyFn) {
      modifyFn(source, elRef.current);
    }
  }, [modifyFn, source]);

  const p = {
    src: source,
    name,
    collapsed,
    theme,
    iconStyle,
    displayObjectSize,
    displayDataTypes,
  } as ReactJsonViewProps;

  return (
    <div
      className={`pi-json-viewer pi-json-viewer-${cardName}`}
      ref={elRef}
      data-pihanga={cardName}
    >
      {/* <ReactJson  {...p} />  not sure why this is no longer working */}
      {React.createElement(ReactJson, p)}
    </div>
  );
};
