import {
  PiRegister,
  dispatchFromReducer,
  ReduxState,
  ReduxAction,
  createOnAction,
} from "@pihanga/core";
import { registerGET, registerPOST } from "@pihanga/rest-client";
import { getAccessToken } from ".";
import {
  ACTION_TYPES,
  BaseEvent,
  createListAction,
  createReadAction,
  ListEvent,
  LoadListEvent,
  LoadRecordEvent,
} from "./actions";
import {
  restErrorHandling,
  createListUrlBuilder,
  getNextPage,
  dispatchEvent,
} from "./common";

// LIST

type OrderListEvent = ListEvent & {
  orders: OrderListItem[];
};

export type OrderListItem = {
  id: string;
  name: string;
  status: string;
  orderedAt?: Date;
  startedAt?: Date;
  finishedAt?: Date;
  serviceID: string;
  accountID: string;
};

export type OrderProductListEvent = ListEvent & {
  items: OrderProductListItem[];
};

export type OrderProductListItem = {
  href: string;
  id: string;
  status: string;
  "data-href"?: string;
  "mime-type"?: string;
  name?: string;
  size?: number;
};

export type LoadOrderListEvent<T = {}> = LoadListEvent<T>;
export type LoadOrderProductListEvent<T = {}> = LoadListEvent<T> & {
  orderId: string;
};

// export const createGetOrderListAction = (
//   apiURL: URL,
//   limit?: number,
//   page?: string,
//   filter?: string,
//   orderBy?: string,
//   orderDesc?: boolean,
//   atTime?: Date
// ): ReduxAction & LoadOrderListEvent => {
//   return createListAction(ACTION_TYPES.LOAD_ORDER_LIST, {
//     apiURL,
//     limit,
//     page,
//     filter,
//     orderBy,
//     orderDesc,
//     atTime,
//   });
// };

export function dispatchIvcapGetOrderList<T = {}>(
  ev: LoadOrderListEvent<T>
): void {
  const a = createListAction(ACTION_TYPES.LOAD_ORDER_LIST, ev);
  dispatchFromReducer(a);
}

export function dispatchIvcapGetOrderProductList<T = {}>(
  ev: LoadOrderProductListEvent<T>
): void {
  const a = createListAction(ACTION_TYPES.LOAD_ORDER_PRODUCT_LIST, ev);
  dispatchFromReducer({
    ...a,
    orderId: ev.orderId,
  });
}

export const onOrderList = createOnAction<OrderListEvent>(
  ACTION_TYPES.ORDER_LIST
);

// READ

type OrderRecordEvent = {
  order: OrderRecord;
};

export type OrderRecord = {
  id: string;
  name: string;
  status: string;
  orderedAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  parameters: OrderParameter[];
  products: OrderProduct[];
  productsOffset: number;
  productsNextPage?: string;
  productsFetching?: boolean;
  serviceID: string;
  accountID: string;
};

export type OrderParameter = {
  name: string;
  value: string | number;
};

export type OrderProduct = {
  id: string;
  name: string;
  status: string;
  mimeType?: string;
  size?: number;
  dataURL?: string;
};

export type LoadOrderRecordEvent<T = {}> = LoadRecordEvent<T>;

// export const createLoadOrderRecordAction = (
//   apiURL: URL,
//   id: string
// ): ReduxAction & LoadOrderRecordEvent => {
//   return createReadAction(ACTION_TYPES.LOAD_ORDER_RECORD, {
//     apiURL,
//     id,
//   });
// };

export function dispatchIvcapGetOrderRecord<T = {}>(
  ev: LoadOrderRecordEvent<T>
): void {
  const a = createReadAction(ACTION_TYPES.LOAD_ORDER_RECORD, ev);
  dispatchFromReducer(a);
}

export const onOrderRecord = createOnAction<OrderRecordEvent>(
  ACTION_TYPES.ORDER_DETAIL
);

// CREATE

export type OrderReceiptEvent = {
  refID: string; // internal id to relate request to reply
  order: OrderRecord;
};

type OrderCreateEvent<T = {}> = BaseEvent<T> & {
  id: string; // for internal reference
  serviceID: string;
  parameters: OrderParameter[];
};

// export const createOrderCreateAction = (
//   apiURL: URL,
//   refID: string, // internal id to relate request to reply
//   serviceID: string,
//   parameters: OrderParameter[]
// ): ReduxAction & OrderCreateEvent => {
//   return {
//     type: ACTION_TYPES.CREATE_ORDER,
//     apiURL,
//     id: refID,
//     serviceID,
//     parameters,
//   };
// };

export function dispatchIvcapCreateOrder<T = {}>(
  ev: OrderCreateEvent<T>
): void {
  dispatchFromReducer({
    type: ACTION_TYPES.CREATE_ORDER,
    ...ev,
  });
}

export const onOrderReceipt = createOnAction<OrderReceiptEvent>(
  ACTION_TYPES.ORDER_RECEIPT
);

//====== API HANDLER

export function init(register: PiRegister): void {
  registerGET<ReduxState, ReduxAction & LoadOrderListEvent, any>(register)({
    name: "loadOrderList",
    origin: ({ apiURL }, _) => apiURL,
    url: createListUrlBuilder("orders"),
    trigger: ACTION_TYPES.LOAD_ORDER_LIST,
    request: (a, _) => a as any,
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, { template }) => {
      const ev: OrderListEvent = {
        nextPage: getNextPage(content.links),
        orders: (content.items || []).map(toOrderListItem),
      };
      dispatchEvent(ev, ACTION_TYPES.ORDER_LIST, template);
      return state;
    },
    error: restErrorHandling("ivcap-api:loadOrderList"),
  });

  registerGET<ReduxState, ReduxAction & LoadOrderProductListEvent, any>(
    register
  )({
    name: "loadOrderProductList",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/orders/:orderId/products?page=:page&limit=:limit&order-by=:orderBy&order-desc=:orderDesc",
    trigger: ACTION_TYPES.LOAD_ORDER_PRODUCT_LIST,
    request: ({ orderId, page, limit, orderBy, orderDesc }, _) => ({
      orderId,
      page: page || "",
      limit: limit || "",
      orderBy: orderBy || "",
      orderDesc: orderDesc ? "true" : "false",
    }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, { template }) => {
      const ev: OrderProductListEvent = {
        nextPage: getNextPage(content.links),
        items: content.items || [],
      };

      dispatchEvent(ev, ACTION_TYPES.ORDER_PRODUCT_LIST, template);
      return state;
    },
    error: restErrorHandling("ivcap-api:loadOrderProductList"),
  });

  registerGET<ReduxState, ReduxAction & LoadOrderRecordEvent, any>(register)({
    name: "getOrderDetail",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/orders/:id",
    trigger: ACTION_TYPES.LOAD_ORDER_RECORD,
    request: ({ id }, _) => ({ id }),
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, content: any, { template }) => {
      const ev: OrderRecordEvent = {
        order: toOrderRecord(content),
      };
      dispatchEvent(ev, ACTION_TYPES.ORDER_DETAIL, template);
      return state;
    },
    error: restErrorHandling("ivcap-api:getOrderDetail"),
  });

  registerPOST<ReduxState, ReduxAction & OrderCreateEvent, any>(register)({
    name: "createOrder",
    origin: ({ apiURL }, _) => apiURL,
    url: "/1/orders",
    trigger: ACTION_TYPES.CREATE_ORDER,
    request: ({ id, serviceID, parameters }) => {
      return {
        body: { name: id, service: serviceID, parameters },
        contentType: "application/json",
      };
    },
    headers: () => ({ Authorization: `Bearer ${getAccessToken()}` }),
    reply: (state, reply: any, req) => {
      //   reply
      //   {
      //     "id": "urn:ivcap:order:575a624d-d75c-40c3-a62c-ebc0fc5c6bd6",
      //     "name": "Order 575a624d-d75c-40c3-a62c-ebc0fc5c6bd6",
      //     "status": "unknown",
      //     "ordered_at": "2023-08-30T16:14:43+10:00",
      //     "parameters": [
      //         {
      //             "name": "model",
      //             "value": "urn:ivcap:artifact:00000000-0000-0000-0000-000000000000"
      //         },
      //         {
      //             "name": "images",
      //             "value": "urn:ivcap:collection:00000000-0000-0000-0000-000000000000"
      //         }
      //     ],
      //     "service": {
      //         "id": "urn:ivcap:service:dbf6f2fb-ac29-5acd-8d57-bf631a89557b",
      //         "links": {
      //             "self": "http://localhost:8080/1/services/dbf6f2fb-ac29-5acd-8d57-bf631a89557b"
      //         }
      //     },
      //     "account": {
      //         "id": "urn:ivcap:account:cedfcb67-8b41-4e4f-9f1c-0f1cf4a605e3"
      //     },
      //     "links": {
      //         "self": "http://localhost:8080/1/orders/575a624d-d75c-40c3-a62c-ebc0fc5c6bd6"
      //     }
      //   }
      // const parameters: OrderParameter[] = reply.parameters.map((el: any) => ({
      //   name: el.name,
      //   value: el.value,
      // }));
      // const order: OrderRecord = {
      //   id: reply.id,
      //   name: reply.name,
      //   status: reply.status,
      //   orderedAt: new Date(reply['ordered-at']),
      //   parameters,
      //   products: [],
      //   serviceID: reply.service.id,
      //   accountID: reply.account.id,
      // };
      const order = toOrderRecord(reply);
      const ev: OrderReceiptEvent = {
        refID: req.id,
        order,
      };
      dispatchEvent(ev, ACTION_TYPES.ORDER_RECEIPT, req.template);
      return state;
    },
    error: restErrorHandling("ivcap-api:addMetadata"),
  });
}

function toOrderListItem(els: any): OrderListItem {
  // {
  //   "id": "urn:ivcap:order:f44d5dbd-77cd-448f-a270-99a7b24a36c6",
  //   "name": "Order f44d5dbd-77cd-448f-a270-99a7b24a36c6",
  //   "status": "Succeeded",
  //   "ordered_at": "2023-03-26T06:49:10Z",
  //   "started_at": "2023-03-26T06:53:16Z",
  //   "finished_at": "2023-03-26T06:56:15Z",
  //   "service_id": "urn:ivcap:service:8773f79e-d46c-559a-a63c-54c4e2a9d9a1",
  //   "account_id": "urn:ivcap:account:4c65b865-df6a-4977-982a-f96b19c1fda0",
  //   "links": {
  //     "self": "https://develop.ivcap.net/1/orders/f44d5dbd-77cd-448f-a270-99a7b24a36c6"
  //   }
  // }
  return {
    id: els.id,
    name: els.name,
    status: els.status,
    orderedAt: toDate("ordered-at", els),
    startedAt: toDate("started-at", els),
    finishedAt: toDate("finished-at", els),
    serviceID: els.service,
    accountID: els.account,
  };
}

function toOrderRecord(els: any): OrderRecord {
  // {
  //   id: 'urn:ivcap:order:cee25b3c-9dc2-4cf1-a854-8b5351590f93',
  //   name: 'Order cee25b3c-9dc2-4cf1-a854-8b5351590f93',
  //   status: 'Succeeded',
  //   ordered_at: '2023-03-26T06:49:09Z',
  //   started_at: '2023-03-26T06:53:06Z',
  //   finished_at: '2023-03-26T06:56:18Z',
  //   parameters: [
  //     {
  //       name: 'model',
  //       value: 'http://artifact.local/urn:ivcap:artifact:240c4714-8aa3-43b6-bded-6c7a08595e81'
  //     },
  //     ...
  //   ],
  //   products: [
  //     {
  //       id: 'urn:ivcap:artifact:24dc7977-5503-4779-9d26-3f869599641f',
  //       name: 'tmp_mj_r70d-1154x866.pseudo.png',
  //       status: 'Ready',
  //       'mime-type': 'image/jpeg',
  //       size: 17897,
  //       links: {
  //         self: 'https://develop.ivcap.net/1/artifacts/24dc7977-5503-4779-9d26-3f869599641f',
  //         data: 'https://develop.ivcap.net/1/artifacts/24dc7977-5503-4779-9d26-3f869599641f/blob'
  //       }
  //     }
  //   ],
  //   service: {
  //     id: 'urn:ivcap:service:8773f79e-d46c-559a-a63c-54c4e2a9d9a1',
  //     links: {
  //       self: 'https://develop.ivcap.net/1/services/8773f79e-d46c-559a-a63c-54c4e2a9d9a1'
  //     }
  //   },
  //   account: {
  //     id: 'urn:ivcap:account:4c65b865-df6a-4977-982a-f96b19c1fda0'
  //   },
  //   links: {
  //     self: 'https://develop.ivcap.net/1/orders/cee25b3c-9dc2-4cf1-a854-8b5351590f93'
  //   }
  // },

  const parameters = (els.parameters || []).map((p: any) => {
    return {
      name: p.name,
      value: p.value,
    };
  });

  const products = (els.products.items || []).map((p: any) => {
    return {
      id: p.id,
      name: p.name,
      status: p.status,
      mimeType: p["mime-type"],
      size: p.number,
      dataURL: p.dataRef,
    };
  });

  return {
    id: els.id,
    name: els.name,
    status: els.status,
    orderedAt: toDate("ordered-at", els)!,
    startedAt: toDate("started-at", els),
    finishedAt: toDate("finished-at", els),
    parameters,
    products,
    productsNextPage: getNextPage((els.products || {}).links),
    productsOffset: 0,
    serviceID: els.service,
    accountID: els.account,
  };
}

function toDate(name: string, els: any): Date | undefined {
  const v = els[name];
  return v ? new Date(v) : undefined;
}
