// Source: https://github.com/n3r4zzurr0/svg-spinners/blob/main/svg-css/3-dots-bounce.svg
// Converted with: https://react-svgr.com/
export const ThreeDotsBounce = (size: number = 24): (() => React.ReactNode) => {
  return () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <style>
        {
          "@keyframes spinner_8HQG{0%,57.14%{animation-timing-function:cubic-bezier(.33,.66,.66,1);transform:translate(0)}28.57%{animation-timing-function:cubic-bezier(.33,0,.66,.33);transform:translateY(-6px)}to{transform:translate(0)}}.spinner_qM83{animation:spinner_8HQG 1.05s infinite}"
        }
      </style>
      <circle cx={4} cy={12} r={3} className="spinner_qM83" />
      <circle
        cx={12}
        cy={12}
        r={3}
        className="spinner_qM83"
        style={{
          animationDelay: ".1s",
        }}
      />
      <circle
        cx={20}
        cy={12}
        r={3}
        className="spinner_qM83"
        style={{
          animationDelay: ".2s",
        }}
      />
    </svg>
  );
};

export const Spinner = (
  rotation: number = 0.75, // seconds
  size: number = 24
): (() => React.ReactNode) => {
  return () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <style>{"@keyframes spinner_AtaB{to{transform:rotate(360deg)}}"}</style>
      <path
        d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm0 19a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z"
        opacity={0.25}
      />
      <path
        d="M10.14 1.16a11 11 0 0 0-9 8.92A1.59 1.59 0 0 0 2.46 12a1.52 1.52 0 0 0 1.65-1.3 8 8 0 0 1 6.66-6.61A1.42 1.42 0 0 0 12 2.69a1.57 1.57 0 0 0-1.86-1.53Z"
        style={{
          transformOrigin: "center",
          animation: `spinner_AtaB ${rotation}s infinite linear`,
        }}
      />
    </svg>
  );
};

export const Alert = (
  color = "red",
  size: number = 24
): (() => React.ReactNode) => {
  return () => (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="icon icon-tabler icon-tabler-alert-triangle"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="m10.24 3.957-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0z"
        />
        <path d="M12 9v4" stroke="white" stroke-width="3"></path>
        <path d="M12 17h.01" stroke="white" stroke-width="3"></path>
      </svg>
    </div>
  );
};
